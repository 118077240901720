export const ethnies = [
  "Preto",
  "Pardo",
  "Amarelo",
  "Indígena",
  "Branco",
  "Não desejo informar",
]

export const genderIdentities = [
  "Homem cis",
  "Homem trans",
  "Mulher cis",
  "Mulher trans",
  "Não binário",
  "Outro/outra",
  "Não desejo informar",
]

export const disabilityPresence = [
  "Não",
  "Sim",
  "Não desejo informar",
]

export const disabilities = [
  "Física",
  "Visual - baixa visão",
  "Visual - cego",
  "Auditiva - baixa audição",
  "Auditivo - surdo",
  "Intelectual",
  "Psicossocial",
  "Deficiência múltipla",
  "Outro/outra",
  "Desejo não declarar",
]

export const educations = [
  "Ensino fundamental incompleto",
  "Ensino fundamental completo",
  "Ensino médio incompleto",
  "Ensino médio completo",
  "Ensino superior incompleto",
  "Ensino superior completo",
  "Mestrado incompleto",
  "Mestrado completo",
  "Pós-graduação incompleta",
  "Pós-graduação completa",
  "Doutorado incompleto",
  "Doutorado completo",
  "Pós-doutorado incompleto",
  "Pós-doutorado completo",
  "Outro",
]