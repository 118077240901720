const FILE_PATH = {
  privacy: "privacy/politica_privacidade.pdf",
  terms:   "terms/termos_de_uso.pdf",
}

/**
   * @param {string} organization
   * @param {keyof FILE_PATH} fileType
   */
export function getLmsFilePath(organization, fileType) {
  const filePath = FILE_PATH[fileType]

  if (!organization || !filePath) return ""

  return `/files/documents/${organization}/${filePath}`
}