import { copyDeeply } from "../../helpers"

const DEFAULT_DECIMALS = "*"

export const decimal = {
  validate: (value, { decimals = DEFAULT_DECIMALS, separator = "." } = {}) => {
    if (value === null || value === undefined || value === "") {
      return false
    }
    if (Number(decimals) === 0) {
      return /^-?\d*$/.test(value)
    }


    const separatorRegex = Array.isArray(separator)
      ? `[\\${separator.join("\\")}]`
      : separator

    const regexPart = decimals === DEFAULT_DECIMALS ? "+" : `{0,${decimals}}`
    const regex = new RegExp(`^-?\\d*(${separatorRegex}\\d${regexPart})?$`)

    return regex.test(value)
  },

  params: ["decimals", "separator"],

  message: (fieldName, { decimals = DEFAULT_DECIMALS, separator = "." } = {}) => {
    let separatorMessage = copyDeeply(separator)

    if (Array.isArray(separator)) {
      const lastItem = separatorMessage.pop()

      separatorMessage = separatorMessage.map(char => `'${char}'`).join(", ") + ` or '${lastItem}'`
    }
    else {
      separatorMessage = `'${separatorMessage}'`
    }

    return decimals === DEFAULT_DECIMALS
      ? `The {_field_} field must contain only decimal values separeted by ${separatorMessage}`
      : `The {_field_} field must contain up to ${decimals} decimal point values separeted by ${separatorMessage}`
  }
}
