import { BaseEndpoint } from "./Base"

export default class ForbiddenTermValidateEndpoint extends BaseEndpoint {
  static get selector() {
    return "forbiddenTerms.validate"
  }

  create({ params = {}, options = {} } = {}) {

    return this.action({
      method:  "post",
      url:     "/forbidden_terms/validate",
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
