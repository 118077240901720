export const DATA_ACCESS_LOG_VIEWABLE_TYPE = /** @type {const} */ ({
  USER_SENSITIVE_DATUM: "UserSensitiveDatum",
})

export const REPORT_STATUS = /** @type {const} */ ({
  PROCESSED: {
    value:     "processed",
    label:     "Processado",
    iconClass: ["fa-check"],
  },
  PROCESSING: {
    value:     "processing",
    label:     "Processando",
    iconClass: ["fa-spinner"],
  },
  PENDING: {
    value:     "pending",
    label:     "Processando",
    iconClass: ["fa-spinner"],
  },
  EXPIRED: { value: "expired", label: "Expirado", iconClass: ["fa-times"] },
  FAILURE: {
    value:     "failure",
    label:     "Falhou",
    iconClass: ["fa-exclamation-triangle"],
  },
})

export const DATA_ACCESS_LOG_ORIGIN = /** @type {const} */ ({
  SELECTION_PROCESS: {
    value: "selection_process",
    label: "Processo seletivo",
  },
  USER_REGISTRATION_DATA_REPORT: {
    value: "user_registration_data_report",
    label: "Relatório de dados cadastrais de usuários",
  },
  USERS_SECTION: {
    value: "users_section",
    label: "Seção de usuários"
  }
})