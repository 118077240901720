import { formatFileSize } from "./number"

export const getRailsPath = (signedIn, fileName) => [
  "/rails/active_storage/blobs/redirect",
  signedIn,
  fileName,
].join("/")

export class UploadHelper {
  constructor(variableFile) {
    this._insertedFile = variableFile
  }

  get fileName() {
    return this._insertedFile?.name || this._insertedFile?.filename || ""
  }

  get path() {
    // ? INFO: Arquivo já no banco
    if (this._insertedFile?.url) return this._insertedFile.url
    if (this._insertedFile?.src) return this._insertedFile.src // TODO: atualizar no backend

    // ? INFO: Upload de imagem acabou de ser feito
    if (this._insertedFile.signed_id)
      return getRailsPath(this._insertedFile.signed_id, this.fileName)

    // ? INFO: Arquivo apenas dentro de um input de arquivo
    if (this._insertedFile instanceof File) return URL.createObjectURL(this._insertedFile)

    return ""
  }

  get size() {
    return this._insertedFile?.size || this._insertedFile?.byte_size
  }

  get type() {
    return this._insertedFile?.type || this._insertedFile?.content_type || ""
  }

  get sizeFormatted() {
    return this.size ? formatFileSize(this.size).replace(".", ",") : ""
  }
}