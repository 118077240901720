export const createNumbersArray = (amount, startIndex = 0) =>
  Array.from({ length: amount }, (_, i) => i + startIndex)

export function formatFileSize(bytes, decimalPoint) {
  if (bytes == 0) return "0 Bytes"
  var k = 1000,
    dm = decimalPoint || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

/** @param rawNumber {number} */
export function formatPercentage(rawNumber) {
  if (isNaN(rawNumber)) return "0%"

  const [integer, decimals] = rawNumber.toFixed(2).split(".")

  if (!decimals || decimals == "00") return integer + "%"

  if (decimals[1] == 0) return [integer, decimals[0]].join(",") + "%"

  return [integer, decimals].join(",") + "%"
}
