export const regions = [
  {
    name:   "Centro-Oeste",
    states: [
      {
        name:   "Distrito Federal",
        sigla:  "DF",
        cities: [
          { name: "Águas Claras" },
          { name: "Arniqueira" },
          { name: "Brasília" },
          { name: "Brazlândia" },
          { name: "Candangolândia" },
          { name: "Ceilândia" },
          { name: "Cruzeiro" },
          { name: "Estrutural" },
          { name: "Fercal" },
          { name: "Gama" },
          { name: "Guará" },
          { name: "Itapoã" },
          { name: "Jardim Botânico" },
          { name: "Lago Norte" },
          { name: "Lago Sul" },
          { name: "Núcleo Bandeirante" },
          { name: "Octogonal" },
          { name: "Paranoá" },
          { name: "Park Way" },
          { name: "Planaltina" },
          { name: "Plano Piloto" },
          { name: "Pôr do Sol" },
          { name: "Recanto das Emas" },
          { name: "Riacho Fundo II" },
          { name: "Riacho Fundo" },
          { name: "Samambaia" },
          { name: "Santa Maria" },
          { name: "São Sebastião" },
          { name: "SIA" },
          { name: "Sobradinho II" },
          { name: "Sobradinho" },
          { name: "Sol Nascente" },
          { name: "Sudoeste" },
          { name: "Taguatinga" },
          { name: "Varjão" },
          { name: "Vicente Pires" }
        ]
      },
      {
        name:   "Goiás",
        sigla:  "GO",
        cities: [
          { name: "Abadia de Goiás" },
          { name: "Abadiânia" },
          { name: "Acreúna" },
          { name: "Adelândia" },
          { name: "Água Fria de Goiás" },
          { name: "Água Limpa" },
          { name: "Águas Lindas de Goiás" },
          { name: "Alexânia" },
          { name: "Aloândia" },
          { name: "Alto Horizonte" },
          { name: "Alto Paraíso de Goiás" },
          { name: "Alvorada do Norte" },
          { name: "Amaralina" },
          { name: "Americano do Brasil" },
          { name: "Amorinópolis" },
          { name: "Anápolis" },
          { name: "Anhanguera" },
          { name: "Anicuns" },
          { name: "Aparecida de Goiânia" },
          { name: "Aparecida do Rio Doce" },
          { name: "Aporé" },
          { name: "Araçu" },
          { name: "Aragarças" },
          { name: "Aragoiânia" },
          { name: "Araguapaz" },
          { name: "Arenópolis" },
          { name: "Aruanã" },
          { name: "Aurilândia" },
          { name: "Avelinópolis" },
          { name: "Baliza" },
          { name: "Barro Alto" },
          { name: "Bela Vista de Goiás" },
          { name: "Bom Jardim de Goiás" },
          { name: "Bom Jesus de Goiás" },
          { name: "Bonfinópolis" },
          { name: "Bonópolis" },
          { name: "Brazabrantes" },
          { name: "Britânia" },
          { name: "Buriti Alegre" },
          { name: "Buriti de Goiás" },
          { name: "Buritinópolis" },
          { name: "Cabeceiras" },
          { name: "Cachoeira Alta" },
          { name: "Cachoeira de Goiás" },
          { name: "Cachoeira Dourada" },
          { name: "Caçu" },
          { name: "Caiapônia" },
          { name: "Caldas Novas" },
          { name: "Caldazinha" },
          { name: "Campestre de Goiás" },
          { name: "Campinaçu" },
          { name: "Campinorte" },
          { name: "Campo Alegre de Goiás" },
          { name: "Campo Limpo de Goiás" },
          { name: "Campos Belos" },
          { name: "Campos Verdes" },
          { name: "Carmo do Rio Verde" },
          { name: "Castelândia" },
          { name: "Catalão" },
          { name: "Caturaí" },
          { name: "Cavalcante" },
          { name: "Ceres" },
          { name: "Cezarina" },
          { name: "Chapadão do Céu" },
          { name: "Cidade Ocidental" },
          { name: "Cocalzinho de Goiás" },
          { name: "Colinas do Sul" },
          { name: "Córrego do Ouro" },
          { name: "Corumbá de Goiás" },
          { name: "Corumbaíba" },
          { name: "Cristalina" },
          { name: "Cristianópolis" },
          { name: "Crixás" },
          { name: "Cromínia" },
          { name: "Cumari" },
          { name: "Damianópolis" },
          { name: "Damolândia" },
          { name: "Davinópolis" },
          { name: "Diorama" },
          { name: "Divinópolis de Goiás" },
          { name: "Doverlândia" },
          { name: "Edealina" },
          { name: "Edéia" },
          { name: "Estrela do Norte" },
          { name: "Faina" },
          { name: "Fazenda Nova" },
          { name: "Firminópolis" },
          { name: "Flores de Goiás" },
          { name: "Formosa" },
          { name: "Formoso" },
          { name: "Gameleira de Goiás" },
          { name: "Goianápolis" },
          { name: "Goiandira" },
          { name: "Goianésia" },
          { name: "Goiânia" },
          { name: "Goianira" },
          { name: "Goiás" },
          { name: "Goiatuba" },
          { name: "Gouvelândia" },
          { name: "Guapó" },
          { name: "Guaraíta" },
          { name: "Guarani de Goiás" },
          { name: "Guarinos" },
          { name: "Heitoraí" },
          { name: "Hidrolândia" },
          { name: "Hidrolina" },
          { name: "Iaciara" },
          { name: "Inaciolândia" },
          { name: "Indiara" },
          { name: "Inhumas" },
          { name: "Ipameri" },
          { name: "Ipiranga de Goiás" },
          { name: "Iporá" },
          { name: "Israelândia" },
          { name: "Itaberaí" },
          { name: "Itaguari" },
          { name: "Itaguaru" },
          { name: "Itajá" },
          { name: "Itapaci" },
          { name: "Itapirapuã" },
          { name: "Itapuranga" },
          { name: "Itarumã" },
          { name: "Itauçu" },
          { name: "Itumbiara" },
          { name: "Ivolândia" },
          { name: "Jandaia" },
          { name: "Jaraguá" },
          { name: "Jataí" },
          { name: "Jaupaci" },
          { name: "Jesúpolis" },
          { name: "Joviânia" },
          { name: "Jussara" },
          { name: "Lagoa Santa" },
          { name: "Leopoldo de Bulhões" },
          { name: "Luziânia" },
          { name: "Mairipotaba" },
          { name: "Mambaí" },
          { name: "Mara Rosa" },
          { name: "Marzagão" },
          { name: "Matrinchã" },
          { name: "Maurilândia" },
          { name: "Mimoso de Goiás" },
          { name: "Minaçu" },
          { name: "Mineiros" },
          { name: "Moiporá" },
          { name: "Monte Alegre de Goiás" },
          { name: "Montes Claros de Goiás" },
          { name: "Montividiu" },
          { name: "Montividiu do Norte" },
          { name: "Morrinhos" },
          { name: "Morro Agudo de Goiás" },
          { name: "Mossâmedes" },
          { name: "Mozarlândia" },
          { name: "Mundo Novo" },
          { name: "Mutunópolis" },
          { name: "Nazário" },
          { name: "Nerópolis" },
          { name: "Niquelândia" },
          { name: "Nova América" },
          { name: "Nova Aurora" },
          { name: "Nova Crixás" },
          { name: "Nova Glória" },
          { name: "Nova Iguaçu de Goiás" },
          { name: "Nova Roma" },
          { name: "Nova Veneza" },
          { name: "Novo Brasil" },
          { name: "Novo Gama" },
          { name: "Novo Planalto" },
          { name: "Orizona" },
          { name: "Ouro Verde de Goiás" },
          { name: "Ouvidor" },
          { name: "Padre Bernardo" },
          { name: "Palestina de Goiás" },
          { name: "Palmeiras de Goiás" },
          { name: "Palmelo" },
          { name: "Palminópolis" },
          { name: "Panamá" },
          { name: "Paranaiguara" },
          { name: "Paraúna" },
          { name: "Perolândia" },
          { name: "Petrolina de Goiás" },
          { name: "Pilar de Goiás" },
          { name: "Piracanjuba" },
          { name: "Piranhas" },
          { name: "Pirenópolis" },
          { name: "Pires do Rio" },
          { name: "Planaltina" },
          { name: "Pontalina" },
          { name: "Porangatu" },
          { name: "Porteirão" },
          { name: "Portelândia" },
          { name: "Posse" },
          { name: "Professor Jamil" },
          { name: "Quirinópolis" },
          { name: "Rialma" },
          { name: "Rianápolis" },
          { name: "Rio Quente" },
          { name: "Rio Verde" },
          { name: "Rubiataba" },
          { name: "Sanclerlândia" },
          { name: "Santa Bárbara de Goiás" },
          { name: "Santa Cruz de Goiás" },
          { name: "Santa Fé de Goiás" },
          { name: "Santa Helena de Goiás" },
          { name: "Santa Isabel" },
          { name: "Santa Rita do Araguaia" },
          { name: "Santa Rita do Novo Destino" },
          { name: "Santa Rosa de Goiás" },
          { name: "Santa Tereza de Goiás" },
          { name: "Santa Terezinha de Goiás" },
          { name: "Santo Antônio da Barra" },
          { name: "Santo Antônio de Goiás" },
          { name: "Santo Antônio do Descoberto" },
          { name: "São Domingos" },
          { name: "São Francisco de Goiás" },
          { name: "São João d'Aliança" },
          { name: "São João da Paraúna" },
          { name: "São Luís de Montes Belos" },
          { name: "São Luíz do Norte" },
          { name: "São Miguel do Araguaia" },
          { name: "São Miguel do Passa Quatro" },
          { name: "São Patrício" },
          { name: "São Simão" },
          { name: "Senador Canedo" },
          { name: "Serranópolis" },
          { name: "Silvânia" },
          { name: "Simolândia" },
          { name: "Sítio d'Abadia" },
          { name: "Taquaral de Goiás" },
          { name: "Teresina de Goiás" },
          { name: "Terezópolis de Goiás" },
          { name: "Três Ranchos" },
          { name: "Trindade" },
          { name: "Trombas" },
          { name: "Turvânia" },
          { name: "Turvelândia" },
          { name: "Uirapuru" },
          { name: "Uruaçu" },
          { name: "Uruana" },
          { name: "Urutaí" },
          { name: "Valparaíso de Goiás" },
          { name: "Varjão" },
          { name: "Vianópolis" },
          { name: "Vicentinópolis" },
          { name: "Vila Boa" },
          { name: "Vila Propício" }
        ]
      },
      {
        name:   "Mato Grosso",
        sigla:  "MT",
        cities: [
          { name: "Acorizal" },
          { name: "Água Boa" },
          { name: "Alta Floresta" },
          { name: "Alto Araguaia" },
          { name: "Alto Boa Vista" },
          { name: "Alto Garças" },
          { name: "Alto Paraguai" },
          { name: "Alto Taquari" },
          { name: "Apiacás" },
          { name: "Araguaiana" },
          { name: "Araguainha" },
          { name: "Araputanga" },
          { name: "Arenápolis" },
          { name: "Aripuanã" },
          { name: "Barão de Melgaço" },
          { name: "Barra do Bugres" },
          { name: "Barra do Garças" },
          { name: "Bom Jesus do Araguaia" },
          { name: "Brasnorte" },
          { name: "Cáceres" },
          { name: "Campinápolis" },
          { name: "Campo Novo do Parecis" },
          { name: "Campo Verde" },
          { name: "Campos de Júlio" },
          { name: "Canabrava do Norte" },
          { name: "Canarana" },
          { name: "Carlinda" },
          { name: "Castanheira" },
          { name: "Chapada dos Guimarães" },
          { name: "Cláudia" },
          { name: "Cocalinho" },
          { name: "Colíder" },
          { name: "Colniza" },
          { name: "Comodoro" },
          { name: "Confresa" },
          { name: "Conquista d'Oeste" },
          { name: "Cotriguaçu" },
          { name: "Curvelândia" },
          { name: "Cuiabá" },
          { name: "Denise" },
          { name: "Diamantino" },
          { name: "Dom Aquino" },
          { name: "Feliz Natal" },
          { name: "Figueirópolis d'Oeste" },
          { name: "Gaúcha do Norte" },
          { name: "General Carneiro" },
          { name: "Glória d'Oeste" },
          { name: "Guarantã do Norte" },
          { name: "Guiratinga" },
          { name: "Indiavaí" },
          { name: "Ipiranga do Norte" },
          { name: "Itanhangá" },
          { name: "Itaúba" },
          { name: "Itiquira" },
          { name: "Jaciara" },
          { name: "Jangada" },
          { name: "Jauru" },
          { name: "Juara" },
          { name: "Juína" },
          { name: "Juruena" },
          { name: "Juscimeira" },
          { name: "Lambari d'Oeste" },
          { name: "Lucas do Rio Verde" },
          { name: "Luciára" },
          { name: "Marcelândia" },
          { name: "Matupá" },
          { name: "Mirassol d'Oeste" },
          { name: "Nobres" },
          { name: "Nortelândia" },
          { name: "Nossa Senhora do Livramento" },
          { name: "Nova Bandeirantes" },
          { name: "Nova Brasilândia" },
          { name: "Nova Canaã do Norte" },
          { name: "Nova Guarita" },
          { name: "Nova Lacerda" },
          { name: "Nova Marilândia" },
          { name: "Nova Maringá" },
          { name: "Nova Monte Verde" },
          { name: "Nova Mutum" },
          { name: "Nova Nazaré" },
          { name: "Nova Olímpia" },
          { name: "Nova Santa Helena" },
          { name: "Nova Ubiratã" },
          { name: "Nova Xavantina" },
          { name: "Novo Horizonte do Norte" },
          { name: "Novo Mundo" },
          { name: "Novo Santo Antônio" },
          { name: "Novo São Joaquim" },
          { name: "Paranaíta" },
          { name: "Paranatinga" },
          { name: "Pedra Preta" },
          { name: "Peixoto de Azevedo" },
          { name: "Planalto da Serra" },
          { name: "Poconé" },
          { name: "Pontal do Araguaia" },
          { name: "Ponte Branca" },
          { name: "Pontes e Lacerda" },
          { name: "Porto Alegre do Norte" },
          { name: "Porto dos Gaúchos" },
          { name: "Porto Esperidião" },
          { name: "Porto Estrela" },
          { name: "Poxoréo" },
          { name: "Primavera do Leste" },
          { name: "Querência" },
          { name: "Reserva do Cabaçal" },
          { name: "Ribeirão Cascalheira" },
          { name: "Ribeirãozinho" },
          { name: "Rio Branco" },
          { name: "Rondolândia" },
          { name: "Rondonópolis" },
          { name: "Rosário Oeste" },
          { name: "Salto do Céu" },
          { name: "Santa Carmem" },
          { name: "Santa Cruz do Xingu" },
          { name: "Santa Rita do Trivelato" },
          { name: "Santa Terezinha" },
          { name: "Santo Afonso" },
          { name: "Santo Antônio do Leste" },
          { name: "Santo Antônio do Leverger" },
          { name: "São Félix do Araguaia" },
          { name: "São José do Povo" },
          { name: "São José do Rio Claro" },
          { name: "São José do Xingu" },
          { name: "São José dos Quatro Marcos" },
          { name: "São Pedro da Cipa" },
          { name: "Sapezal" },
          { name: "Serra Nova Dourada" },
          { name: "Sinop" },
          { name: "Sorriso" },
          { name: "Tabaporã" },
          { name: "Tangará da Serra" },
          { name: "Tapurah" },
          { name: "Terra Nova do Norte" },
          { name: "Tesouro" },
          { name: "Torixoréu" },
          { name: "União do Sul" },
          { name: "Vale de São Domingos" },
          { name: "Várzea Grande" },
          { name: "Vera" },
          { name: "Vila Bela da Santíssima Trindade" },
          { name: "Vila Rica" }
        ]
      },
      {
        name:   "Mato Grosso do Sul",
        sigla:  "MS",
        cities: [
          { name: "Água Clara" },
          { name: "Alcinópolis" },
          { name: "Amambaí" },
          { name: "Anastácio" },
          { name: "Anaurilândia" },
          { name: "Angélica" },
          { name: "Antônio João" },
          { name: "Aparecida do Taboado" },
          { name: "Aquidauana" },
          { name: "Aral Moreira" },
          { name: "Bandeirantes" },
          { name: "Bataguassu" },
          { name: "Batayporã" },
          { name: "Bela Vista" },
          { name: "Bodoquena" },
          { name: "Bonito" },
          { name: "Brasilândia" },
          { name: "Caarapó" },
          { name: "Camapuã" },
          { name: "Campo Grande" },
          { name: "Caracol" },
          { name: "Cassilândia" },
          { name: "Chapadão do Sul" },
          { name: "Corguinho" },
          { name: "Coronel Sapucaia" },
          { name: "Corumbá" },
          { name: "Costa Rica" },
          { name: "Coxim" },
          { name: "Deodápolis" },
          { name: "Dois Irmãos do Buriti" },
          { name: "Douradina" },
          { name: "Dourados" },
          { name: "Eldorado" },
          { name: "Fátima do Sul" },
          { name: "Figueirão" },
          { name: "Glória de Dourados" },
          { name: "Guia Lopes da Laguna" },
          { name: "Iguatemi" },
          { name: "Inocência" },
          { name: "Itaporã" },
          { name: "Itaquiraí" },
          { name: "Ivinhema" },
          { name: "Japorã" },
          { name: "Jaraguari" },
          { name: "Jardim" },
          { name: "Jateí" },
          { name: "Juti" },
          { name: "Ladário" },
          { name: "Laguna Carapã" },
          { name: "Maracaju" },
          { name: "Miranda" },
          { name: "Mundo Novo" },
          { name: "Naviraí" },
          { name: "Nioaque" },
          { name: "Nova Alvorada do Sul" },
          { name: "Nova Andradina" },
          { name: "Novo Horizonte do Sul" },
          { name: "Paraíso das Águas" },
          { name: "Paranaíba" },
          { name: "Paranhos" },
          { name: "Pedro Gomes" },
          { name: "Ponta Porã" },
          { name: "Porto Murtinho" },
          { name: "Ribas do Rio Pardo" },
          { name: "Rio Brilhante" },
          { name: "Rio Negro" },
          { name: "Rio Verde de Mato Grosso" },
          { name: "Rochedo" },
          { name: "Santa Rita do Pardo" },
          { name: "São Gabriel do Oeste" },
          { name: "Selvíria" },
          { name: "Sete Quedas" },
          { name: "Sidrolândia" },
          { name: "Sonora" },
          { name: "Tacuru" },
          { name: "Taquarussu" },
          { name: "Terenos" },
          { name: "Três Lagoas" },
          { name: "Vicentina" }
        ]
      }
    ]
  },
  {
    name:   "Nordeste",
    states: [
      {
        name:   "Alagoas",
        sigla:  "AL",
        cities: [
          { name: "Água Branca" },
          { name: "Anadia" },
          { name: "Arapiraca" },
          { name: "Atalaia" },
          { name: "Barra de Santo Antônio" },
          { name: "Barra de São Miguel" },
          { name: "Batalha" },
          { name: "Belém" },
          { name: "Belo Monte" },
          { name: "Boca da Mata" },
          { name: "Branquinha" },
          { name: "Cacimbinhas" },
          { name: "Cajueiro" },
          { name: "Campestre" },
          { name: "Campo Alegre" },
          { name: "Campo Grande" },
          { name: "Canapi" },
          { name: "Capela" },
          { name: "Carneiros" },
          { name: "Chã Preta" },
          { name: "Coité do Nóia" },
          { name: "Colônia Leopoldina" },
          { name: "Coqueiro Seco" },
          { name: "Coruripe" },
          { name: "Craíbas" },
          { name: "Delmiro Gouveia" },
          { name: "Dois Riachos" },
          { name: "Estrela de Alagoas" },
          { name: "Feira Grande" },
          { name: "Feliz Deserto" },
          { name: "Flexeiras" },
          { name: "Girau do Ponciano" },
          { name: "Ibateguara" },
          { name: "Igaci" },
          { name: "Igreja Nova" },
          { name: "Inhapi" },
          { name: "Jacaré dos Homens" },
          { name: "Jacuípe" },
          { name: "Japaratinga" },
          { name: "Jaramataia" },
          { name: "Jequiá da Praia" },
          { name: "Joaquim Gomes" },
          { name: "Jundiá" },
          { name: "Junqueiro" },
          { name: "Lagoa da Canoa" },
          { name: "Limoeiro de Anadia" },
          { name: "Maceió" },
          { name: "Major Isidoro" },
          { name: "Mar Vermelho" },
          { name: "Maragogi" },
          { name: "Maravilha" },
          { name: "Marechal Deodoro" },
          { name: "Maribondo" },
          { name: "Mata Grande" },
          { name: "Matriz de Camaragibe" },
          { name: "Messias" },
          { name: "Minador do Negrão" },
          { name: "Monteirópolis" },
          { name: "Murici" },
          { name: "Novo Lino" },
          { name: "Olho d'Água das Flores" },
          { name: "Olho d'Água do Casado" },
          { name: "Olho d'Água Grande" },
          { name: "Olivença" },
          { name: "Ouro Branco" },
          { name: "Palestina" },
          { name: "Palmeira dos Índios" },
          { name: "Pão de Açúcar" },
          { name: "Pariconha" },
          { name: "Paripueira" },
          { name: "Passo de Camaragibe" },
          { name: "Paulo Jacinto" },
          { name: "Penedo" },
          { name: "Piaçabuçu" },
          { name: "Pilar" },
          { name: "Pindoba" },
          { name: "Piranhas" },
          { name: "Poço das Trincheiras" },
          { name: "Porto Calvo" },
          { name: "Porto de Pedras" },
          { name: "Porto Real do Colégio" },
          { name: "Quebrangulo" },
          { name: "Rio Largo" },
          { name: "Roteiro" },
          { name: "Santa Luzia do Norte" },
          { name: "Santana do Ipanema" },
          { name: "Santana do Mundaú" },
          { name: "São Brás" },
          { name: "São José da Laje" },
          { name: "São José da Tapera" },
          { name: "São Luís do Quitunde" },
          { name: "São Miguel dos Campos" },
          { name: "São Miguel dos Milagres" },
          { name: "São Sebastião" },
          { name: "Satuba" },
          { name: "Senador Rui Palmeira" },
          { name: "Tanque d'Arca" },
          { name: "Taquarana" },
          { name: "Teotônio Vilela" },
          { name: "Traipu" },
          { name: "União dos Palmares" },
          { name: "Viçosa" }
        ]
      },
      {
        name:   "Bahia",
        sigla:  "BA",
        cities: [
          { name: "Abaíra" },
          { name: "Abaré" },
          { name: "Acajutiba" },
          { name: "Adustina" },
          { name: "Água Fria" },
          { name: "Aiquara" },
          { name: "Alagoinhas" },
          { name: "Alcobaça" },
          { name: "Almadina" },
          { name: "Amargosa" },
          { name: "Amélia Rodrigues" },
          { name: "América Dourada" },
          { name: "Anagé" },
          { name: "Andaraí" },
          { name: "Andorinha" },
          { name: "Angical" },
          { name: "Anguera" },
          { name: "Antas" },
          { name: "Antônio Cardoso" },
          { name: "Antônio Gonçalves" },
          { name: "Aporá" },
          { name: "Apuarema" },
          { name: "Araças" },
          { name: "Aracatu" },
          { name: "Araci" },
          { name: "Aramari" },
          { name: "Arataca" },
          { name: "Aratuípe" },
          { name: "Aurelino Leal" },
          { name: "Baianópolis" },
          { name: "Baixa Grande" },
          { name: "Banzaê" },
          { name: "Barra" },
          { name: "Barra da Estiva" },
          { name: "Barra do Choça" },
          { name: "Barra do Mendes" },
          { name: "Barra do Rocha" },
          { name: "Barreiras" },
          { name: "Barro Alto" },
          { name: "Barrocas" },
          { name: "Barro Preto" },
          { name: "Belmonte" },
          { name: "Belo Campo" },
          { name: "Biritinga" },
          { name: "Boa Nova" },
          { name: "Boa Vista do Tupim" },
          { name: "Bom Jesus da Lapa" },
          { name: "Bom Jesus da Serra" },
          { name: "Boninal" },
          { name: "Bonito" },
          { name: "Boquira" },
          { name: "Botuporã" },
          { name: "Brejões" },
          { name: "Brejolândia" },
          { name: "Brotas de Macaúbas" },
          { name: "Brumado" },
          { name: "Buerarema" },
          { name: "Buritirama" },
          { name: "Caatiba" },
          { name: "Cabaceiras do Paraguaçu" },
          { name: "Cachoeira" },
          { name: "Caculé" },
          { name: "Caém" },
          { name: "Caetanos" },
          { name: "Caetité" },
          { name: "Cafarnaum" },
          { name: "Cairu" },
          { name: "Caldeirão Grande" },
          { name: "Camacan" },
          { name: "Camaçari" },
          { name: "Camamu" },
          { name: "Campo Alegre de Lourdes" },
          { name: "Campo Formoso" },
          { name: "Canápolis" },
          { name: "Canarana" },
          { name: "Canavieiras" },
          { name: "Candeal" },
          { name: "Candeias" },
          { name: "Candiba" },
          { name: "Cândido Sales" },
          { name: "Cansanção" },
          { name: "Canudos" },
          { name: "Capela do Alto Alegre" },
          { name: "Capim Grosso" },
          { name: "Caraíbas" },
          { name: "Caravelas" },
          { name: "Cardeal da Silva" },
          { name: "Carinhanha" },
          { name: "Casa Nova" },
          { name: "Castro Alves" },
          { name: "Catolândia" },
          { name: "Catu" },
          { name: "Caturama" },
          { name: "Central" },
          { name: "Chorrochó" },
          { name: "Cícero Dantas" },
          { name: "Cipó" },
          { name: "Coaraci" },
          { name: "Cocos" },
          { name: "Conceição da Feira" },
          { name: "Conceição do Almeida" },
          { name: "Conceição do Coité" },
          { name: "Conceição do Jacuípe" },
          { name: "Conde" },
          { name: "Condeúba" },
          { name: "Contendas do Sincorá" },
          { name: "Coração de Maria" },
          { name: "Cordeiros" },
          { name: "Coribe" },
          { name: "Coronel João Sá" },
          { name: "Correntina" },
          { name: "Cotegipe" },
          { name: "Cravolândia" },
          { name: "Crisópolis" },
          { name: "Cristópolis" },
          { name: "Cruz das Almas" },
          { name: "Curaçá" },
          { name: "Dário Meira" },
          { name: "Dias d'Ávila" },
          { name: "Dom Basílio" },
          { name: "Dom Macedo Costa" },
          { name: "Elísio Medrado" },
          { name: "Encruzilhada" },
          { name: "Entre Rios" },
          { name: "Érico Cardoso" },
          { name: "Esplanada" },
          { name: "Euclides da Cunha" },
          { name: "Eunápolis" },
          { name: "Fátima" },
          { name: "Feira da Mata" },
          { name: "Feira de Santana" },
          { name: "Filadélfia" },
          { name: "Firmino Alves" },
          { name: "Floresta Azul" },
          { name: "Formosa do Rio Preto" },
          { name: "Gandu" },
          { name: "Gavião" },
          { name: "Gentio do Ouro" },
          { name: "Glória" },
          { name: "Gongogi" },
          { name: "Governador Mangabeira" },
          { name: "Guajeru" },
          { name: "Guanambi" },
          { name: "Guaratinga" },
          { name: "Heliópolis" },
          { name: "Iaçu" },
          { name: "Ibiassucê" },
          { name: "Ibicaraí" },
          { name: "Ibicoara" },
          { name: "Ibicuí" },
          { name: "Ibipeba" },
          { name: "Ibipitanga" },
          { name: "Ibiquera" },
          { name: "Ibirapitanga" },
          { name: "Ibirapuã" },
          { name: "Ibirataia" },
          { name: "Ibitiara" },
          { name: "Ibititá" },
          { name: "Ibotirama" },
          { name: "Ichu" },
          { name: "Igaporã" },
          { name: "Igrapiúna" },
          { name: "Iguaí" },
          { name: "Ilhéus" },
          { name: "Inhambupe" },
          { name: "Ipecaetá" },
          { name: "Ipiaú" },
          { name: "Ipirá" },
          { name: "Ipupiara" },
          { name: "Irajuba" },
          { name: "Iramaia" },
          { name: "Iraquara" },
          { name: "Irará" },
          { name: "Irecê" },
          { name: "Itabela" },
          { name: "Itaberaba" },
          { name: "Itabuna" },
          { name: "Itacaré" },
          { name: "Itaeté" },
          { name: "Itagi" },
          { name: "Itagibá" },
          { name: "Itagimirim" },
          { name: "Itaguaçu da Bahia" },
          { name: "Itaju do Colônia" },
          { name: "Itajuípe" },
          { name: "Itamaraju" },
          { name: "Itamari" },
          { name: "Itambé" },
          { name: "Itanagra" },
          { name: "Itanhém" },
          { name: "Itaparica" },
          { name: "Itapé" },
          { name: "Itapebi" },
          { name: "Itapetinga" },
          { name: "Itapicuru" },
          { name: "Itapitanga" },
          { name: "Itaquara" },
          { name: "Itarantim" },
          { name: "Itatim" },
          { name: "Itiruçu" },
          { name: "Itiúba" },
          { name: "Itororó" },
          { name: "Ituaçu" },
          { name: "Ituberá" },
          { name: "Iuiú" },
          { name: "Jaborandi" },
          { name: "Jacaraci" },
          { name: "Jacobina" },
          { name: "Jaguaquara" },
          { name: "Jaguarari" },
          { name: "Jaguaripe" },
          { name: "Jandaíra" },
          { name: "Jequié" },
          { name: "Jeremoabo" },
          { name: "Jiquiriçá" },
          { name: "Jitaúna" },
          { name: "João Dourado" },
          { name: "Juazeiro" },
          { name: "Jucuruçu" },
          { name: "Jussara" },
          { name: "Jussari" },
          { name: "Jussiape" },
          { name: "Lafaiete Coutinho" },
          { name: "Lagoa Real" },
          { name: "Laje" },
          { name: "Lajedão" },
          { name: "Lajedinho" },
          { name: "Lajedo do Tabocal" },
          { name: "Lamarão" },
          { name: "Lapão" },
          { name: "Lauro de Freitas" },
          { name: "Lençóis" },
          { name: "Licínio de Almeida" },
          { name: "Livramento de Nossa Senhora" },
          { name: "Luís Eduardo Magalhães" },
          { name: "Macajuba" },
          { name: "Macarani" },
          { name: "Macaúbas" },
          { name: "Macururé" },
          { name: "Madre de Deus" },
          { name: "Maetinga" },
          { name: "Maiquinique" },
          { name: "Mairi" },
          { name: "Malhada" },
          { name: "Malhada de Pedras" },
          { name: "Manoel Vitorino" },
          { name: "Mansidão" },
          { name: "Maracás" },
          { name: "Maragogipe" },
          { name: "Maraú" },
          { name: "Marcionílio Souza" },
          { name: "Mascote" },
          { name: "Mata de São João" },
          { name: "Matina" },
          { name: "Medeiros Neto" },
          { name: "Miguel Calmon" },
          { name: "Milagres" },
          { name: "Mirangaba" },
          { name: "Mirante" },
          { name: "Monte Santo" },
          { name: "Morpará" },
          { name: "Morro do Chapéu" },
          { name: "Mortugaba" },
          { name: "Mucugê" },
          { name: "Mucuri" },
          { name: "Mulungu do Morro" },
          { name: "Mundo Novo" },
          { name: "Muniz Ferreira" },
          { name: "Muquém de São Francisco" },
          { name: "Muritiba" },
          { name: "Mutuípe" },
          { name: "Nazaré" },
          { name: "Nilo Peçanha" },
          { name: "Nordestina" },
          { name: "Nova Canaã" },
          { name: "Nova Fátima" },
          { name: "Nova Ibiá" },
          { name: "Nova Itarana" },
          { name: "Nova Redenção" },
          { name: "Nova Soure" },
          { name: "Nova Viçosa" },
          { name: "Novo Horizonte" },
          { name: "Novo Triunfo" },
          { name: "Olindina" },
          { name: "Oliveira dos Brejinhos" },
          { name: "Ouriçangas" },
          { name: "Ourolândia" },
          { name: "Palmas de Monte Alto" },
          { name: "Palmeiras" },
          { name: "Paramirim" },
          { name: "Paratinga" },
          { name: "Paripiranga" },
          { name: "Pau Brasil" },
          { name: "Paulo Afonso" },
          { name: "Pé de Serra" },
          { name: "Pedrão" },
          { name: "Pedro Alexandre" },
          { name: "Piatã" },
          { name: "Pilão Arcado" },
          { name: "Pindaí" },
          { name: "Pindobaçu" },
          { name: "Pintadas" },
          { name: "Piraí do Norte" },
          { name: "Piripá" },
          { name: "Piritiba" },
          { name: "Planaltino" },
          { name: "Planalto" },
          { name: "Poções" },
          { name: "Pojuca" },
          { name: "Ponto Novo" },
          { name: "Porto Seguro" },
          { name: "Potiraguá" },
          { name: "Prado" },
          { name: "Presidente Dutra" },
          { name: "Presidente Jânio Quadros" },
          { name: "Presidente Tancredo Neves" },
          { name: "Queimadas" },
          { name: "Quijingue" },
          { name: "Quixabeira" },
          { name: "Rafael Jambeiro" },
          { name: "Remanso" },
          { name: "Retirolândia" },
          { name: "Riachão das Neves" },
          { name: "Riachão do Jacuípe" },
          { name: "Riacho de Santana" },
          { name: "Ribeira do Amparo" },
          { name: "Ribeira do Pombal" },
          { name: "Ribeirão do Largo" },
          { name: "Rio de Contas" },
          { name: "Rio do Antônio" },
          { name: "Rio do Pires" },
          { name: "Rio Real" },
          { name: "Rodelas" },
          { name: "Ruy Barbosa" },
          { name: "Salinas da Margarida" },
          { name: "Salvador" },
          { name: "Santa Bárbara" },
          { name: "Santa Brígida" },
          { name: "Santa Cruz Cabrália" },
          { name: "Santa Cruz da Vitória" },
          { name: "Santa Inês" },
          { name: "Santa Luzia" },
          { name: "Santa Maria da Vitória" },
          { name: "Santa Rita de Cássia" },
          { name: "Santa Teresinha" },
          { name: "Santaluz" },
          { name: "Santana" },
          { name: "Santanópolis" },
          { name: "Santo Amaro" },
          { name: "Santo Antônio de Jesus" },
          { name: "Santo Estêvão" },
          { name: "São Desidério" },
          { name: "São Domingos" },
          { name: "São Felipe" },
          { name: "São Félix" },
          { name: "São Félix do Coribe" },
          { name: "São Francisco do Conde" },
          { name: "São Gabriel" },
          { name: "São Gonçalo dos Campos" },
          { name: "São José da Vitória" },
          { name: "São José do Jacuípe" },
          { name: "São Miguel das Matas" },
          { name: "São Sebastião do Passé" },
          { name: "Sapeaçu" },
          { name: "Sátiro Dias" },
          { name: "Saubara" },
          { name: "Saúde" },
          { name: "Seabra" },
          { name: "Sebastião Laranjeiras" },
          { name: "Senhor do Bonfim" },
          { name: "Sento Sé" },
          { name: "Serra do Ramalho" },
          { name: "Serra Dourada" },
          { name: "Serra Preta" },
          { name: "Serrinha" },
          { name: "Serrolândia" },
          { name: "Simões Filho" },
          { name: "Sítio do Mato" },
          { name: "Sítio do Quinto" },
          { name: "Sobradinho" },
          { name: "Souto Soares" },
          { name: "Tabocas do Brejo Velho" },
          { name: "Tanhaçu" },
          { name: "Tanque Novo" },
          { name: "Tanquinho" },
          { name: "Taperoá" },
          { name: "Tapiramutá" },
          { name: "Teixeira de Freitas" },
          { name: "Teodoro Sampaio" },
          { name: "Teofilândia" },
          { name: "Teolândia" },
          { name: "Terra Nova" },
          { name: "Tremedal" },
          { name: "Tucano" },
          { name: "Uauá" },
          { name: "Ubaíra" },
          { name: "Ubaitaba" },
          { name: "Ubatã" },
          { name: "Uibaí" },
          { name: "Umburanas" },
          { name: "Una" },
          { name: "Urandi" },
          { name: "Uruçuca" },
          { name: "Utinga" },
          { name: "Valença" },
          { name: "Valente" },
          { name: "Várzea da Roça" },
          { name: "Várzea do Poço" },
          { name: "Várzea Nova" },
          { name: "Varzedo" },
          { name: "Vera Cruz" },
          { name: "Vereda" },
          { name: "Vitória da Conquista" },
          { name: "Wagner" },
          { name: "Wanderley" },
          { name: "Wenceslau Guimarães" },
          { name: "Xique-Xique" }
        ]
      },
      {
        name:   "Ceará",
        sigla:  "CE",
        cities: [
          { name: "Abaiara" },
          { name: "Acarape" },
          { name: "Acaraú" },
          { name: "Acopiara" },
          { name: "Aiuaba" },
          { name: "Alcântaras" },
          { name: "Altaneira" },
          { name: "Alto Santo" },
          { name: "Amontada" },
          { name: "Antonina do Norte" },
          { name: "Apuiarés" },
          { name: "Aquiraz" },
          { name: "Aracati" },
          { name: "Aracoiaba" },
          { name: "Ararendá" },
          { name: "Araripe" },
          { name: "Aratuba" },
          { name: "Arneiroz" },
          { name: "Assaré" },
          { name: "Aurora" },
          { name: "Baixio" },
          { name: "Banabuiú" },
          { name: "Barbalha" },
          { name: "Barreira" },
          { name: "Barro" },
          { name: "Barroquinha" },
          { name: "Baturité" },
          { name: "Beberibe" },
          { name: "Bela Cruz" },
          { name: "Boa Viagem" },
          { name: "Brejo Santo" },
          { name: "Camocim" },
          { name: "Campos Sales" },
          { name: "Canindé" },
          { name: "Capistrano" },
          { name: "Caridade" },
          { name: "Cariré" },
          { name: "Caririaçu" },
          { name: "Cariús" },
          { name: "Carnaubal" },
          { name: "Cascavel" },
          { name: "Catarina" },
          { name: "Catunda" },
          { name: "Caucaia" },
          { name: "Cedro" },
          { name: "Chaval" },
          { name: "Choró" },
          { name: "Chorozinho" },
          { name: "Coreaú" },
          { name: "Crateús" },
          { name: "Crato" },
          { name: "Croatá" },
          { name: "Cruz" },
          { name: "Deputado Irapuan Pinheiro" },
          { name: "Ererê" },
          { name: "Eusébio" },
          { name: "Farias Brito" },
          { name: "Forquilha" },
          { name: "Fortaleza" },
          { name: "Fortim" },
          { name: "Frecheirinha" },
          { name: "General Sampaio" },
          { name: "Graça" },
          { name: "Granja" },
          { name: "Granjeiro" },
          { name: "Groaíras" },
          { name: "Guaiúba" },
          { name: "Guaraciaba do Norte" },
          { name: "Guaramiranga" },
          { name: "Hidrolândia" },
          { name: "Horizonte" },
          { name: "Ibaretama" },
          { name: "Ibiapina" },
          { name: "Ibicuitinga" },
          { name: "Icapuí" },
          { name: "Icó" },
          { name: "Iguatu" },
          { name: "Independência" },
          { name: "Ipaporanga" },
          { name: "Ipaumirim" },
          { name: "Ipu" },
          { name: "Ipueiras" },
          { name: "Iracema" },
          { name: "Irauçuba" },
          { name: "Itaiçaba" },
          { name: "Itaitinga" },
          { name: "Itapajé" },
          { name: "Itapipoca" },
          { name: "Itapiúna" },
          { name: "Itarema" },
          { name: "Itatira" },
          { name: "Jaguaretama" },
          { name: "Jaguaribara" },
          { name: "Jaguaribe" },
          { name: "Jaguaruana" },
          { name: "Jardim" },
          { name: "Jati" },
          { name: "Jijoca de Jericoacoara" },
          { name: "Juazeiro do Norte" },
          { name: "Jucás" },
          { name: "Lavras da Mangabeira" },
          { name: "Limoeiro do Norte" },
          { name: "Madalena" },
          { name: "Maracanaú" },
          { name: "Maranguape" },
          { name: "Marco" },
          { name: "Martinópole" },
          { name: "Massapê" },
          { name: "Mauriti" },
          { name: "Meruoca" },
          { name: "Milagres" },
          { name: "Milhã" },
          { name: "Miraíma" },
          { name: "Missão Velha" },
          { name: "Mombaça" },
          { name: "Monsenhor Tabosa" },
          { name: "Morada Nova" },
          { name: "Moraújo" },
          { name: "Morrinhos" },
          { name: "Mucambo" },
          { name: "Mulungu" },
          { name: "Nova Olinda" },
          { name: "Nova Russas" },
          { name: "Novo Oriente" },
          { name: "Ocara" },
          { name: "Orós" },
          { name: "Pacajus" },
          { name: "Pacatuba" },
          { name: "Pacoti" },
          { name: "Pacujá" },
          { name: "Palhano" },
          { name: "Palmácia" },
          { name: "Paracuru" },
          { name: "Paraipaba" },
          { name: "Parambu" },
          { name: "Paramoti" },
          { name: "Pedra Branca" },
          { name: "Penaforte" },
          { name: "Pentecoste" },
          { name: "Pereiro" },
          { name: "Pindoretama" },
          { name: "Piquet Carneiro" },
          { name: "Pires Ferreira" },
          { name: "Poranga" },
          { name: "Porteiras" },
          { name: "Potengi" },
          { name: "Potiretama" },
          { name: "Quiterianópolis" },
          { name: "Quixadá" },
          { name: "Quixelô" },
          { name: "Quixeramobim" },
          { name: "Quixeré" },
          { name: "Redenção" },
          { name: "Reriutaba" },
          { name: "Russas" },
          { name: "Saboeiro" },
          { name: "Salitre" },
          { name: "Santa Quitéria" },
          { name: "Santana do Acaraú" },
          { name: "Santana do Cariri" },
          { name: "São Benedito" },
          { name: "São Gonçalo do Amarante" },
          { name: "São João do Jaguaribe" },
          { name: "São Luís do Curu" },
          { name: "Senador Pompeu" },
          { name: "Senador Sá" },
          { name: "Sobral" },
          { name: "Solonópole" },
          { name: "Tabuleiro do Norte" },
          { name: "Tamboril" },
          { name: "Tarrafas" },
          { name: "Tauá" },
          { name: "Tejuçuoca" },
          { name: "Tianguá" },
          { name: "Trairi" },
          { name: "Tururu" },
          { name: "Ubajara" },
          { name: "Umari" },
          { name: "Umirim" },
          { name: "Uruburetama" },
          { name: "Uruoca" },
          { name: "Varjota" },
          { name: "Várzea Alegre" },
          { name: "Viçosa do Ceará" }
        ]
      },
      {
        name:   "Maranhão",
        sigla:  "MA",
        cities: [
          { name: "Açailândia" },
          { name: "Afonso Cunha" },
          { name: "Água Doce do Maranhão" },
          { name: "Alcântara" },
          { name: "Aldeias Altas" },
          { name: "Altamira do Maranhão" },
          { name: "Alto Alegre do Maranhão" },
          { name: "Alto Alegre do Pindaré" },
          { name: "Alto Parnaíba" },
          { name: "Amapá do Maranhão" },
          { name: "Amarante do Maranhão" },
          { name: "Anajatuba" },
          { name: "Anapurus" },
          { name: "Apicum-Açu" },
          { name: "Araguanã" },
          { name: "Araioses" },
          { name: "Arame" },
          { name: "Arari" },
          { name: "Axixá" },
          { name: "Bacabal" },
          { name: "Bacabeira" },
          { name: "Bacuri" },
          { name: "Bacurituba" },
          { name: "Balsas" },
          { name: "Barão de Grajaú" },
          { name: "Barra do Corda" },
          { name: "Barreirinhas" },
          { name: "Bela Vista do Maranhão" },
          { name: "Belágua" },
          { name: "Benedito Leite" },
          { name: "Bequimão" },
          { name: "Bernardo do Mearim" },
          { name: "Boa Vista do Gurupi" },
          { name: "Bom Jardim" },
          { name: "Bom Jesus das Selvas" },
          { name: "Bom Lugar" },
          { name: "Brejo" },
          { name: "Brejo de Areia" },
          { name: "Buriti" },
          { name: "Buriti Bravo" },
          { name: "Buriticupu" },
          { name: "Buritirana" },
          { name: "Cachoeira Grande" },
          { name: "Cajapió" },
          { name: "Cajari" },
          { name: "Campestre do Maranhão" },
          { name: "Cândido Mendes" },
          { name: "Cantanhede" },
          { name: "Capinzal do Norte" },
          { name: "Carolina" },
          { name: "Carutapera" },
          { name: "Caxias" },
          { name: "Cedral" },
          { name: "Central do Maranhão" },
          { name: "Centro do Guilherme" },
          { name: "Centro Novo do Maranhão" },
          { name: "Chapadinha" },
          { name: "Cidelândia" },
          { name: "Codó" },
          { name: "Coelho Neto" },
          { name: "Colinas" },
          { name: "Conceição do Lago-Açu" },
          { name: "Coroatá" },
          { name: "Cururupu" },
          { name: "Davinópolis" },
          { name: "Dom Pedro" },
          { name: "Duque Bacelar" },
          { name: "Esperantinópolis" },
          { name: "Estreito" },
          { name: "Feira Nova do Maranhão" },
          { name: "Fernando Falcão" },
          { name: "Formosa da Serra Negra" },
          { name: "Fortaleza dos Nogueiras" },
          { name: "Fortuna" },
          { name: "Godofredo Viana" },
          { name: "Gonçalves Dias" },
          { name: "Governador Archer" },
          { name: "Governador Edison Lobão" },
          { name: "Governador Eugênio Barros" },
          { name: "Governador Luiz Rocha" },
          { name: "Governador Newton Bello" },
          { name: "Governador Nunes Freire" },
          { name: "Graça Aranha" },
          { name: "Grajaú" },
          { name: "Guimarães" },
          { name: "Humberto de Campos" },
          { name: "Icatu" },
          { name: "Igarapé do Meio" },
          { name: "Igarapé Grande" },
          { name: "Imperatriz" },
          { name: "Itaipava do Grajaú" },
          { name: "Itapecuru Mirim" },
          { name: "Itinga do Maranhão" },
          { name: "Jatobá" },
          { name: "Jenipapo dos Vieiras" },
          { name: "João Lisboa" },
          { name: "Joselândia" },
          { name: "Junco do Maranhão" },
          { name: "Lago da Pedra" },
          { name: "Lago do Junco" },
          { name: "Lago dos Rodrigues" },
          { name: "Lago Verde" },
          { name: "Lagoa do Mato" },
          { name: "Lagoa Grande do Maranhão" },
          { name: "Lajeado Novo" },
          { name: "Lima Campos" },
          { name: "Loreto" },
          { name: "Luís Domingues" },
          { name: "Magalhães de Almeida" },
          { name: "Maracaçumé" },
          { name: "Marajá do Sena" },
          { name: "Maranhãozinho" },
          { name: "Mata Roma" },
          { name: "Matinha" },
          { name: "Matões" },
          { name: "Matões do Norte" },
          { name: "Milagres do Maranhão" },
          { name: "Mirador" },
          { name: "Miranda do Norte" },
          { name: "Mirinzal" },
          { name: "Monção" },
          { name: "Montes Altos" },
          { name: "Morros" },
          { name: "Nina Rodrigues" },
          { name: "Nova Colinas" },
          { name: "Nova Iorque" },
          { name: "Nova Olinda do Maranhão" },
          { name: "Olho d'Água das Cunhãs" },
          { name: "Olinda Nova do Maranhão" },
          { name: "Paço do Lumiar" },
          { name: "Palmeirândia" },
          { name: "Paraibano" },
          { name: "Parnarama" },
          { name: "Passagem Franca" },
          { name: "Pastos Bons" },
          { name: "Paulino Neves" },
          { name: "Paulo Ramos" },
          { name: "Pedreiras" },
          { name: "Pedro do Rosário" },
          { name: "Penalva" },
          { name: "Peri Mirim" },
          { name: "Peritoró" },
          { name: "Pindaré Mirim" },
          { name: "Pinheiro" },
          { name: "Pio XII" },
          { name: "Pirapemas" },
          { name: "Poção de Pedras" },
          { name: "Porto Franco" },
          { name: "Porto Rico do Maranhão" },
          { name: "Presidente Dutra" },
          { name: "Presidente Juscelino" },
          { name: "Presidente Médici" },
          { name: "Presidente Sarney" },
          { name: "Presidente Vargas" },
          { name: "Primeira Cruz" },
          { name: "Raposa" },
          { name: "Riachão" },
          { name: "Ribamar Fiquene" },
          { name: "Rosário" },
          { name: "Sambaíba" },
          { name: "Santa Filomena do Maranhão" },
          { name: "Santa Helena" },
          { name: "Santa Inês" },
          { name: "Santa Luzia" },
          { name: "Santa Luzia do Paruá" },
          { name: "Santa Quitéria do Maranhão" },
          { name: "Santa Rita" },
          { name: "Santana do Maranhão" },
          { name: "Santo Amaro do Maranhão" },
          { name: "Santo Antônio dos Lopes" },
          { name: "São Benedito do Rio Preto" },
          { name: "São Bento" },
          { name: "São Bernardo" },
          { name: "São Domingos do Azeitão" },
          { name: "São Domingos do Maranhão" },
          { name: "São Félix de Balsas" },
          { name: "São Francisco do Brejão" },
          { name: "São Francisco do Maranhão" },
          { name: "São João Batista" },
          { name: "São João do Carú" },
          { name: "São João do Paraíso" },
          { name: "São João do Soter" },
          { name: "São João dos Patos" },
          { name: "São José de Ribamar" },
          { name: "São José dos Basílios" },
          { name: "São Luís" },
          { name: "São Luís Gonzaga do Maranhão" },
          { name: "São Mateus do Maranhão" },
          { name: "São Pedro da Água Branca" },
          { name: "São Pedro dos Crentes" },
          { name: "São Raimundo das Mangabeiras" },
          { name: "São Raimundo do Doca Bezerra" },
          { name: "São Roberto" },
          { name: "São Vicente Ferrer" },
          { name: "Satubinha" },
          { name: "Senador Alexandre Costa" },
          { name: "Senador La Rocque" },
          { name: "Serrano do Maranhão" },
          { name: "Sítio Novo" },
          { name: "Sucupira do Norte" },
          { name: "Sucupira do Riachão" },
          { name: "Tasso Fragoso" },
          { name: "Timbiras" },
          { name: "Timon" },
          { name: "Trizidela do Vale" },
          { name: "Tufilândia" },
          { name: "Tuntum" },
          { name: "Turiaçu" },
          { name: "Turilândia" },
          { name: "Tutóia" },
          { name: "Urbano Santos" },
          { name: "Vargem Grande" },
          { name: "Viana" },
          { name: "Vila Nova dos Martírios" },
          { name: "Vitória do Mearim" },
          { name: "Vitorino Freire" },
          { name: "Zé Doca" }
        ]
      },
      {
        name:   "Paraíba",
        sigla:  "PB",
        cities: [
          { name: "Água Branca" },
          { name: "Aguiar" },
          { name: "Alagoa Grande" },
          { name: "Alagoa Nova" },
          { name: "Alagoinha" },
          { name: "Alcantil" },
          { name: "Algodão de Jandaíra" },
          { name: "Alhandra" },
          { name: "Amparo" },
          { name: "Aparecida" },
          { name: "Araçagi" },
          { name: "Arara" },
          { name: "Araruna" },
          { name: "Areia" },
          { name: "Areia de Baraúnas" },
          { name: "Areial" },
          { name: "Aroeiras" },
          { name: "Assunção" },
          { name: "Baía da Traição" },
          { name: "Bananeiras" },
          { name: "Baraúna" },
          { name: "Barra de Santa Rosa" },
          { name: "Barra de Santana" },
          { name: "Barra de São Miguel" },
          { name: "Bayeux" },
          { name: "Belém" },
          { name: "Belém do Brejo do Cruz" },
          { name: "Bernardino Batista" },
          { name: "Boa Ventura" },
          { name: "Boa Vista" },
          { name: "Bom Jesus" },
          { name: "Bom Sucesso" },
          { name: "Bonito de Santa Fé" },
          { name: "Boqueirão" },
          { name: "Borborema" },
          { name: "Brejo do Cruz" },
          { name: "Brejo dos Santos" },
          { name: "Caaporã" },
          { name: "Cabaceiras" },
          { name: "Cabedelo" },
          { name: "Cachoeira dos Índios" },
          { name: "Cacimba de Areia" },
          { name: "Cacimba de Dentro" },
          { name: "Cacimbas" },
          { name: "Caiçara" },
          { name: "Cajazeiras" },
          { name: "Cajazeirinhas" },
          { name: "Caldas Brandão" },
          { name: "Camalaú" },
          { name: "Campina Grande" },
          { name: "Campo de Santana" },
          { name: "Capim" },
          { name: "Caraúbas" },
          { name: "Carrapateira" },
          { name: "Casserengue" },
          { name: "Catingueira" },
          { name: "Catolé do Rocha" },
          { name: "Caturité" },
          { name: "Conceição" },
          { name: "Condado" },
          { name: "Conde" },
          { name: "Congo" },
          { name: "Coremas" },
          { name: "Coxixola" },
          { name: "Cruz do Espírito Santo" },
          { name: "Cubati" },
          { name: "Cuité" },
          { name: "Cuité de Mamanguape" },
          { name: "Cuitegi" },
          { name: "Curral de Cima" },
          { name: "Curral Velho" },
          { name: "Damião" },
          { name: "Desterro" },
          { name: "Diamante" },
          { name: "Dona Inês" },
          { name: "Duas Estradas" },
          { name: "Emas" },
          { name: "Esperança" },
          { name: "Fagundes" },
          { name: "Frei Martinho" },
          { name: "Gado Bravo" },
          { name: "Guarabira" },
          { name: "Gurinhém" },
          { name: "Gurjão" },
          { name: "Ibiara" },
          { name: "Igaracy" },
          { name: "Imaculada" },
          { name: "Ingá" },
          { name: "Itabaiana" },
          { name: "Itaporanga" },
          { name: "Itapororoca" },
          { name: "Itatuba" },
          { name: "Jacaraú" },
          { name: "Jericó" },
          { name: "João Pessoa" },
          { name: "Juarez Távora" },
          { name: "Juazeirinho" },
          { name: "Junco do Seridó" },
          { name: "Juripiranga" },
          { name: "Juru" },
          { name: "Lagoa" },
          { name: "Lagoa de Dentro" },
          { name: "Lagoa Seca" },
          { name: "Lastro" },
          { name: "Livramento" },
          { name: "Logradouro" },
          { name: "Lucena" },
          { name: "Mãe d'Água" },
          { name: "Malta" },
          { name: "Mamanguape" },
          { name: "Manaíra" },
          { name: "Marcação" },
          { name: "Mari" },
          { name: "Marizópolis" },
          { name: "Massaranduba" },
          { name: "Mataraca" },
          { name: "Matinhas" },
          { name: "Mato Grosso" },
          { name: "Maturéia" },
          { name: "Mogeiro" },
          { name: "Montadas" },
          { name: "Monte Horebe" },
          { name: "Monteiro" },
          { name: "Mulungu" },
          { name: "Natuba" },
          { name: "Nazarezinho" },
          { name: "Nova Floresta" },
          { name: "Nova Olinda" },
          { name: "Nova Palmeira" },
          { name: "Olho d'Água" },
          { name: "Olivedos" },
          { name: "Ouro Velho" },
          { name: "Parari" },
          { name: "Passagem" },
          { name: "Patos" },
          { name: "Paulista" },
          { name: "Pedra Branca" },
          { name: "Pedra Lavrada" },
          { name: "Pedras de Fogo" },
          { name: "Pedro Régis" },
          { name: "Piancó" },
          { name: "Picuí" },
          { name: "Pilar" },
          { name: "Pilões" },
          { name: "Pilõezinhos" },
          { name: "Pirpirituba" },
          { name: "Pitimbu" },
          { name: "Pocinhos" },
          { name: "Poço Dantas" },
          { name: "Poço de José de Moura" },
          { name: "Pombal" },
          { name: "Prata" },
          { name: "Princesa Isabel" },
          { name: "Puxinanã" },
          { name: "Queimadas" },
          { name: "Quixabá" },
          { name: "Remígio" },
          { name: "Riachão" },
          { name: "Riachão do Bacamarte" },
          { name: "Riachão do Poço" },
          { name: "Riacho de Santo Antônio" },
          { name: "Riacho dos Cavalos" },
          { name: "Rio Tinto" },
          { name: "Salgadinho" },
          { name: "Salgado de São Félix" },
          { name: "Santa Cecília" },
          { name: "Santa Cruz" },
          { name: "Santa Helena" },
          { name: "Santa Inês" },
          { name: "Santa Luzia" },
          { name: "Santa Rita" },
          { name: "Santa Teresinha" },
          { name: "Santana de Mangueira" },
          { name: "Santana dos Garrotes" },
          { name: "Santarém" },
          { name: "Santo André" },
          { name: "São Bentinho" },
          { name: "São Bento" },
          { name: "São Domingos de Pombal" },
          { name: "São Domingos do Cariri" },
          { name: "São Francisco" },
          { name: "São João do Cariri" },
          { name: "São João do Rio do Peixe" },
          { name: "São João do Tigre" },
          { name: "São José da Lagoa Tapada" },
          { name: "São José de Caiana" },
          { name: "São José de Espinharas" },
          { name: "São José de Piranhas" },
          { name: "São José de Princesa" },
          { name: "São José do Bonfim" },
          { name: "São José do Brejo do Cruz" },
          { name: "São José do Sabugi" },
          { name: "São José dos Cordeiros" },
          { name: "São José dos Ramos" },
          { name: "São Mamede" },
          { name: "São Miguel de Taipu" },
          { name: "São Sebastião de Lagoa de Roça" },
          { name: "São Sebastião do Umbuzeiro" },
          { name: "Sapé" },
          { name: "Seridó" },
          { name: "Serra Branca" },
          { name: "Serra da Raiz" },
          { name: "Serra Grande" },
          { name: "Serra Redonda" },
          { name: "Serraria" },
          { name: "Sertãozinho" },
          { name: "Sobrado" },
          { name: "Solânea" },
          { name: "Soledade" },
          { name: "Sossêgo" },
          { name: "Sousa" },
          { name: "Sumé" },
          { name: "Taperoá" },
          { name: "Tavares" },
          { name: "Teixeira" },
          { name: "Tenório" },
          { name: "Triunfo" },
          { name: "Uiraúna" },
          { name: "Umbuzeiro" },
          { name: "Várzea" },
          { name: "Vieirópolis" },
          { name: "Vista Serrana" },
          { name: "Zabelê" }
        ]
      },
      {
        name:   "Pernambuco",
        sigla:  "PE",
        cities: [
          { name: "Abreu e Lima" },
          { name: "Afogados da Ingazeira" },
          { name: "Afrânio" },
          { name: "Agrestina" },
          { name: "Água Preta" },
          { name: "Águas Belas" },
          { name: "Alagoinha" },
          { name: "Aliança" },
          { name: "Altinho" },
          { name: "Amaraji" },
          { name: "Angelim" },
          { name: "Araçoiaba" },
          { name: "Araripina" },
          { name: "Arcoverde" },
          { name: "Barra de Guabiraba" },
          { name: "Barreiros" },
          { name: "Belém de Maria" },
          { name: "Belém de São Francisco" },
          { name: "Belo Jardim" },
          { name: "Betânia" },
          { name: "Bezerros" },
          { name: "Bodocó" },
          { name: "Bom Conselho" },
          { name: "Bom Jardim" },
          { name: "Bonito" },
          { name: "Brejão" },
          { name: "Brejinho" },
          { name: "Brejo da Madre de Deus" },
          { name: "Buenos Aires" },
          { name: "Buíque" },
          { name: "Cabo de Santo Agostinho" },
          { name: "Cabrobó" },
          { name: "Cachoeirinha" },
          { name: "Caetés" },
          { name: "Calçado" },
          { name: "Calumbi" },
          { name: "Camaragibe" },
          { name: "Camocim de São Félix" },
          { name: "Camutanga" },
          { name: "Canhotinho" },
          { name: "Capoeiras" },
          { name: "Carnaíba" },
          { name: "Carnaubeira da Penha" },
          { name: "Carpina" },
          { name: "Caruaru" },
          { name: "Casinhas" },
          { name: "Catende" },
          { name: "Cedro" },
          { name: "Chã de Alegria" },
          { name: "Chã Grande" },
          { name: "Condado" },
          { name: "Correntes" },
          { name: "Cortês" },
          { name: "Cumaru" },
          { name: "Cupira" },
          { name: "Custódia" },
          { name: "Dormentes" },
          { name: "Escada" },
          { name: "Exu" },
          { name: "Feira Nova" },
          { name: "Fernando de Noronha" },
          { name: "Ferreiros" },
          { name: "Flores" },
          { name: "Floresta" },
          { name: "Frei Miguelinho" },
          { name: "Gameleira" },
          { name: "Garanhuns" },
          { name: "Glória do Goitá" },
          { name: "Goiana" },
          { name: "Granito" },
          { name: "Gravatá" },
          { name: "Iati" },
          { name: "Ibimirim" },
          { name: "Ibirajuba" },
          { name: "Igarassu" },
          { name: "Iguaraci" },
          { name: "Ilha de Itamaracá" },
          { name: "Inajá" },
          { name: "Ingazeira" },
          { name: "Ipojuca" },
          { name: "Ipubi" },
          { name: "Itacuruba" },
          { name: "Itaíba" },
          { name: "Itambé" },
          { name: "Itapetim" },
          { name: "Itapissuma" },
          { name: "Itaquitinga" },
          { name: "Jaboatão dos Guararapes" },
          { name: "Jaqueira" },
          { name: "Jataúba" },
          { name: "Jatobá" },
          { name: "João Alfredo" },
          { name: "Joaquim Nabuco" },
          { name: "Jucati" },
          { name: "Jupi" },
          { name: "Jurema" },
          { name: "Lagoa do Carro" },
          { name: "Lagoa do Itaenga" },
          { name: "Lagoa do Ouro" },
          { name: "Lagoa dos Gatos" },
          { name: "Lagoa Grande" },
          { name: "Lajedo" },
          { name: "Limoeiro" },
          { name: "Macaparana" },
          { name: "Machados" },
          { name: "Manari" },
          { name: "Maraial" },
          { name: "Mirandiba" },
          { name: "Moreilândia" },
          { name: "Moreno" },
          { name: "Nazaré da Mata" },
          { name: "Olinda" },
          { name: "Orobó" },
          { name: "Orocó" },
          { name: "Ouricuri" },
          { name: "Palmares" },
          { name: "Palmeirina" },
          { name: "Panelas" },
          { name: "Paranatama" },
          { name: "Parnamirim" },
          { name: "Passira" },
          { name: "Paudalho" },
          { name: "Paulista" },
          { name: "Pedra" },
          { name: "Pesqueira" },
          { name: "Petrolândia" },
          { name: "Petrolina" },
          { name: "Poção" },
          { name: "Pombos" },
          { name: "Primavera" },
          { name: "Quipapá" },
          { name: "Quixaba" },
          { name: "Recife" },
          { name: "Riacho das Almas" },
          { name: "Ribeirão" },
          { name: "Rio Formoso" },
          { name: "Sairé" },
          { name: "Salgadinho" },
          { name: "Salgueiro" },
          { name: "Saloá" },
          { name: "Sanharó" },
          { name: "Santa Cruz" },
          { name: "Santa Cruz da Baixa Verde" },
          { name: "Santa Cruz do Capibaribe" },
          { name: "Santa Filomena" },
          { name: "Santa Maria da Boa Vista" },
          { name: "Santa Maria do Cambucá" },
          { name: "Santa Terezinha" },
          { name: "São Benedito do Sul" },
          { name: "São Bento do Una" },
          { name: "São Caitano" },
          { name: "São João" },
          { name: "São Joaquim do Monte" },
          { name: "São José da Coroa Grande" },
          { name: "São José do Belmonte" },
          { name: "São José do Egito" },
          { name: "São Lourenço da Mata" },
          { name: "São Vicente Ferrer" },
          { name: "Serra Talhada" },
          { name: "Serrita" },
          { name: "Sertânia" },
          { name: "Sirinhaém" },
          { name: "Solidão" },
          { name: "Surubim" },
          { name: "Tabira" },
          { name: "Tacaimbó" },
          { name: "Tacaratu" },
          { name: "Tamandaré" },
          { name: "Taquaritinga do Norte" },
          { name: "Terezinha" },
          { name: "Terra Nova" },
          { name: "Timbaúba" },
          { name: "Toritama" },
          { name: "Tracunhaém" },
          { name: "Trindade" },
          { name: "Triunfo" },
          { name: "Tupanatinga" },
          { name: "Tuparetama" },
          { name: "Venturosa" },
          { name: "Verdejante" },
          { name: "Vertente do Lério" },
          { name: "Vertentes" },
          { name: "Vicência" },
          { name: "Vitória de Santo Antão" },
          { name: "Xexéu" }
        ]
      },
      {
        name:   "Piauí",
        sigla:  "PI",
        cities: [
          { name: "Acauã" },
          { name: "Agricolândia" },
          { name: "Água Branca" },
          { name: "Alagoinha do Piauí" },
          { name: "Alegrete do Piauí" },
          { name: "Alto Longá" },
          { name: "Altos" },
          { name: "Alvorada do Gurguéia" },
          { name: "Amarante" },
          { name: "Angical do Piauí" },
          { name: "Anísio de Abreu" },
          { name: "Antônio Almeida" },
          { name: "Aroazes" },
          { name: "Aroeiras do Itaim" },
          { name: "Arraial" },
          { name: "Assunção do Piauí" },
          { name: "Avelino Lopes" },
          { name: "Baixa Grande do Ribeiro" },
          { name: "Barra d'Alcântara" },
          { name: "Barras" },
          { name: "Barreiras do Piauí" },
          { name: "Barro Duro" },
          { name: "Batalha" },
          { name: "Bela Vista do Piauí" },
          { name: "Belém do Piauí" },
          { name: "Beneditinos" },
          { name: "Bertolínia" },
          { name: "Betânia do Piauí" },
          { name: "Boa Hora" },
          { name: "Bocaina" },
          { name: "Bom Jesus" },
          { name: "Bom Princípio do Piauí" },
          { name: "Bonfim do Piauí" },
          { name: "Boqueirão do Piauí" },
          { name: "Brasileira" },
          { name: "Brejo do Piauí" },
          { name: "Buriti dos Lopes" },
          { name: "Buriti dos Montes" },
          { name: "Cabeceiras do Piauí" },
          { name: "Cajazeiras do Piauí" },
          { name: "Cajueiro da Praia" },
          { name: "Caldeirão Grande do Piauí" },
          { name: "Campinas do Piauí" },
          { name: "Campo Alegre do Fidalgo" },
          { name: "Campo Grande do Piauí" },
          { name: "Campo Largo do Piauí" },
          { name: "Campo Maior" },
          { name: "Canavieira" },
          { name: "Canto do Buriti" },
          { name: "Capitão de Campos" },
          { name: "Capitão Gervásio Oliveira" },
          { name: "Caracol" },
          { name: "Caraúbas do Piauí" },
          { name: "Caridade do Piauí" },
          { name: "Castelo do Piauí" },
          { name: "Caxingó" },
          { name: "Cocal" },
          { name: "Cocal de Telha" },
          { name: "Cocal dos Alves" },
          { name: "Coivaras" },
          { name: "Colônia do Gurguéia" },
          { name: "Colônia do Piauí" },
          { name: "Conceição do Canindé" },
          { name: "Coronel José Dias" },
          { name: "Corrente" },
          { name: "Cristalândia do Piauí" },
          { name: "Cristino Castro" },
          { name: "Curimatá" },
          { name: "Currais" },
          { name: "Curral Novo do Piauí" },
          { name: "Curralinhos" },
          { name: "Demerval Lobão" },
          { name: "Dirceu Arcoverde" },
          { name: "Dom Expedito Lopes" },
          { name: "Dom Inocêncio" },
          { name: "Domingos Mourão" },
          { name: "Elesbão Veloso" },
          { name: "Eliseu Martins" },
          { name: "Esperantina" },
          { name: "Fartura do Piauí" },
          { name: "Flores do Piauí" },
          { name: "Floresta do Piauí" },
          { name: "Floriano" },
          { name: "Francinópolis" },
          { name: "Francisco Ayres" },
          { name: "Francisco Macedo" },
          { name: "Francisco Santos" },
          { name: "Fronteiras" },
          { name: "Geminiano" },
          { name: "Gilbués" },
          { name: "Guadalupe" },
          { name: "Guaribas" },
          { name: "Hugo Napoleão" },
          { name: "Ilha Grande" },
          { name: "Inhuma" },
          { name: "Ipiranga do Piauí" },
          { name: "Isaías Coelho" },
          { name: "Itainópolis" },
          { name: "Itaueira" },
          { name: "Jacobina do Piauí" },
          { name: "Jaicós" },
          { name: "Jardim do Mulato" },
          { name: "Jatobá do Piauí" },
          { name: "Jerumenha" },
          { name: "João Costa" },
          { name: "Joaquim Pires" },
          { name: "Joca Marques" },
          { name: "José de Freitas" },
          { name: "Juazeiro do Piauí" },
          { name: "Júlio Borges" },
          { name: "Jurema" },
          { name: "Lagoa Alegre" },
          { name: "Lagoa de São Francisco" },
          { name: "Lagoa do Barro do Piauí" },
          { name: "Lagoa do Piauí" },
          { name: "Lagoa do Sítio" },
          { name: "Lagoinha do Piauí" },
          { name: "Landri Sales" },
          { name: "Luís Correia" },
          { name: "Luzilândia" },
          { name: "Madeiro" },
          { name: "Manoel Emídio" },
          { name: "Marcolândia" },
          { name: "Marcos Parente" },
          { name: "Massapê do Piauí" },
          { name: "Matias Olímpio" },
          { name: "Miguel Alves" },
          { name: "Miguel Leão" },
          { name: "Milton Brandão" },
          { name: "Monsenhor Gil" },
          { name: "Monsenhor Hipólito" },
          { name: "Monte Alegre do Piauí" },
          { name: "Morro Cabeça no Tempo" },
          { name: "Morro do Chapéu do Piauí" },
          { name: "Murici dos Portelas" },
          { name: "Nazaré do Piauí" },
          { name: "Nossa Senhora de Nazaré" },
          { name: "Nossa Senhora dos Remédios" },
          { name: "Nova Santa Rita" },
          { name: "Novo Oriente do Piauí" },
          { name: "Novo Santo Antônio" },
          { name: "Oeiras" },
          { name: "Olho d'Água do Piauí" },
          { name: "Padre Marcos" },
          { name: "Paes Landim" },
          { name: "Pajeú do Piauí" },
          { name: "Palmeira do Piauí" },
          { name: "Palmeirais" },
          { name: "Paquetá" },
          { name: "Parnaguá" },
          { name: "Parnaíba" },
          { name: "Passagem Franca do Piauí" },
          { name: "Patos do Piauí" },
          { name: "Pau d'Arco do Piauí" },
          { name: "Paulistana" },
          { name: "Pavussu" },
          { name: "Pedro II" },
          { name: "Pedro Laurentino" },
          { name: "Picos" },
          { name: "Pimenteiras" },
          { name: "Pio IX" },
          { name: "Piracuruca" },
          { name: "Piripiri" },
          { name: "Porto" },
          { name: "Porto Alegre do Piauí" },
          { name: "Prata do Piauí" },
          { name: "Queimada Nova" },
          { name: "Redenção do Gurguéia" },
          { name: "Regeneração" },
          { name: "Riacho Frio" },
          { name: "Ribeira do Piauí" },
          { name: "Ribeiro Gonçalves" },
          { name: "Rio Grande do Piauí" },
          { name: "Santa Cruz do Piauí" },
          { name: "Santa Cruz dos Milagres" },
          { name: "Santa Filomena" },
          { name: "Santa Luz" },
          { name: "Santa Rosa do Piauí" },
          { name: "Santana do Piauí" },
          { name: "Santo Antônio de Lisboa" },
          { name: "Santo Antônio dos Milagres" },
          { name: "Santo Inácio do Piauí" },
          { name: "São Braz do Piauí" },
          { name: "São Félix do Piauí" },
          { name: "São Francisco de Assis do Piauí" },
          { name: "São Francisco do Piauí" },
          { name: "São Gonçalo do Gurguéia" },
          { name: "São Gonçalo do Piauí" },
          { name: "São João da Canabrava" },
          { name: "São João da Fronteira" },
          { name: "São João da Serra" },
          { name: "São João da Varjota" },
          { name: "São João do Arraial" },
          { name: "São João do Piauí" },
          { name: "São José do Divino" },
          { name: "São José do Peixe" },
          { name: "São José do Piauí" },
          { name: "São Julião" },
          { name: "São Lourenço do Piauí" },
          { name: "São Luis do Piauí" },
          { name: "São Miguel da Baixa Grande" },
          { name: "São Miguel do Fidalgo" },
          { name: "São Miguel do Tapuio" },
          { name: "São Pedro do Piauí" },
          { name: "São Raimundo Nonato" },
          { name: "Sebastião Barros" },
          { name: "Sebastião Leal" },
          { name: "Sigefredo Pacheco" },
          { name: "Simões" },
          { name: "Simplício Mendes" },
          { name: "Socorro do Piauí" },
          { name: "Sussuapara" },
          { name: "Tamboril do Piauí" },
          { name: "Tanque do Piauí" },
          { name: "Teresina" },
          { name: "União" },
          { name: "Uruçuí" },
          { name: "Valença do Piauí" },
          { name: "Várzea Branca" },
          { name: "Várzea Grande" },
          { name: "Vera Mendes" },
          { name: "Vila Nova do Piauí" },
          { name: "Wall Ferraz" }
        ]
      },
      {
        name:   "Rio Grande do Norte",
        sigla:  "RN",
        cities: [
          { name: "Acari" },
          { name: "Açu" },
          { name: "Afonso Bezerra" },
          { name: "Água Nova" },
          { name: "Alexandria" },
          { name: "Almino Afonso" },
          { name: "Alto do Rodrigues" },
          { name: "Angicos" },
          { name: "Antônio Martins" },
          { name: "Apodi" },
          { name: "Areia Branca" },
          { name: "Arês" },
          { name: "Augusto Severo" },
          { name: "Baía Formosa" },
          { name: "Baraúna" },
          { name: "Barcelona" },
          { name: "Bento Fernandes" },
          { name: "Bodó" },
          { name: "Bom Jesus" },
          { name: "Brejinho" },
          { name: "Caiçara do Norte" },
          { name: "Caiçara do Rio do Vento" },
          { name: "Caicó" },
          { name: "Campo Redondo" },
          { name: "Canguaretama" },
          { name: "Caraúbas" },
          { name: "Carnaúba dos Dantas" },
          { name: "Carnaubais" },
          { name: "Ceará-Mirim" },
          { name: "Cerro Corá" },
          { name: "Coronel Ezequiel" },
          { name: "Coronel João Pessoa" },
          { name: "Cruzeta" },
          { name: "Currais Novos" },
          { name: "Doutor Severiano" },
          { name: "Encanto" },
          { name: "Equador" },
          { name: "Espírito Santo" },
          { name: "Extremoz" },
          { name: "Felipe Guerra" },
          { name: "Fernando Pedroza" },
          { name: "Florânia" },
          { name: "Francisco Dantas" },
          { name: "Frutuoso Gomes" },
          { name: "Galinhos" },
          { name: "Goianinha" },
          { name: "Governador Dix-Sept Rosado" },
          { name: "Grossos" },
          { name: "Guamaré" },
          { name: "Ielmo Marinho" },
          { name: "Ipanguaçu" },
          { name: "Ipueira" },
          { name: "Itajá" },
          { name: "Itaú" },
          { name: "Jaçanã" },
          { name: "Jandaíra" },
          { name: "Janduís" },
          { name: "Januário Cicco" },
          { name: "Japi" },
          { name: "Jardim de Angicos" },
          { name: "Jardim de Piranhas" },
          { name: "Jardim do Seridó" },
          { name: "João Câmara" },
          { name: "João Dias" },
          { name: "José da Penha" },
          { name: "Jucurutu" },
          { name: "Jundiá" },
          { name: "Lagoa d'Anta" },
          { name: "Lagoa de Pedras" },
          { name: "Lagoa de Velhos" },
          { name: "Lagoa Nova" },
          { name: "Lagoa Salgada" },
          { name: "Lajes" },
          { name: "Lajes Pintadas" },
          { name: "Lucrécia" },
          { name: "Luís Gomes" },
          { name: "Macaíba" },
          { name: "Macau" },
          { name: "Major Sales" },
          { name: "Marcelino Vieira" },
          { name: "Martins" },
          { name: "Maxaranguape" },
          { name: "Messias Targino" },
          { name: "Montanhas" },
          { name: "Monte Alegre" },
          { name: "Monte das Gameleiras" },
          { name: "Mossoró" },
          { name: "Natal" },
          { name: "Nísia Floresta" },
          { name: "Nova Cruz" },
          { name: "Olho-d'Água do Borges" },
          { name: "Ouro Branco" },
          { name: "Paraná" },
          { name: "Paraú" },
          { name: "Parazinho" },
          { name: "Parelhas" },
          { name: "Parnamirim" },
          { name: "Passa e Fica" },
          { name: "Passagem" },
          { name: "Patu" },
          { name: "Pau dos Ferros" },
          { name: "Pedra Grande" },
          { name: "Pedra Preta" },
          { name: "Pedro Avelino" },
          { name: "Pedro Velho" },
          { name: "Pendências" },
          { name: "Pilões" },
          { name: "Poço Branco" },
          { name: "Portalegre" },
          { name: "Porto do Mangue" },
          { name: "Presidente Juscelino" },
          { name: "Pureza" },
          { name: "Rafael Fernandes" },
          { name: "Rafael Godeiro" },
          { name: "Riacho da Cruz" },
          { name: "Riacho de Santana" },
          { name: "Riachuelo" },
          { name: "Rio do Fogo" },
          { name: "Rodolfo Fernandes" },
          { name: "Ruy Barbosa" },
          { name: "Santa Cruz" },
          { name: "Santa Maria" },
          { name: "Santana do Matos" },
          { name: "Santana do Seridó" },
          { name: "Santo Antônio" },
          { name: "São Bento do Norte" },
          { name: "São Bento do Trairí" },
          { name: "São Fernando" },
          { name: "São Francisco do Oeste" },
          { name: "São Gonçalo do Amarante" },
          { name: "São João do Sabugi" },
          { name: "São José de Mipibu" },
          { name: "São José do Campestre" },
          { name: "São José do Seridó" },
          { name: "São Miguel" },
          { name: "São Miguel de Touros" },
          { name: "São Paulo do Potengi" },
          { name: "São Pedro" },
          { name: "São Rafael" },
          { name: "São Tomé" },
          { name: "São Vicente" },
          { name: "Senador Elói de Souza" },
          { name: "Senador Georgino Avelino" },
          { name: "Serra de São Bento" },
          { name: "Serra do Mel" },
          { name: "Serra Negra do Norte" },
          { name: "Serrinha" },
          { name: "Serrinha dos Pintos" },
          { name: "Severiano Melo" },
          { name: "Sítio Novo" },
          { name: "Taboleiro Grande" },
          { name: "Taipu" },
          { name: "Tangará" },
          { name: "Tenente Ananias" },
          { name: "Tenente Laurentino Cruz" },
          { name: "Tibau" },
          { name: "Tibau do Sul" },
          { name: "Timbaúba dos Batistas" },
          { name: "Touros" },
          { name: "Triunfo Potiguar" },
          { name: "Umarizal" },
          { name: "Upanema" },
          { name: "Várzea" },
          { name: "Venha-Ver" },
          { name: "Vera Cruz" },
          { name: "Viçosa" },
          { name: "Vila Flor" }
        ]
      },
      {
        name:   "Sergipe",
        sigla:  "SE",
        cities: [
          { name: "Amparo de São Francisco" },
          { name: "Aquidabã" },
          { name: "Aracaju" },
          { name: "Arauá" },
          { name: "Areia Branca" },
          { name: "Barra dos Coqueiros" },
          { name: "Boquim" },
          { name: "Brejo Grande" },
          { name: "Campo do Brito" },
          { name: "Canhoba" },
          { name: "Canindé de São Francisco" },
          { name: "Capela" },
          { name: "Carira" },
          { name: "Carmópolis" },
          { name: "Cedro de São João" },
          { name: "Cristinápolis" },
          { name: "Cumbe" },
          { name: "Divina Pastora" },
          { name: "Estância" },
          { name: "Feira Nova" },
          { name: "Frei Paulo" },
          { name: "Gararu" },
          { name: "General Maynard" },
          { name: "Gracho Cardoso" },
          { name: "Ilha das Flores" },
          { name: "Indiaroba" },
          { name: "Itabaiana" },
          { name: "Itabaianinha" },
          { name: "Itabi" },
          { name: "Itaporanga d'Ajuda" },
          { name: "Japaratuba" },
          { name: "Japoatã" },
          { name: "Lagarto" },
          { name: "Laranjeiras" },
          { name: "Macambira" },
          { name: "Malhada dos Bois" },
          { name: "Malhador" },
          { name: "Maruim" },
          { name: "Moita Bonita" },
          { name: "Monte Alegre de Sergipe" },
          { name: "Muribeca" },
          { name: "Neópolis" },
          { name: "Nossa Senhora Aparecida" },
          { name: "Nossa Senhora da Glória" },
          { name: "Nossa Senhora das Dores" },
          { name: "Nossa Senhora de Lourdes" },
          { name: "Nossa Senhora do Socorro" },
          { name: "Pacatuba" },
          { name: "Pedra Mole" },
          { name: "Pedrinhas" },
          { name: "Pinhão" },
          { name: "Pirambu" },
          { name: "Poço Redondo" },
          { name: "Poço Verde" },
          { name: "Porto da Folha" },
          { name: "Propriá" },
          { name: "Riachão do Dantas" },
          { name: "Riachuelo" },
          { name: "Ribeirópolis" },
          { name: "Rosário do Catete" },
          { name: "Salgado" },
          { name: "Santa Luzia do Itanhy" },
          { name: "Santa Rosa de Lima" },
          { name: "Santana do São Francisco" },
          { name: "Santo Amaro das Brotas" },
          { name: "São Cristóvão" },
          { name: "São Domingos" },
          { name: "São Francisco" },
          { name: "São Miguel do Aleixo" },
          { name: "Simão Dias" },
          { name: "Siriri" },
          { name: "Telha" },
          { name: "Tobias Barreto" },
          { name: "Tomar do Geru" },
          { name: "Umbaúba" }
        ]
      }
    ]
  },
  {
    name:   "Norte",
    states: [
      {
        name:   "Acre",
        sigla:  "AC",
        cities: [
          { name: "Acrelândia" },
          { name: "Assis Brasil" },
          { name: "Brasiléia" },
          { name: "Bujari" },
          { name: "Capixaba" },
          { name: "Cruzeiro do Sul" },
          { name: "Epitaciolândia" },
          { name: "Feijó" },
          { name: "Jordão" },
          { name: "Mâncio Lima" },
          { name: "Manoel Urbano" },
          { name: "Marechal Thaumaturgo" },
          { name: "Plácido de Castro" },
          { name: "Porto Acre" },
          { name: "Porto Walter" },
          { name: "Rio Branco" },
          { name: "Rodrigues Alves" },
          { name: "Santa Rosa do Purus" },
          { name: "Sena Madureira" },
          { name: "Senador Guiomard" },
          { name: "Tarauacá" },
          { name: "Xapuri" }
        ]
      },
      {
        name:   "Amapá",
        sigla:  "AP",
        cities: [
          { name: "Amapá" },
          { name: "Calçoene" },
          { name: "Cutias" },
          { name: "Ferreira Gomes" },
          { name: "Itaubal" },
          { name: "Laranjal do Jari" },
          { name: "Macapá" },
          { name: "Mazagão" },
          { name: "Oiapoque" },
          { name: "Pedra Branca do Amapari" },
          { name: "Porto Grande" },
          { name: "Pracuúba" },
          { name: "Santana" },
          { name: "Serra do Navio" },
          { name: "Tartarugalzinho" },
          { name: "Vitória do Jari" }
        ]
      },
      {
        name:   "Amazonas",
        sigla:  "AM",
        cities: [
          { name: "Alvarães" },
          { name: "Amaturá" },
          { name: "Anamã" },
          { name: "Anori" },
          { name: "Apuí" },
          { name: "Atalaia do Norte" },
          { name: "Autazes" },
          { name: "Barcelos" },
          { name: "Barreirinha" },
          { name: "Benjamin Constant" },
          { name: "Beruri" },
          { name: "Boa Vista do Ramos" },
          { name: "Boca do Acre" },
          { name: "Borba" },
          { name: "Caapiranga" },
          { name: "Canutama" },
          { name: "Carauari" },
          { name: "Careiro" },
          { name: "Careiro da Várzea" },
          { name: "Coari" },
          { name: "Codajás" },
          { name: "Eirunepé" },
          { name: "Envira" },
          { name: "Fonte Boa" },
          { name: "Guajará" },
          { name: "Humaitá" },
          { name: "Ipixuna" },
          { name: "Iranduba" },
          { name: "Itacoatiara" },
          { name: "Itamarati" },
          { name: "Itapiranga" },
          { name: "Japurá" },
          { name: "Juruá" },
          { name: "Jutaí" },
          { name: "Lábrea" },
          { name: "Manacapuru" },
          { name: "Manaquiri" },
          { name: "Manaus" },
          { name: "Manicoré" },
          { name: "Maraã" },
          { name: "Maués" },
          { name: "Nhamundá" },
          { name: "Nova Olinda do Norte" },
          { name: "Novo Airão" },
          { name: "Novo Aripuanã" },
          { name: "Parintins" },
          { name: "Pauini" },
          { name: "Presidente Figueiredo" },
          { name: "Rio Preto da Eva" },
          { name: "Santa Isabel do Rio Negro" },
          { name: "Santo Antônio do Içá" },
          { name: "São Gabriel da Cachoeira" },
          { name: "São Paulo de Olivença" },
          { name: "São Sebastião do Uatumã" },
          { name: "Silves" },
          { name: "Tabatinga" },
          { name: "Tapauá" },
          { name: "Tefé" },
          { name: "Tonantins" },
          { name: "Uarini" },
          { name: "Urucará" },
          { name: "Urucurituba" }
        ]
      },
      {
        name:   "Pará",
        sigla:  "PA",
        cities: [
          { name: "Abaetetuba" },
          { name: "Abel Figueiredo" },
          { name: "Acará" },
          { name: "Afuá" },
          { name: "Água Azul do Norte" },
          { name: "Alenquer" },
          { name: "Almeirim" },
          { name: "Altamira" },
          { name: "Anajás" },
          { name: "Ananindeua" },
          { name: "Anapu" },
          { name: "Augusto Corrêa" },
          { name: "Aurora do Pará" },
          { name: "Aveiro" },
          { name: "Bagre" },
          { name: "Baião" },
          { name: "Bannach" },
          { name: "Barcarena" },
          { name: "Belém" },
          { name: "Belterra" },
          { name: "Benevides" },
          { name: "Bom Jesus do Tocantins" },
          { name: "Bonito" },
          { name: "Bragança" },
          { name: "Brasil Novo" },
          { name: "Brejo Grande do Araguaia" },
          { name: "Breu Branco" },
          { name: "Breves" },
          { name: "Bujaru" },
          { name: "Cachoeira do Arari" },
          { name: "Cachoeira do Piriá" },
          { name: "Cametá" },
          { name: "Canaã dos Carajás" },
          { name: "Capanema" },
          { name: "Capitão Poço" },
          { name: "Castanhal" },
          { name: "Chaves" },
          { name: "Colares" },
          { name: "Conceição do Araguaia" },
          { name: "Concórdia do Pará" },
          { name: "Cumaru do Norte" },
          { name: "Curionópolis" },
          { name: "Curralinho" },
          { name: "Curuá" },
          { name: "Curuçá" },
          { name: "Dom Eliseu" },
          { name: "Eldorado dos Carajás" },
          { name: "Faro" },
          { name: "Floresta do Araguaia" },
          { name: "Garrafão do Norte" },
          { name: "Goianésia do Pará" },
          { name: "Gurupá" },
          { name: "Igarapé-Açu" },
          { name: "Igarapé-Miri" },
          { name: "Inhangapi" },
          { name: "Ipixuna do Pará" },
          { name: "Irituia" },
          { name: "Itaituba" },
          { name: "Itupiranga" },
          { name: "Jacareacanga" },
          { name: "Jacundá" },
          { name: "Juruti" },
          { name: "Limoeiro do Ajuru" },
          { name: "Mãe do Rio" },
          { name: "Magalhães Barata" },
          { name: "Marabá" },
          { name: "Maracanã" },
          { name: "Marapanim" },
          { name: "Marituba" },
          { name: "Medicilândia" },
          { name: "Melgaço" },
          { name: "Mocajuba" },
          { name: "Moju" },
          { name: "Mojuí dos Campos" },
          { name: "Monte Alegre" },
          { name: "Muaná" },
          { name: "Nova Esperança do Piriá" },
          { name: "Nova Ipixuna" },
          { name: "Nova Timboteua" },
          { name: "Novo Progresso" },
          { name: "Novo Repartimento" },
          { name: "Óbidos" },
          { name: "Oeiras do Pará" },
          { name: "Oriximiná" },
          { name: "Ourém" },
          { name: "Ourilândia do Norte" },
          { name: "Pacajá" },
          { name: "Palestina do Pará" },
          { name: "Paragominas" },
          { name: "Parauapebas" },
          { name: "Pau d'Arco" },
          { name: "Peixe-Boi" },
          { name: "Piçarra" },
          { name: "Placas" },
          { name: "Ponta de Pedras" },
          { name: "Portel" },
          { name: "Porto de Moz" },
          { name: "Prainha" },
          { name: "Primavera" },
          { name: "Quatipuru" },
          { name: "Redenção" },
          { name: "Rio Maria" },
          { name: "Rondon do Pará" },
          { name: "Rurópolis" },
          { name: "Salinópolis" },
          { name: "Salvaterra" },
          { name: "Santa Bárbara do Pará" },
          { name: "Santa Cruz do Arari" },
          { name: "Santa Isabel do Pará" },
          { name: "Santa Luzia do Pará" },
          { name: "Santa Maria das Barreiras" },
          { name: "Santa Maria do Pará" },
          { name: "Santana do Araguaia" },
          { name: "Santarém" },
          { name: "Santarém Novo" },
          { name: "Santo Antônio do Tauá" },
          { name: "São Caetano de Odivelas" },
          { name: "São Domingos do Araguaia" },
          { name: "São Domingos do Capim" },
          { name: "São Félix do Xingu" },
          { name: "São Francisco do Pará" },
          { name: "São Geraldo do Araguaia" },
          { name: "São João da Ponta" },
          { name: "São João de Pirabas" },
          { name: "São João do Araguaia" },
          { name: "São Miguel do Guamá" },
          { name: "São Sebastião da Boa Vista" },
          { name: "Sapucaia" },
          { name: "Senador José Porfírio" },
          { name: "Soure" },
          { name: "Tailândia" },
          { name: "Terra Alta" },
          { name: "Terra Santa" },
          { name: "Tomé-Açu" },
          { name: "Tracuateua" },
          { name: "Trairão" },
          { name: "Tucumã" },
          { name: "Tucuruí" },
          { name: "Ulianópolis" },
          { name: "Uruará" },
          { name: "Vigia" },
          { name: "Viseu" },
          { name: "Vitória do Xingu" },
          { name: "Xinguara" }
        ]
      },
      {
        name:   "Rondônia",
        sigla:  "RO",
        cities: [
          { name: "Alta Floresta d'Oeste" },
          { name: "Alto Alegre do Parecis" },
          { name: "Alto Paraíso" },
          { name: "Alvorada d'Oeste" },
          { name: "Ariquemes" },
          { name: "Buritis" },
          { name: "Cabixi" },
          { name: "Cacaulândia" },
          { name: "Cacoal" },
          { name: "Campo Novo de Rondônia" },
          { name: "Candeias do Jamari" },
          { name: "Castanheiras" },
          { name: "Cerejeiras" },
          { name: "Chupinguaia" },
          { name: "Colorado do Oeste" },
          { name: "Corumbiara" },
          { name: "Costa Marques" },
          { name: "Cujubim" },
          { name: "Espigão d'Oeste" },
          { name: "Governador Jorge Teixeira" },
          { name: "Guajará-Mirim" },
          { name: "Itapuã do Oeste" },
          { name: "Jaru" },
          { name: "Ji-Paraná" },
          { name: "Machadinho d'Oeste" },
          { name: "Ministro Andreazza" },
          { name: "Mirante da Serra" },
          { name: "Monte Negro" },
          { name: "Nova Brasilândia d'Oeste" },
          { name: "Nova Mamoré" },
          { name: "Nova União" },
          { name: "Novo Horizonte do Oeste" },
          { name: "Ouro Preto do Oeste" },
          { name: "Parecis" },
          { name: "Pimenta Bueno" },
          { name: "Pimenteiras do Oeste" },
          { name: "Porto Velho" },
          { name: "Presidente Médici" },
          { name: "Primavera de Rondônia" },
          { name: "Rio Crespo" },
          { name: "Rolim de Moura" },
          { name: "Santa Luzia d'Oeste" },
          { name: "São Felipe d'Oeste" },
          { name: "São Francisco do Guaporé" },
          { name: "São Miguel do Guaporé" },
          { name: "Seringueiras" },
          { name: "Teixeirópolis" },
          { name: "Theobroma" },
          { name: "Urupá" },
          { name: "Vale do Anari" },
          { name: "Vale do Paraíso" },
          { name: "Vilhena" }
        ]
      },
      {
        name:   "Roraima",
        sigla:  "RR",
        cities: [
          { name: "Alto Alegre" },
          { name: "Amajari" },
          { name: "Boa Vista" },
          { name: "Bonfim" },
          { name: "Cantá" },
          { name: "Caracaraí" },
          { name: "Caroebe" },
          { name: "Iracema" },
          { name: "Mucajaí" },
          { name: "Normandia" },
          { name: "Pacaraima" },
          { name: "Rorainópolis" },
          { name: "São João da Baliza" },
          { name: "São Luiz" },
          { name: "Uiramutã" }
        ]
      },
      {
        name:   "Tocantins",
        sigla:  "TO",
        cities: [
          { name: "Abreulândia" },
          { name: "Aguiarnópolis" },
          { name: "Aliança do Tocantins" },
          { name: "Almas" },
          { name: "Alvorada" },
          { name: "Ananás" },
          { name: "Angico" },
          { name: "Aparecida do Rio Negro" },
          { name: "Aragominas" },
          { name: "Araguacema" },
          { name: "Araguaçu" },
          { name: "Araguaína" },
          { name: "Araguanã" },
          { name: "Araguatins" },
          { name: "Arapoema" },
          { name: "Arraias" },
          { name: "Augustinópolis" },
          { name: "Aurora do Tocantins" },
          { name: "Axixá do Tocantins" },
          { name: "Babaçulândia" },
          { name: "Bandeirantes do Tocantins" },
          { name: "Barra do Ouro" },
          { name: "Barrolândia" },
          { name: "Bernardo Sayão" },
          { name: "Bom Jesus do Tocantins" },
          { name: "Brasilândia do Tocantins" },
          { name: "Brejinho de Nazaré" },
          { name: "Buriti do Tocantins" },
          { name: "Cachoeirinha" },
          { name: "Campos Lindos" },
          { name: "Cariri do Tocantins" },
          { name: "Carmolândia" },
          { name: "Carrasco Bonito" },
          { name: "Caseara" },
          { name: "Centenário" },
          { name: "Chapada da Natividade" },
          { name: "Chapada de Areia" },
          { name: "Colinas do Tocantins" },
          { name: "Colméia" },
          { name: "Combinado" },
          { name: "Conceição do Tocantins" },
          { name: "Couto Magalhães" },
          { name: "Cristalândia" },
          { name: "Crixás do Tocantins" },
          { name: "Darcinópolis" },
          { name: "Dianópolis" },
          { name: "Divinópolis do Tocantins" },
          { name: "Dois Irmãos do Tocantins" },
          { name: "Dueré" },
          { name: "Esperantina" },
          { name: "Fátima" },
          { name: "Figueirópolis" },
          { name: "Filadélfia" },
          { name: "Formoso do Araguaia" },
          { name: "Fortaleza do Tabocão" },
          { name: "Goianorte" },
          { name: "Goiatins" },
          { name: "Guaraí" },
          { name: "Gurupi" },
          { name: "Ipueiras" },
          { name: "Itacajá" },
          { name: "Itaguatins" },
          { name: "Itapiratins" },
          { name: "Itaporã do Tocantins" },
          { name: "Jaú do Tocantins" },
          { name: "Juarina" },
          { name: "Lagoa da Confusão" },
          { name: "Lagoa do Tocantins" },
          { name: "Lajeado" },
          { name: "Lavandeira" },
          { name: "Lizarda" },
          { name: "Luzinópolis" },
          { name: "Marianópolis do Tocantins" },
          { name: "Mateiros" },
          { name: "Maurilândia do Tocantins" },
          { name: "Miracema do Tocantins" },
          { name: "Miranorte" },
          { name: "Monte do Carmo" },
          { name: "Monte Santo do Tocantins" },
          { name: "Muricilândia" },
          { name: "Natividade" },
          { name: "Nazaré" },
          { name: "Nova Olinda" },
          { name: "Nova Rosalândia" },
          { name: "Novo Acordo" },
          { name: "Novo Alegre" },
          { name: "Novo Jardim" },
          { name: "Oliveira de Fátima" },
          { name: "Palmas" },
          { name: "Palmeirante" },
          { name: "Palmeiras do Tocantins" },
          { name: "Palmeirópolis" },
          { name: "Paraíso do Tocantins" },
          { name: "Paranã" },
          { name: "Pau d'Arco" },
          { name: "Pedro Afonso" },
          { name: "Peixe" },
          { name: "Pequizeiro" },
          { name: "Pindorama do Tocantins" },
          { name: "Piraquê" },
          { name: "Pium" },
          { name: "Ponte Alta do Bom Jesus" },
          { name: "Ponte Alta do Tocantins" },
          { name: "Porto Alegre do Tocantins" },
          { name: "Porto Nacional" },
          { name: "Praia Norte" },
          { name: "Presidente Kennedy" },
          { name: "Pugmil" },
          { name: "Recursolândia" },
          { name: "Riachinho" },
          { name: "Rio da Conceição" },
          { name: "Rio dos Bois" },
          { name: "Rio Sono" },
          { name: "Sampaio" },
          { name: "Sandolândia" },
          { name: "Santa Fé do Araguaia" },
          { name: "Santa Maria do Tocantins" },
          { name: "Santa Rita do Tocantins" },
          { name: "Santa Rosa do Tocantins" },
          { name: "Santa Tereza do Tocantins" },
          { name: "Santa Terezinha do Tocantins" },
          { name: "São Bento do Tocantins" },
          { name: "São Félix do Tocantins" },
          { name: "São Miguel do Tocantins" },
          { name: "São Salvador do Tocantins" },
          { name: "São Sebastião do Tocantins" },
          { name: "São Valério da Natividade" },
          { name: "São Valério" },
          { name: "Silvanópolis" },
          { name: "Sítio Novo do Tocantins" },
          { name: "Sucupira" },
          { name: "Taguatinga" },
          { name: "Taipas do Tocantins" },
          { name: "Talismã" },
          { name: "Tocantínia" },
          { name: "Tocantinópolis" },
          { name: "Tupirama" },
          { name: "Tupiratins" },
          { name: "Wanderlândia" },
          { name: "Xambioá" }
        ]
      }
    ]
  },
  {
    name:   "Sudeste",
    states: [
      {
        name:   "Espírito Santo",
        sigla:  "ES",
        cities: [
          { name: "Afonso Cláudio" },
          { name: "Água Doce do Norte" },
          { name: "Águia Branca" },
          { name: "Alegre" },
          { name: "Alfredo Chaves" },
          { name: "Alto Rio Novo" },
          { name: "Anchieta" },
          { name: "Apiacá" },
          { name: "Aracruz" },
          { name: "Atilio Vivacqua" },
          { name: "Baixo Guandu" },
          { name: "Barra de São Francisco" },
          { name: "Boa Esperança" },
          { name: "Bom Jesus do Norte" },
          { name: "Brejetuba" },
          { name: "Cachoeiro de Itapemirim" },
          { name: "Cariacica" },
          { name: "Castelo" },
          { name: "Colatina" },
          { name: "Conceição da Barra" },
          { name: "Conceição do Castelo" },
          { name: "Divino de São Lourenço" },
          { name: "Domingos Martins" },
          { name: "Dores do Rio Preto" },
          { name: "Ecoporanga" },
          { name: "Fundão" },
          { name: "Governador Lindenberg" },
          { name: "Guaçuí" },
          { name: "Guarapari" },
          { name: "Ibatiba" },
          { name: "Ibiraçu" },
          { name: "Ibitirama" },
          { name: "Iconha" },
          { name: "Irupi" },
          { name: "Itaguaçu" },
          { name: "Itapemirim" },
          { name: "Itarana" },
          { name: "Iúna" },
          { name: "Jaguaré" },
          { name: "Jerônimo Monteiro" },
          { name: "João Neiva" },
          { name: "Laranja da Terra" },
          { name: "Linhares" },
          { name: "Mantenópolis" },
          { name: "Marataízes" },
          { name: "Marechal Floriano" },
          { name: "Marilândia" },
          { name: "Mimoso do Sul" },
          { name: "Montanha" },
          { name: "Mucurici" },
          { name: "Muniz Freire" },
          { name: "Muqui" },
          { name: "Nova Venécia" },
          { name: "Pancas" },
          { name: "Pedro Canário" },
          { name: "Pinheiros" },
          { name: "Piúma" },
          { name: "Ponto Belo" },
          { name: "Presidente Kennedy" },
          { name: "Rio Bananal" },
          { name: "Rio Novo do Sul" },
          { name: "Santa Leopoldina" },
          { name: "Santa Maria de Jetibá" },
          { name: "Santa Teresa" },
          { name: "São Domingos do Norte" },
          { name: "São Gabriel da Palha" },
          { name: "São José do Calçado" },
          { name: "São Mateus" },
          { name: "São Roque do Canaã" },
          { name: "Serra" },
          { name: "Sooretama" },
          { name: "Vargem Alta" },
          { name: "Venda Nova do Imigrante" },
          { name: "Viana" },
          { name: "Vila Pavão" },
          { name: "Vila Valério" },
          { name: "Vila Velha" },
          { name: "Vitória" }
        ]
      },
      {
        name:   "Minas Gerais",
        sigla:  "MG",
        cities: [
          { name: "Abadia dos Dourados" },
          { name: "Abaeté" },
          { name: "Abre Campo" },
          { name: "Acaiaca" },
          { name: "Açucena" },
          { name: "Água Boa" },
          { name: "Água Comprida" },
          { name: "Aguanil" },
          { name: "Águas Formosas" },
          { name: "Águas Vermelhas" },
          { name: "Aimorés" },
          { name: "Aiuruoca" },
          { name: "Alagoa" },
          { name: "Albertina" },
          { name: "Além Paraíba" },
          { name: "Alfenas" },
          { name: "Alfredo Vasconcelos" },
          { name: "Almenara" },
          { name: "Alpercata" },
          { name: "Alpinópolis" },
          { name: "Alterosa" },
          { name: "Alto Caparaó" },
          { name: "Alto Jequitibá" },
          { name: "Alto Rio Doce" },
          { name: "Alvarenga" },
          { name: "Alvinópolis" },
          { name: "Alvorada de Minas" },
          { name: "Amparo do Serra" },
          { name: "Andradas" },
          { name: "Andrelândia" },
          { name: "Angelândia" },
          { name: "Antônio Carlos" },
          { name: "Antônio Dias" },
          { name: "Antônio Prado de Minas" },
          { name: "Araçaí" },
          { name: "Aracitaba" },
          { name: "Araçuaí" },
          { name: "Araguari" },
          { name: "Arantina" },
          { name: "Araponga" },
          { name: "Araporã" },
          { name: "Arapuá" },
          { name: "Araújos" },
          { name: "Araxá" },
          { name: "Arceburgo" },
          { name: "Arcos" },
          { name: "Areado" },
          { name: "Argirita" },
          { name: "Aricanduva" },
          { name: "Arinos" },
          { name: "Astolfo Dutra" },
          { name: "Ataléia" },
          { name: "Augusto de Lima" },
          { name: "Baependi" },
          { name: "Baldim" },
          { name: "Bambuí" },
          { name: "Bandeira" },
          { name: "Bandeira do Sul" },
          { name: "Barão de Cocais" },
          { name: "Barão de Monte Alto" },
          { name: "Barbacena" },
          { name: "Barra Longa" },
          { name: "Barroso" },
          { name: "Bela Vista de Minas" },
          { name: "Belmiro Braga" },
          { name: "Belo Horizonte" },
          { name: "Belo Oriente" },
          { name: "Belo Vale" },
          { name: "Berilo" },
          { name: "Berizal" },
          { name: "Bertópolis" },
          { name: "Betim" },
          { name: "Bias Fortes" },
          { name: "Bicas" },
          { name: "Biquinhas" },
          { name: "Boa Esperança" },
          { name: "Bocaina de Minas" },
          { name: "Bocaiúva" },
          { name: "Bom Despacho" },
          { name: "Bom Jardim de Minas" },
          { name: "Bom Jesus da Penha" },
          { name: "Bom Jesus do Amparo" },
          { name: "Bom Jesus do Galho" },
          { name: "Bom Repouso" },
          { name: "Bom Sucesso" },
          { name: "Bonfim" },
          { name: "Bonfinópolis de Minas" },
          { name: "Bonito de Minas" },
          { name: "Borda da Mata" },
          { name: "Botelhos" },
          { name: "Botumirim" },
          { name: "Brás Pires" },
          { name: "Brasilândia de Minas" },
          { name: "Brasília de Minas" },
          { name: "Brasópolis" },
          { name: "Braúnas" },
          { name: "Brumadinho" },
          { name: "Bueno Brandão" },
          { name: "Buenópolis" },
          { name: "Bugre" },
          { name: "Buritis" },
          { name: "Buritizeiro" },
          { name: "Cabeceira Grande" },
          { name: "Cabo Verde" },
          { name: "Cachoeira da Prata" },
          { name: "Cachoeira de Minas" },
          { name: "Cachoeira de Pajeú" },
          { name: "Cachoeira Dourada" },
          { name: "Caetanópolis" },
          { name: "Caeté" },
          { name: "Caiana" },
          { name: "Cajuri" },
          { name: "Caldas" },
          { name: "Camacho" },
          { name: "Camanducaia" },
          { name: "Cambuí" },
          { name: "Cambuquira" },
          { name: "Campanário" },
          { name: "Campanha" },
          { name: "Campestre" },
          { name: "Campina Verde" },
          { name: "Campo Azul" },
          { name: "Campo Belo" },
          { name: "Campo do Meio" },
          { name: "Campo Florido" },
          { name: "Campos Altos" },
          { name: "Campos Gerais" },
          { name: "Cana Verde" },
          { name: "Canaã" },
          { name: "Canápolis" },
          { name: "Candeias" },
          { name: "Cantagalo" },
          { name: "Caparaó" },
          { name: "Capela Nova" },
          { name: "Capelinha" },
          { name: "Capetinga" },
          { name: "Capim Branco" },
          { name: "Capinópolis" },
          { name: "Capitão Andrade" },
          { name: "Capitão Enéas" },
          { name: "Capitólio" },
          { name: "Caputira" },
          { name: "Caraí" },
          { name: "Caranaíba" },
          { name: "Carandaí" },
          { name: "Carangola" },
          { name: "Caratinga" },
          { name: "Carbonita" },
          { name: "Careaçu" },
          { name: "Carlos Chagas" },
          { name: "Carmésia" },
          { name: "Carmo da Cachoeira" },
          { name: "Carmo da Mata" },
          { name: "Carmo de Minas" },
          { name: "Carmo do Cajuru" },
          { name: "Carmo do Paranaíba" },
          { name: "Carmo do Rio Claro" },
          { name: "Carmópolis de Minas" },
          { name: "Carneirinho" },
          { name: "Carrancas" },
          { name: "Carvalhópolis" },
          { name: "Carvalhos" },
          { name: "Casa Grande" },
          { name: "Cascalho Rico" },
          { name: "Cássia" },
          { name: "Cataguases" },
          { name: "Catas Altas" },
          { name: "Catas Altas da Noruega" },
          { name: "Catuji" },
          { name: "Catuti" },
          { name: "Caxambu" },
          { name: "Cedro do Abaeté" },
          { name: "Central de Minas" },
          { name: "Centralina" },
          { name: "Chácara" },
          { name: "Chalé" },
          { name: "Chapada do Norte" },
          { name: "Chapada Gaúcha" },
          { name: "Chiador" },
          { name: "Cipotânea" },
          { name: "Claraval" },
          { name: "Claro dos Poções" },
          { name: "Cláudio" },
          { name: "Coimbra" },
          { name: "Coluna" },
          { name: "Comendador Gomes" },
          { name: "Comercinho" },
          { name: "Conceição da Aparecida" },
          { name: "Conceição da Barra de Minas" },
          { name: "Conceição das Alagoas" },
          { name: "Conceição das Pedras" },
          { name: "Conceição de Ipanema" },
          { name: "Conceição do Mato Dentro" },
          { name: "Conceição do Pará" },
          { name: "Conceição do Rio Verde" },
          { name: "Conceição dos Ouros" },
          { name: "Cônego Marinho" },
          { name: "Confins" },
          { name: "Congonhal" },
          { name: "Congonhas" },
          { name: "Congonhas do Norte" },
          { name: "Conquista" },
          { name: "Conselheiro Lafaiete" },
          { name: "Conselheiro Pena" },
          { name: "Consolação" },
          { name: "Contagem" },
          { name: "Coqueiral" },
          { name: "Coração de Jesus" },
          { name: "Cordisburgo" },
          { name: "Cordislândia" },
          { name: "Corinto" },
          { name: "Coroaci" },
          { name: "Coromandel" },
          { name: "Coronel Fabriciano" },
          { name: "Coronel Murta" },
          { name: "Coronel Pacheco" },
          { name: "Coronel Xavier Chaves" },
          { name: "Córrego Danta" },
          { name: "Córrego do Bom Jesus" },
          { name: "Córrego Fundo" },
          { name: "Córrego Novo" },
          { name: "Couto de Magalhães de Minas" },
          { name: "Crisólita" },
          { name: "Cristais" },
          { name: "Cristália" },
          { name: "Cristiano Otoni" },
          { name: "Cristina" },
          { name: "Crucilândia" },
          { name: "Cruzeiro da Fortaleza" },
          { name: "Cruzília" },
          { name: "Cuparaque" },
          { name: "Curral de Dentro" },
          { name: "Curvelo" },
          { name: "Datas" },
          { name: "Delfim Moreira" },
          { name: "Delfinópolis" },
          { name: "Delta" },
          { name: "Descoberto" },
          { name: "Desterro de Entre Rios" },
          { name: "Desterro do Melo" },
          { name: "Diamantina" },
          { name: "Diogo de Vasconcelos" },
          { name: "Dionísio" },
          { name: "Divinésia" },
          { name: "Divino" },
          { name: "Divino das Laranjeiras" },
          { name: "Divinolândia de Minas" },
          { name: "Divinópolis" },
          { name: "Divisa Alegre" },
          { name: "Divisa Nova" },
          { name: "Divisópolis" },
          { name: "Dom Bosco" },
          { name: "Dom Cavati" },
          { name: "Dom Joaquim" },
          { name: "Dom Silvério" },
          { name: "Dom Viçoso" },
          { name: "Dona Euzébia" },
          { name: "Dores de Campos" },
          { name: "Dores de Guanhães" },
          { name: "Dores do Indaiá" },
          { name: "Dores do Turvo" },
          { name: "Doresópolis" },
          { name: "Douradoquara" },
          { name: "Durandé" },
          { name: "Elói Mendes" },
          { name: "Engenheiro Caldas" },
          { name: "Engenheiro Navarro" },
          { name: "Entre Folhas" },
          { name: "Entre Rios de Minas" },
          { name: "Ervália" },
          { name: "Esmeraldas" },
          { name: "Espera Feliz" },
          { name: "Espinosa" },
          { name: "Espírito Santo do Dourado" },
          { name: "Estiva" },
          { name: "Estrela Dalva" },
          { name: "Estrela do Indaiá" },
          { name: "Estrela do Sul" },
          { name: "Eugenópolis" },
          { name: "Ewbank da Câmara" },
          { name: "Extrema" },
          { name: "Fama" },
          { name: "Faria Lemos" },
          { name: "Felício dos Santos" },
          { name: "Felisburgo" },
          { name: "Felixlândia" },
          { name: "Fernandes Tourinho" },
          { name: "Ferros" },
          { name: "Fervedouro" },
          { name: "Florestal" },
          { name: "Formiga" },
          { name: "Formoso" },
          { name: "Fortaleza de Minas" },
          { name: "Fortuna de Minas" },
          { name: "Francisco Badaró" },
          { name: "Francisco Dumont" },
          { name: "Francisco Sá" },
          { name: "Franciscópolis" },
          { name: "Frei Gaspar" },
          { name: "Frei Inocêncio" },
          { name: "Frei Lagonegro" },
          { name: "Fronteira" },
          { name: "Fronteira dos Vales" },
          { name: "Fruta de Leite" },
          { name: "Frutal" },
          { name: "Funilândia" },
          { name: "Galiléia" },
          { name: "Gameleiras" },
          { name: "Glaucilândia" },
          { name: "Goiabeira" },
          { name: "Goianá" },
          { name: "Gonçalves" },
          { name: "Gonzaga" },
          { name: "Gouveia" },
          { name: "Governador Valadares" },
          { name: "Grão Mogol" },
          { name: "Grupiara" },
          { name: "Guanhães" },
          { name: "Guapé" },
          { name: "Guaraciaba" },
          { name: "Guaraciama" },
          { name: "Guaranésia" },
          { name: "Guarani" },
          { name: "Guarará" },
          { name: "Guarda-Mor" },
          { name: "Guaxupé" },
          { name: "Guidoval" },
          { name: "Guimarânia" },
          { name: "Guiricema" },
          { name: "Gurinhatã" },
          { name: "Heliodora" },
          { name: "Iapu" },
          { name: "Ibertioga" },
          { name: "Ibiá" },
          { name: "Ibiaí" },
          { name: "Ibiracatu" },
          { name: "Ibiraci" },
          { name: "Ibirité" },
          { name: "Ibitiúra de Minas" },
          { name: "Ibituruna" },
          { name: "Icaraí de Minas" },
          { name: "Igarapé" },
          { name: "Igaratinga" },
          { name: "Iguatama" },
          { name: "Ijaci" },
          { name: "Ilicínea" },
          { name: "Imbé de Minas" },
          { name: "Inconfidentes" },
          { name: "Indaiabira" },
          { name: "Indianópolis" },
          { name: "Ingaí" },
          { name: "Inhapim" },
          { name: "Inhaúma" },
          { name: "Inimutaba" },
          { name: "Ipaba" },
          { name: "Ipanema" },
          { name: "Ipatinga" },
          { name: "Ipiaçu" },
          { name: "Ipuiúna" },
          { name: "Iraí de Minas" },
          { name: "Itabira" },
          { name: "Itabirinha" },
          { name: "Itabirinha de Mantena" },
          { name: "Itabirito" },
          { name: "Itacambira" },
          { name: "Itacarambi" },
          { name: "Itaguara" },
          { name: "Itaipé" },
          { name: "Itajubá" },
          { name: "Itamarandiba" },
          { name: "Itamarati de Minas" },
          { name: "Itambacuri" },
          { name: "Itambé do Mato Dentro" },
          { name: "Itamogi" },
          { name: "Itamonte" },
          { name: "Itanhandu" },
          { name: "Itanhomi" },
          { name: "Itaobim" },
          { name: "Itapagipe" },
          { name: "Itapecerica" },
          { name: "Itapeva" },
          { name: "Itatiaiuçu" },
          { name: "Itaú de Minas" },
          { name: "Itaúna" },
          { name: "Itaverava" },
          { name: "Itinga" },
          { name: "Itueta" },
          { name: "Ituiutaba" },
          { name: "Itumirim" },
          { name: "Iturama" },
          { name: "Itutinga" },
          { name: "Jaboticatubas" },
          { name: "Jacinto" },
          { name: "Jacuí" },
          { name: "Jacutinga" },
          { name: "Jaguaraçu" },
          { name: "Jaíba" },
          { name: "Jampruca" },
          { name: "Janaúba" },
          { name: "Januária" },
          { name: "Japaraíba" },
          { name: "Japonvar" },
          { name: "Jeceaba" },
          { name: "Jenipapo de Minas" },
          { name: "Jequeri" },
          { name: "Jequitaí" },
          { name: "Jequitibá" },
          { name: "Jequitinhonha" },
          { name: "Jesuânia" },
          { name: "Joaíma" },
          { name: "Joanésia" },
          { name: "João Monlevade" },
          { name: "João Pinheiro" },
          { name: "Joaquim Felício" },
          { name: "Jordânia" },
          { name: "José Gonçalves de Minas" },
          { name: "José Raydan" },
          { name: "Josenópolis" },
          { name: "Juatuba" },
          { name: "Juiz de Fora" },
          { name: "Juramento" },
          { name: "Juruaia" },
          { name: "Juvenília" },
          { name: "Ladainha" },
          { name: "Lagamar" },
          { name: "Lagoa da Prata" },
          { name: "Lagoa dos Patos" },
          { name: "Lagoa Dourada" },
          { name: "Lagoa Formosa" },
          { name: "Lagoa Grande" },
          { name: "Lagoa Santa" },
          { name: "Lajinha" },
          { name: "Lambari" },
          { name: "Lamim" },
          { name: "Laranjal" },
          { name: "Lassance" },
          { name: "Lavras" },
          { name: "Leandro Ferreira" },
          { name: "Leme do Prado" },
          { name: "Leopoldina" },
          { name: "Liberdade" },
          { name: "Lima Duarte" },
          { name: "Limeira do Oeste" },
          { name: "Lontra" },
          { name: "Luisburgo" },
          { name: "Luislândia" },
          { name: "Luminárias" },
          { name: "Luz" },
          { name: "Machacalis" },
          { name: "Machado" },
          { name: "Madre de Deus de Minas" },
          { name: "Malacacheta" },
          { name: "Mamonas" },
          { name: "Manga" },
          { name: "Manhuaçu" },
          { name: "Manhumirim" },
          { name: "Mantena" },
          { name: "Mar de Espanha" },
          { name: "Maravilhas" },
          { name: "Maria da Fé" },
          { name: "Mariana" },
          { name: "Marilac" },
          { name: "Mário Campos" },
          { name: "Maripá de Minas" },
          { name: "Marliéria" },
          { name: "Marmelópolis" },
          { name: "Martinho Campos" },
          { name: "Martins Soares" },
          { name: "Mata Verde" },
          { name: "Materlândia" },
          { name: "Mateus Leme" },
          { name: "Mathias Lobato" },
          { name: "Matias Barbosa" },
          { name: "Matias Cardoso" },
          { name: "Matipó" },
          { name: "Mato Verde" },
          { name: "Matozinhos" },
          { name: "Matutina" },
          { name: "Medeiros" },
          { name: "Medina" },
          { name: "Mendes Pimentel" },
          { name: "Mercês" },
          { name: "Mesquita" },
          { name: "Minas Novas" },
          { name: "Minduri" },
          { name: "Mirabela" },
          { name: "Miradouro" },
          { name: "Miraí" },
          { name: "Miravânia" },
          { name: "Moeda" },
          { name: "Moema" },
          { name: "Monjolos" },
          { name: "Monsenhor Paulo" },
          { name: "Montalvânia" },
          { name: "Monte Alegre de Minas" },
          { name: "Monte Azul" },
          { name: "Monte Belo" },
          { name: "Monte Carmelo" },
          { name: "Monte Formoso" },
          { name: "Monte Santo de Minas" },
          { name: "Monte Sião" },
          { name: "Montes Claros" },
          { name: "Montezuma" },
          { name: "Morada Nova de Minas" },
          { name: "Morro da Garça" },
          { name: "Morro do Pilar" },
          { name: "Munhoz" },
          { name: "Muriaé" },
          { name: "Mutum" },
          { name: "Muzambinho" },
          { name: "Nacip Raydan" },
          { name: "Nanuque" },
          { name: "Naque" },
          { name: "Natalândia" },
          { name: "Natércia" },
          { name: "Nazareno" },
          { name: "Nepomuceno" },
          { name: "Ninheira" },
          { name: "Nova Belém" },
          { name: "Nova Era" },
          { name: "Nova Lima" },
          { name: "Nova Módica" },
          { name: "Nova Ponte" },
          { name: "Nova Porteirinha" },
          { name: "Nova Resende" },
          { name: "Nova Serrana" },
          { name: "Nova União" },
          { name: "Novo Cruzeiro" },
          { name: "Novo Oriente de Minas" },
          { name: "Novorizonte" },
          { name: "Olaria" },
          { name: "Olhos-d'Água" },
          { name: "Olímpio Noronha" },
          { name: "Oliveira" },
          { name: "Oliveira Fortes" },
          { name: "Onça de Pitangui" },
          { name: "Oratórios" },
          { name: "Orizânia" },
          { name: "Ouro Branco" },
          { name: "Ouro Fino" },
          { name: "Ouro Preto" },
          { name: "Ouro Verde de Minas" },
          { name: "Padre Carvalho" },
          { name: "Padre Paraíso" },
          { name: "Pai Pedro" },
          { name: "Paineiras" },
          { name: "Pains" },
          { name: "Paiva" },
          { name: "Palma" },
          { name: "Palmópolis" },
          { name: "Papagaios" },
          { name: "Pará de Minas" },
          { name: "Paracatu" },
          { name: "Paraguaçu" },
          { name: "Paraisópolis" },
          { name: "Paraopeba" },
          { name: "Passa Quatro" },
          { name: "Passa Tempo" },
          { name: "Passa-Vinte" },
          { name: "Passabém" },
          { name: "Passos" },
          { name: "Patis" },
          { name: "Patos de Minas" },
          { name: "Patrocínio" },
          { name: "Patrocínio do Muriaé" },
          { name: "Paula Cândido" },
          { name: "Paulistas" },
          { name: "Pavão" },
          { name: "Peçanha" },
          { name: "Pedra Azul" },
          { name: "Pedra Bonita" },
          { name: "Pedra do Anta" },
          { name: "Pedra do Indaiá" },
          { name: "Pedra Dourada" },
          { name: "Pedralva" },
          { name: "Pedras de Maria da Cruz" },
          { name: "Pedrinópolis" },
          { name: "Pedro Leopoldo" },
          { name: "Pedro Teixeira" },
          { name: "Pequeri" },
          { name: "Pequi" },
          { name: "Perdigão" },
          { name: "Perdizes" },
          { name: "Perdões" },
          { name: "Periquito" },
          { name: "Pescador" },
          { name: "Piau" },
          { name: "Piedade de Caratinga" },
          { name: "Piedade de Ponte Nova" },
          { name: "Piedade do Rio Grande" },
          { name: "Piedade dos Gerais" },
          { name: "Pimenta" },
          { name: "Pingo-d'Água" },
          { name: "Pintópolis" },
          { name: "Piracema" },
          { name: "Pirajuba" },
          { name: "Piranga" },
          { name: "Piranguçu" },
          { name: "Piranguinho" },
          { name: "Pirapetinga" },
          { name: "Pirapora" },
          { name: "Piraúba" },
          { name: "Pitangui" },
          { name: "Piumhi" },
          { name: "Planura" },
          { name: "Poço Fundo" },
          { name: "Poços de Caldas" },
          { name: "Pocrane" },
          { name: "Pompéu" },
          { name: "Ponte Nova" },
          { name: "Ponto Chique" },
          { name: "Ponto dos Volantes" },
          { name: "Porteirinha" },
          { name: "Porto Firme" },
          { name: "Poté" },
          { name: "Pouso Alegre" },
          { name: "Pouso Alto" },
          { name: "Prados" },
          { name: "Prata" },
          { name: "Pratápolis" },
          { name: "Pratinha" },
          { name: "Presidente Bernardes" },
          { name: "Presidente Juscelino" },
          { name: "Presidente Kubitschek" },
          { name: "Presidente Olegário" },
          { name: "Prudente de Morais" },
          { name: "Quartel Geral" },
          { name: "Queluzito" },
          { name: "Raposos" },
          { name: "Raul Soares" },
          { name: "Recreio" },
          { name: "Reduto" },
          { name: "Resende Costa" },
          { name: "Resplendor" },
          { name: "Ressaquinha" },
          { name: "Riachinho" },
          { name: "Riacho dos Machados" },
          { name: "Ribeirão das Neves" },
          { name: "Ribeirão Vermelho" },
          { name: "Rio Acima" },
          { name: "Rio Casca" },
          { name: "Rio do Prado" },
          { name: "Rio Doce" },
          { name: "Rio Espera" },
          { name: "Rio Manso" },
          { name: "Rio Novo" },
          { name: "Rio Paranaíba" },
          { name: "Rio Pardo de Minas" },
          { name: "Rio Piracicaba" },
          { name: "Rio Pomba" },
          { name: "Rio Preto" },
          { name: "Rio Vermelho" },
          { name: "Ritápolis" },
          { name: "Rochedo de Minas" },
          { name: "Rodeiro" },
          { name: "Romaria" },
          { name: "Rosário da Limeira" },
          { name: "Rubelita" },
          { name: "Rubim" },
          { name: "Sabará" },
          { name: "Sabinópolis" },
          { name: "Sacramento" },
          { name: "Salinas" },
          { name: "Salto da Divisa" },
          { name: "Santa Bárbara" },
          { name: "Santa Bárbara do Leste" },
          { name: "Santa Bárbara do Monte Verde" },
          { name: "Santa Bárbara do Tugúrio" },
          { name: "Santa Cruz de Minas" },
          { name: "Santa Cruz de Salinas" },
          { name: "Santa Cruz do Escalvado" },
          { name: "Santa Efigênia de Minas" },
          { name: "Santa Fé de Minas" },
          { name: "Santa Helena de Minas" },
          { name: "Santa Juliana" },
          { name: "Santa Luzia" },
          { name: "Santa Margarida" },
          { name: "Santa Maria de Itabira" },
          { name: "Santa Maria do Salto" },
          { name: "Santa Maria do Suaçuí" },
          { name: "Santa Rita de Caldas" },
          { name: "Santa Rita de Ibitipoca" },
          { name: "Santa Rita de Jacutinga" },
          { name: "Santa Rita de Minas" },
          { name: "Santa Rita do Itueto" },
          { name: "Santa Rita do Sapucaí" },
          { name: "Santa Rosa da Serra" },
          { name: "Santa Vitória" },
          { name: "Santana da Vargem" },
          { name: "Santana de Cataguases" },
          { name: "Santana de Pirapama" },
          { name: "Santana do Deserto" },
          { name: "Santana do Garambéu" },
          { name: "Santana do Jacaré" },
          { name: "Santana do Manhuaçu" },
          { name: "Santana do Paraíso" },
          { name: "Santana do Riacho" },
          { name: "Santana dos Montes" },
          { name: "Santo Antônio do Amparo" },
          { name: "Santo Antônio do Aventureiro" },
          { name: "Santo Antônio do Grama" },
          { name: "Santo Antônio do Itambé" },
          { name: "Santo Antônio do Jacinto" },
          { name: "Santo Antônio do Monte" },
          { name: "Santo Antônio do Retiro" },
          { name: "Santo Antônio do Rio Abaixo" },
          { name: "Santo Hipólito" },
          { name: "Santos Dumont" },
          { name: "São Bento Abade" },
          { name: "São Brás do Suaçuí" },
          { name: "São Domingos das Dores" },
          { name: "São Domingos do Prata" },
          { name: "São Félix de Minas" },
          { name: "São Francisco" },
          { name: "São Francisco de Paula" },
          { name: "São Francisco de Sales" },
          { name: "São Francisco do Glória" },
          { name: "São Geraldo" },
          { name: "São Geraldo da Piedade" },
          { name: "São Geraldo do Baixio" },
          { name: "São Gonçalo do Abaeté" },
          { name: "São Gonçalo do Pará" },
          { name: "São Gonçalo do Rio Abaixo" },
          { name: "São Gonçalo do Rio Preto" },
          { name: "São Gonçalo do Sapucaí" },
          { name: "São Gotardo" },
          { name: "São João Batista do Glória" },
          { name: "São João da Lagoa" },
          { name: "São João da Mata" },
          { name: "São João da Ponte" },
          { name: "São João das Missões" },
          { name: "São João del Rei" },
          { name: "São João do Manhuaçu" },
          { name: "São João do Manteninha" },
          { name: "São João do Oriente" },
          { name: "São João do Pacuí" },
          { name: "São João do Paraíso" },
          { name: "São João Evangelista" },
          { name: "São João Nepomuceno" },
          { name: "São Joaquim de Bicas" },
          { name: "São José da Barra" },
          { name: "São José da Lapa" },
          { name: "São José da Safira" },
          { name: "São José da Varginha" },
          { name: "São José do Alegre" },
          { name: "São José do Divino" },
          { name: "São José do Goiabal" },
          { name: "São José do Jacuri" },
          { name: "São José do Mantimento" },
          { name: "São Lourenço" },
          { name: "São Miguel do Anta" },
          { name: "São Pedro da União" },
          { name: "São Pedro do Suaçuí" },
          { name: "São Pedro dos Ferros" },
          { name: "São Romão" },
          { name: "São Roque de Minas" },
          { name: "São Sebastião da Bela Vista" },
          { name: "São Sebastião da Vargem Alegre" },
          { name: "São Sebastião do Anta" },
          { name: "São Sebastião do Maranhão" },
          { name: "São Sebastião do Oeste" },
          { name: "São Sebastião do Paraíso" },
          { name: "São Sebastião do Rio Preto" },
          { name: "São Sebastião do Rio Verde" },
          { name: "São Thomé das Letras" },
          { name: "São Tiago" },
          { name: "São Tomás de Aquino" },
          { name: "São Vicente de Minas" },
          { name: "Sapucaí-Mirim" },
          { name: "Sardoá" },
          { name: "Sarzedo" },
          { name: "Sem-Peixe" },
          { name: "Senador Amaral" },
          { name: "Senador Cortes" },
          { name: "Senador Firmino" },
          { name: "Senador José Bento" },
          { name: "Senador Modestino Gonçalves" },
          { name: "Senhora de Oliveira" },
          { name: "Senhora do Porto" },
          { name: "Senhora dos Remédios" },
          { name: "Sericita" },
          { name: "Seritinga" },
          { name: "Serra Azul de Minas" },
          { name: "Serra da Saudade" },
          { name: "Serra do Salitre" },
          { name: "Serra dos Aimorés" },
          { name: "Serrania" },
          { name: "Serranópolis de Minas" },
          { name: "Serranos" },
          { name: "Serro" },
          { name: "Sete Lagoas" },
          { name: "Setubinha" },
          { name: "Silveirânia" },
          { name: "Silvianópolis" },
          { name: "Simão Pereira" },
          { name: "Simonésia" },
          { name: "Sobrália" },
          { name: "Soledade de Minas" },
          { name: "Tabuleiro" },
          { name: "Taiobeiras" },
          { name: "Taparuba" },
          { name: "Tapira" },
          { name: "Tapiraí" },
          { name: "Taquaraçu de Minas" },
          { name: "Tarumirim" },
          { name: "Teixeiras" },
          { name: "Teófilo Otoni" },
          { name: "Timóteo" },
          { name: "Tiradentes" },
          { name: "Tiros" },
          { name: "Tocantins" },
          { name: "Tocos do Moji" },
          { name: "Toledo" },
          { name: "Tombos" },
          { name: "Três Corações" },
          { name: "Três Marias" },
          { name: "Três Pontas" },
          { name: "Tumiritinga" },
          { name: "Tupaciguara" },
          { name: "Turmalina" },
          { name: "Turvolândia" },
          { name: "Ubá" },
          { name: "Ubaí" },
          { name: "Ubaporanga" },
          { name: "Uberaba" },
          { name: "Uberlândia" },
          { name: "Umburatiba" },
          { name: "Unaí" },
          { name: "União de Minas" },
          { name: "Uruana de Minas" },
          { name: "Urucânia" },
          { name: "Urucuia" },
          { name: "Vargem Alegre" },
          { name: "Vargem Bonita" },
          { name: "Vargem Grande do Rio Pardo" },
          { name: "Varginha" },
          { name: "Varjão de Minas" },
          { name: "Várzea da Palma" },
          { name: "Varzelândia" },
          { name: "Vazante" },
          { name: "Verdelândia" },
          { name: "Veredinha" },
          { name: "Veríssimo" },
          { name: "Vermelho Novo" },
          { name: "Vespasiano" },
          { name: "Viçosa" },
          { name: "Vieiras" },
          { name: "Virgem da Lapa" },
          { name: "Virgínia" },
          { name: "Virginópolis" },
          { name: "Virgolândia" },
          { name: "Visconde do Rio Branco" },
          { name: "Volta Grande" },
          { name: "Wenceslau Braz" }
        ]
      },
      {
        name:   "Rio de Janeiro",
        sigla:  "RJ",
        cities: [
          { name: "Angra dos Reis" },
          { name: "Aperibé" },
          { name: "Araruama" },
          { name: "Areal" },
          { name: "Armação de Búzios" },
          { name: "Arraial do Cabo" },
          { name: "Barra do Piraí" },
          { name: "Barra Mansa" },
          { name: "Belford Roxo" },
          { name: "Bom Jardim" },
          { name: "Bom Jesus do Itabapoana" },
          { name: "Cabo Frio" },
          { name: "Cachoeiras de Macacu" },
          { name: "Cambuci" },
          { name: "Campos dos Goytacazes" },
          { name: "Cantagalo" },
          { name: "Carapebus" },
          { name: "Cardoso Moreira" },
          { name: "Carmo" },
          { name: "Casimiro de Abreu" },
          { name: "Comendador Levy Gasparian" },
          { name: "Conceição de Macabu" },
          { name: "Cordeiro" },
          { name: "Duas Barras" },
          { name: "Duque de Caxias" },
          { name: "Engenheiro Paulo de Frontin" },
          { name: "Guapimirim" },
          { name: "Iguaba Grande" },
          { name: "Itaboraí" },
          { name: "Itaguaí" },
          { name: "Italva" },
          { name: "Itaocara" },
          { name: "Itaperuna" },
          { name: "Itatiaia" },
          { name: "Japeri" },
          { name: "Laje do Muriaé" },
          { name: "Macaé" },
          { name: "Macuco" },
          { name: "Magé" },
          { name: "Mangaratiba" },
          { name: "Maricá" },
          { name: "Mendes" },
          { name: "Mesquita" },
          { name: "Miguel Pereira" },
          { name: "Miracema" },
          { name: "Natividade" },
          { name: "Nilópolis" },
          { name: "Niterói" },
          { name: "Nova Friburgo" },
          { name: "Nova Iguaçu" },
          { name: "Paracambi" },
          { name: "Paraíba do Sul" },
          { name: "Parati" },
          { name: "Paty do Alferes" },
          { name: "Petrópolis" },
          { name: "Pinheiral" },
          { name: "Piraí" },
          { name: "Porciúncula" },
          { name: "Porto Real" },
          { name: "Quatis" },
          { name: "Queimados" },
          { name: "Quissamã" },
          { name: "Resende" },
          { name: "Rio Bonito" },
          { name: "Rio Claro" },
          { name: "Rio das Flores" },
          { name: "Rio das Ostras" },
          { name: "Rio de Janeiro" },
          { name: "Santa Maria Madalena" },
          { name: "Santo Antônio de Pádua" },
          { name: "São Fidélis" },
          { name: "São Francisco de Itabapoana" },
          { name: "São Gonçalo" },
          { name: "São João da Barra" },
          { name: "São João de Meriti" },
          { name: "São José de Ubá" },
          { name: "São José do Vale do Rio Preto" },
          { name: "São Pedro da Aldeia" },
          { name: "São Sebastião do Alto" },
          { name: "Sapucaia" },
          { name: "Saquarema" },
          { name: "Seropédica" },
          { name: "Silva Jardim" },
          { name: "Sumidouro" },
          { name: "Tanguá" },
          { name: "Teresópolis" },
          { name: "Trajano de Morais" },
          { name: "Três Rios" },
          { name: "Valença" },
          { name: "Varre-Sai" },
          { name: "Vassouras" },
          { name: "Volta Redonda" }
        ]
      },
      {
        name:   "São Paulo",
        sigla:  "SP",
        cities: [
          { name: "Adamantina" },
          { name: "Adolfo" },
          { name: "Aguaí" },
          { name: "Águas da Prata" },
          { name: "Águas de Lindóia" },
          { name: "Águas de Santa Bárbara" },
          { name: "Águas de São Pedro" },
          { name: "Agudos" },
          { name: "Alambari" },
          { name: "Alfredo Marcondes" },
          { name: "Altair" },
          { name: "Altinópolis" },
          { name: "Alto Alegre" },
          { name: "Alumínio" },
          { name: "Álvares Florence" },
          { name: "Álvares Machado" },
          { name: "Álvaro de Carvalho" },
          { name: "Alvinlândia" },
          { name: "Americana" },
          { name: "Américo Brasiliense" },
          { name: "Américo de Campos" },
          { name: "Amparo" },
          { name: "Analândia" },
          { name: "Andradina" },
          { name: "Angatuba" },
          { name: "Anhembi" },
          { name: "Anhumas" },
          { name: "Aparecida" },
          { name: "Aparecida d'Oeste" },
          { name: "Apiaí" },
          { name: "Araçariguama" },
          { name: "Araçatuba" },
          { name: "Araçoiaba da Serra" },
          { name: "Aramina" },
          { name: "Arandu" },
          { name: "Arapeí" },
          { name: "Araraquara" },
          { name: "Araras" },
          { name: "Arco-Íris" },
          { name: "Arealva" },
          { name: "Areias" },
          { name: "Areiópolis" },
          { name: "Ariranha" },
          { name: "Artur Nogueira" },
          { name: "Arujá" },
          { name: "Aspásia" },
          { name: "Assis" },
          { name: "Atibaia" },
          { name: "Auriflama" },
          { name: "Avaí" },
          { name: "Avanhandava" },
          { name: "Avaré" },
          { name: "Bady Bassitt" },
          { name: "Balbinos" },
          { name: "Bálsamo" },
          { name: "Bananal" },
          { name: "Barão de Antonina" },
          { name: "Barbosa" },
          { name: "Bariri" },
          { name: "Barra Bonita" },
          { name: "Barra do Chapéu" },
          { name: "Barra do Turvo" },
          { name: "Barretos" },
          { name: "Barrinha" },
          { name: "Barueri" },
          { name: "Bastos" },
          { name: "Batatais" },
          { name: "Bauru" },
          { name: "Bebedouro" },
          { name: "Bento de Abreu" },
          { name: "Bernardino de Campos" },
          { name: "Bertioga" },
          { name: "Bilac" },
          { name: "Birigui" },
          { name: "Biritiba-Mirim" },
          { name: "Boa Esperança do Sul" },
          { name: "Bocaina" },
          { name: "Bofete" },
          { name: "Boituva" },
          { name: "Bom Jesus dos Perdões" },
          { name: "Bom Sucesso de Itararé" },
          { name: "Borá" },
          { name: "Boracéia" },
          { name: "Borborema" },
          { name: "Borebi" },
          { name: "Botucatu" },
          { name: "Bragança Paulista" },
          { name: "Braúna" },
          { name: "Brejo Alegre" },
          { name: "Brodowski" },
          { name: "Brotas" },
          { name: "Buri" },
          { name: "Buritama" },
          { name: "Buritizal" },
          { name: "Cabrália Paulista" },
          { name: "Cabreúva" },
          { name: "Caçapava" },
          { name: "Cachoeira Paulista" },
          { name: "Caconde" },
          { name: "Cafelândia" },
          { name: "Caiabu" },
          { name: "Caieiras" },
          { name: "Caiuá" },
          { name: "Cajamar" },
          { name: "Cajati" },
          { name: "Cajobi" },
          { name: "Cajuru" },
          { name: "Campina do Monte Alegre" },
          { name: "Campinas" },
          { name: "Campo Limpo Paulista" },
          { name: "Campos do Jordão" },
          { name: "Campos Novos Paulista" },
          { name: "Cananéia" },
          { name: "Canas" },
          { name: "Cândido Mota" },
          { name: "Cândido Rodrigues" },
          { name: "Canitar" },
          { name: "Capão Bonito" },
          { name: "Capela do Alto" },
          { name: "Capivari" },
          { name: "Caraguatatuba" },
          { name: "Carapicuíba" },
          { name: "Cardoso" },
          { name: "Casa Branca" },
          { name: "Cássia dos Coqueiros" },
          { name: "Castilho" },
          { name: "Catanduva" },
          { name: "Catiguá" },
          { name: "Cedral" },
          { name: "Cerqueira César" },
          { name: "Cerquilho" },
          { name: "Cesário Lange" },
          { name: "Charqueada" },
          { name: "Chavantes" },
          { name: "Clementina" },
          { name: "Colina" },
          { name: "Colômbia" },
          { name: "Conchal" },
          { name: "Conchas" },
          { name: "Cordeirópolis" },
          { name: "Coroados" },
          { name: "Coronel Macedo" },
          { name: "Corumbataí" },
          { name: "Cosmópolis" },
          { name: "Cosmorama" },
          { name: "Cotia" },
          { name: "Cravinhos" },
          { name: "Cristais Paulista" },
          { name: "Cruzália" },
          { name: "Cruzeiro" },
          { name: "Cubatão" },
          { name: "Cunha" },
          { name: "Descalvado" },
          { name: "Diadema" },
          { name: "Dirce Reis" },
          { name: "Divinolândia" },
          { name: "Dobrada" },
          { name: "Dois Córregos" },
          { name: "Dolcinópolis" },
          { name: "Dourado" },
          { name: "Dracena" },
          { name: "Duartina" },
          { name: "Dumont" },
          { name: "Echaporã" },
          { name: "Eldorado" },
          { name: "Elias Fausto" },
          { name: "Elisiário" },
          { name: "Embaúba" },
          { name: "Embu" },
          { name: "Embu-Guaçu" },
          { name: "Emilianópolis" },
          { name: "Engenheiro Coelho" },
          { name: "Espírito Santo do Pinhal" },
          { name: "Espírito Santo do Turvo" },
          { name: "Estiva Gerbi" },
          { name: "Estrela d'Oeste" },
          { name: "Estrela do Norte" },
          { name: "Euclides da Cunha Paulista" },
          { name: "Fartura" },
          { name: "Fernando Prestes" },
          { name: "Fernandópolis" },
          { name: "Fernão" },
          { name: "Ferraz de Vasconcelos" },
          { name: "Flora Rica" },
          { name: "Floreal" },
          { name: "Florínia" },
          { name: "Flórida Paulista" },
          { name: "Franca" },
          { name: "Francisco Morato" },
          { name: "Franco da Rocha" },
          { name: "Gabriel Monteiro" },
          { name: "Gália" },
          { name: "Garça" },
          { name: "Gastão Vidigal" },
          { name: "Gavião Peixoto" },
          { name: "General Salgado" },
          { name: "Getulina" },
          { name: "Glicério" },
          { name: "Guaiçara" },
          { name: "Guaimbê" },
          { name: "Guaíra" },
          { name: "Guapiaçu" },
          { name: "Guapiara" },
          { name: "Guará" },
          { name: "Guaraçaí" },
          { name: "Guaraci" },
          { name: "Guarani d'Oeste" },
          { name: "Guarantã" },
          { name: "Guararapes" },
          { name: "Guararema" },
          { name: "Guaratinguetá" },
          { name: "Guareí" },
          { name: "Guariba" },
          { name: "Guarujá" },
          { name: "Guarulhos" },
          { name: "Guatapará" },
          { name: "Guzolândia" },
          { name: "Herculândia" },
          { name: "Holambra" },
          { name: "Hortolândia" },
          { name: "Iacanga" },
          { name: "Iacri" },
          { name: "Iaras" },
          { name: "Ibaté" },
          { name: "Ibirá" },
          { name: "Ibirarema" },
          { name: "Ibitinga" },
          { name: "Ibiúna" },
          { name: "Icém" },
          { name: "Iepê" },
          { name: "Igaraçu do Tietê" },
          { name: "Igarapava" },
          { name: "Igaratá" },
          { name: "Iguape" },
          { name: "Ilha Comprida" },
          { name: "Ilha Solteira" },
          { name: "Ilhabela" },
          { name: "Indaiatuba" },
          { name: "Indiana" },
          { name: "Indiaporã" },
          { name: "Inúbia Paulista" },
          { name: "Ipaussu" },
          { name: "Iperó" },
          { name: "Ipeúna" },
          { name: "Ipiguá" },
          { name: "Iporanga" },
          { name: "Ipuã" },
          { name: "Iracemápolis" },
          { name: "Irapuã" },
          { name: "Irapuru" },
          { name: "Itaberá" },
          { name: "Itaí" },
          { name: "Itajobi" },
          { name: "Itaju" },
          { name: "Itanhaém" },
          { name: "Itaóca" },
          { name: "Itapecerica da Serra" },
          { name: "Itapetininga" },
          { name: "Itapeva" },
          { name: "Itapevi" },
          { name: "Itapira" },
          { name: "Itapirapuã Paulista" },
          { name: "Itápolis" },
          { name: "Itaporanga" },
          { name: "Itapuí" },
          { name: "Itapura" },
          { name: "Itaquaquecetuba" },
          { name: "Itararé" },
          { name: "Itariri" },
          { name: "Itatiba" },
          { name: "Itatinga" },
          { name: "Itirapina" },
          { name: "Itirapuã" },
          { name: "Itobi" },
          { name: "Itu" },
          { name: "Itupeva" },
          { name: "Ituverava" },
          { name: "Jaborandi" },
          { name: "Jaboticabal" },
          { name: "Jacareí" },
          { name: "Jaci" },
          { name: "Jacupiranga" },
          { name: "Jaguariúna" },
          { name: "Jales" },
          { name: "Jambeiro" },
          { name: "Jandira" },
          { name: "Jardinópolis" },
          { name: "Jarinu" },
          { name: "Jaú" },
          { name: "Jeriquara" },
          { name: "Joanópolis" },
          { name: "João Ramalho" },
          { name: "José Bonifácio" },
          { name: "Júlio Mesquita" },
          { name: "Jumirim" },
          { name: "Jundiaí" },
          { name: "Junqueirópolis" },
          { name: "Juquiá" },
          { name: "Juquitiba" },
          { name: "Lagoinha" },
          { name: "Laranjal Paulista" },
          { name: "Lavínia" },
          { name: "Lavrinhas" },
          { name: "Leme" },
          { name: "Lençóis Paulista" },
          { name: "Limeira" },
          { name: "Lindóia" },
          { name: "Lins" },
          { name: "Lorena" },
          { name: "Lourdes" },
          { name: "Louveira" },
          { name: "Lucélia" },
          { name: "Lucianópolis" },
          { name: "Luís Antônio" },
          { name: "Luiziânia" },
          { name: "Lupércio" },
          { name: "Lutécia" },
          { name: "Macatuba" },
          { name: "Macaubal" },
          { name: "Macedônia" },
          { name: "Magda" },
          { name: "Mairinque" },
          { name: "Mairiporã" },
          { name: "Manduri" },
          { name: "Marabá Paulista" },
          { name: "Maracaí" },
          { name: "Marapoama" },
          { name: "Mariápolis" },
          { name: "Marília" },
          { name: "Marinópolis" },
          { name: "Martinópolis" },
          { name: "Matão" },
          { name: "Mauá" },
          { name: "Mendonça" },
          { name: "Meridiano" },
          { name: "Mesópolis" },
          { name: "Miguelópolis" },
          { name: "Mineiros do Tietê" },
          { name: "Mira Estrela" },
          { name: "Miracatu" },
          { name: "Mirandópolis" },
          { name: "Mirante do Paranapanema" },
          { name: "Mirassol" },
          { name: "Mirassolândia" },
          { name: "Mococa" },
          { name: "Mogi das Cruzes" },
          { name: "Mogi Guaçu" },
          { name: "Mogi Mirim" },
          { name: "Mombuca" },
          { name: "Monções" },
          { name: "Mongaguá" },
          { name: "Monte Alegre do Sul" },
          { name: "Monte Alto" },
          { name: "Monte Aprazível" },
          { name: "Monte Azul Paulista" },
          { name: "Monte Castelo" },
          { name: "Monte Mor" },
          { name: "Monteiro Lobato" },
          { name: "Morro Agudo" },
          { name: "Morungaba" },
          { name: "Motuca" },
          { name: "Murutinga do Sul" },
          { name: "Nantes" },
          { name: "Narandiba" },
          { name: "Natividade da Serra" },
          { name: "Nazaré Paulista" },
          { name: "Neves Paulista" },
          { name: "Nhandeara" },
          { name: "Nipoã" },
          { name: "Nova Aliança" },
          { name: "Nova Campina" },
          { name: "Nova Canaã Paulista" },
          { name: "Nova Castilho" },
          { name: "Nova Europa" },
          { name: "Nova Granada" },
          { name: "Nova Guataporanga" },
          { name: "Nova Independência" },
          { name: "Nova Luzitânia" },
          { name: "Nova Odessa" },
          { name: "Novais" },
          { name: "Novo Horizonte" },
          { name: "Nuporanga" },
          { name: "Ocauçu" },
          { name: "Óleo" },
          { name: "Olímpia" },
          { name: "Onda Verde" },
          { name: "Oriente" },
          { name: "Orindiúva" },
          { name: "Orlândia" },
          { name: "Osasco" },
          { name: "Oscar Bressane" },
          { name: "Osvaldo Cruz" },
          { name: "Ourinhos" },
          { name: "Ouro Verde" },
          { name: "Ouroeste" },
          { name: "Pacaembu" },
          { name: "Palestina" },
          { name: "Palmares Paulista" },
          { name: "Palmeira d'Oeste" },
          { name: "Palmital" },
          { name: "Panorama" },
          { name: "Paraguaçu Paulista" },
          { name: "Paraibuna" },
          { name: "Paraíso" },
          { name: "Paranapanema" },
          { name: "Paranapuã" },
          { name: "Parapuã" },
          { name: "Pardinho" },
          { name: "Pariquera-Açu" },
          { name: "Parisi" },
          { name: "Patrocínio Paulista" },
          { name: "Paulicéia" },
          { name: "Paulínia" },
          { name: "Paulistânia" },
          { name: "Paulo de Faria" },
          { name: "Pederneiras" },
          { name: "Pedra Bela" },
          { name: "Pedranópolis" },
          { name: "Pedregulho" },
          { name: "Pedreira" },
          { name: "Pedrinhas Paulista" },
          { name: "Pedro de Toledo" },
          { name: "Penápolis" },
          { name: "Pereira Barreto" },
          { name: "Pereiras" },
          { name: "Peruíbe" },
          { name: "Piacatu" },
          { name: "Piedade" },
          { name: "Pilar do Sul" },
          { name: "Pindamonhangaba" },
          { name: "Pindorama" },
          { name: "Pinhalzinho" },
          { name: "Piquerobi" },
          { name: "Piquete" },
          { name: "Piracaia" },
          { name: "Piracicaba" },
          { name: "Piraju" },
          { name: "Pirajuí" },
          { name: "Pirangi" },
          { name: "Pirapora do Bom Jesus" },
          { name: "Pirapozinho" },
          { name: "Pirassununga" },
          { name: "Piratininga" },
          { name: "Pitangueiras" },
          { name: "Planalto" },
          { name: "Platina" },
          { name: "Poá" },
          { name: "Poloni" },
          { name: "Pompéia" },
          { name: "Pongaí" },
          { name: "Pontal" },
          { name: "Pontalinda" },
          { name: "Pontes Gestal" },
          { name: "Populina" },
          { name: "Porangaba" },
          { name: "Porto Feliz" },
          { name: "Porto Ferreira" },
          { name: "Potim" },
          { name: "Potirendaba" },
          { name: "Pracinha" },
          { name: "Pradópolis" },
          { name: "Praia Grande" },
          { name: "Pratânia" },
          { name: "Presidente Alves" },
          { name: "Presidente Bernardes" },
          { name: "Presidente Epitácio" },
          { name: "Presidente Prudente" },
          { name: "Presidente Venceslau" },
          { name: "Promissão" },
          { name: "Quadra" },
          { name: "Quatá" },
          { name: "Queiroz" },
          { name: "Queluz" },
          { name: "Quintana" },
          { name: "Rafard" },
          { name: "Rancharia" },
          { name: "Redenção da Serra" },
          { name: "Regente Feijó" },
          { name: "Reginópolis" },
          { name: "Registro" },
          { name: "Restinga" },
          { name: "Ribeira" },
          { name: "Ribeirão Bonito" },
          { name: "Ribeirão Branco" },
          { name: "Ribeirão Corrente" },
          { name: "Ribeirão do Sul" },
          { name: "Ribeirão dos Índios" },
          { name: "Ribeirão Grande" },
          { name: "Ribeirão Pires" },
          { name: "Ribeirão Preto" },
          { name: "Rifaina" },
          { name: "Rincão" },
          { name: "Rinópolis" },
          { name: "Rio Claro" },
          { name: "Rio das Pedras" },
          { name: "Rio Grande da Serra" },
          { name: "Riolândia" },
          { name: "Riversul" },
          { name: "Rosana" },
          { name: "Roseira" },
          { name: "Rubiácea" },
          { name: "Rubinéia" },
          { name: "Sabino" },
          { name: "Sagres" },
          { name: "Sales" },
          { name: "Sales Oliveira" },
          { name: "Salesópolis" },
          { name: "Salmourão" },
          { name: "Saltinho" },
          { name: "Salto" },
          { name: "Salto de Pirapora" },
          { name: "Salto Grande" },
          { name: "Sandovalina" },
          { name: "Santa Adélia" },
          { name: "Santa Albertina" },
          { name: "Santa Bárbara d'Oeste" },
          { name: "Santa Branca" },
          { name: "Santa Clara d'Oeste" },
          { name: "Santa Cruz da Conceição" },
          { name: "Santa Cruz da Esperança" },
          { name: "Santa Cruz das Palmeiras" },
          { name: "Santa Cruz do Rio Pardo" },
          { name: "Santa Ernestina" },
          { name: "Santa Fé do Sul" },
          { name: "Santa Gertrudes" },
          { name: "Santa Isabel" },
          { name: "Santa Lúcia" },
          { name: "Santa Maria da Serra" },
          { name: "Santa Mercedes" },
          { name: "Santa Rita d'Oeste" },
          { name: "Santa Rita do Passa Quatro" },
          { name: "Santa Rosa de Viterbo" },
          { name: "Santa Salete" },
          { name: "Santana da Ponte Pensa" },
          { name: "Santana de Parnaíba" },
          { name: "Santo Anastácio" },
          { name: "Santo André" },
          { name: "Santo Antônio da Alegria" },
          { name: "Santo Antônio de Posse" },
          { name: "Santo Antônio do Aracanguá" },
          { name: "Santo Antônio do Jardim" },
          { name: "Santo Antônio do Pinhal" },
          { name: "Santo Expedito" },
          { name: "Santópolis do Aguapeí" },
          { name: "Santos" },
          { name: "São Bento do Sapucaí" },
          { name: "São Bernardo do Campo" },
          { name: "São Caetano do Sul" },
          { name: "São Carlos" },
          { name: "São Francisco" },
          { name: "São João da Boa Vista" },
          { name: "São João das Duas Pontes" },
          { name: "São João de Iracema" },
          { name: "São João do Pau d'Alho" },
          { name: "São Joaquim da Barra" },
          { name: "São José da Bela Vista" },
          { name: "São José do Barreiro" },
          { name: "São José do Rio Pardo" },
          { name: "São José do Rio Preto" },
          { name: "São José dos Campos" },
          { name: "São Lourenço da Serra" },
          { name: "São Luís do Paraitinga" },
          { name: "São Manuel" },
          { name: "São Miguel Arcanjo" },
          { name: "São Paulo" },
          { name: "São Pedro" },
          { name: "São Pedro do Turvo" },
          { name: "São Roque" },
          { name: "São Sebastião" },
          { name: "São Sebastião da Grama" },
          { name: "São Simão" },
          { name: "São Vicente" },
          { name: "Sarapuí" },
          { name: "Sarutaiá" },
          { name: "Sebastianópolis do Sul" },
          { name: "Serra Azul" },
          { name: "Serra Negra" },
          { name: "Serrana" },
          { name: "Sertãozinho" },
          { name: "Sete Barras" },
          { name: "Severínia" },
          { name: "Silveiras" },
          { name: "Socorro" },
          { name: "Sorocaba" },
          { name: "Sud Mennucci" },
          { name: "Sumaré" },
          { name: "Suzanápolis" },
          { name: "Suzano" },
          { name: "Tabapuã" },
          { name: "Tabatinga" },
          { name: "Taboão da Serra" },
          { name: "Taciba" },
          { name: "Taguaí" },
          { name: "Taiaçu" },
          { name: "Taiúva" },
          { name: "Tambaú" },
          { name: "Tanabi" },
          { name: "Tapiraí" },
          { name: "Tapiratiba" },
          { name: "Taquaral" },
          { name: "Taquaritinga" },
          { name: "Taquarituba" },
          { name: "Taquarivaí" },
          { name: "Tarabai" },
          { name: "Tarumã" },
          { name: "Tatuí" },
          { name: "Taubaté" },
          { name: "Tejupá" },
          { name: "Teodoro Sampaio" },
          { name: "Terra Roxa" },
          { name: "Tietê" },
          { name: "Timburi" },
          { name: "Torre de Pedra" },
          { name: "Torrinha" },
          { name: "Trabiju" },
          { name: "Tremembé" },
          { name: "Três Fronteiras" },
          { name: "Tuiuti" },
          { name: "Tupã" },
          { name: "Tupi Paulista" },
          { name: "Turiúba" },
          { name: "Turmalina" },
          { name: "Ubarana" },
          { name: "Ubatuba" },
          { name: "Ubirajara" },
          { name: "Uchoa" },
          { name: "União Paulista" },
          { name: "Urânia" },
          { name: "Uru" },
          { name: "Urupês" },
          { name: "Valentim Gentil" },
          { name: "Valinhos" },
          { name: "Valparaíso" },
          { name: "Vargem" },
          { name: "Vargem Grande do Sul" },
          { name: "Vargem Grande Paulista" },
          { name: "Várzea Paulista" },
          { name: "Vera Cruz" },
          { name: "Vinhedo" },
          { name: "Viradouro" },
          { name: "Vista Alegre do Alto" },
          { name: "Vitória Brasil" },
          { name: "Votorantim" },
          { name: "Votuporanga" },
          { name: "Zacarias" }
        ]
      }
    ]
  },
  {
    name:   "Sul",
    states: [
      {
        name:   "Paraná",
        sigla:  "PR",
        cities: [
          { name: "Agudos do Sul" },
          { name: "Adrianópolis" },
          { name: "Almirante Tamandaré" },
          { name: "Abatiá" },
          { name: "Altamira do Paraná" },
          { name: "Alto Paraná" },
          { name: "Alto Piquiri" },
          { name: "Altônia" },
          { name: "Alvorada do Sul" },
          { name: "Amaporã" },
          { name: "Ampére" },
          { name: "Anahy" },
          { name: "Andirá" },
          { name: "Ângulo" },
          { name: "Antonina" },
          { name: "Antônio Olinto" },
          { name: "Apucarana" },
          { name: "Arapongas" },
          { name: "Arapoti" },
          { name: "Arapuã" },
          { name: "Araruna" },
          { name: "Araucária" },
          { name: "Ariranha do Ivaí" },
          { name: "Assaí" },
          { name: "Assis Chateaubriand" },
          { name: "Astorga" },
          { name: "Atalaia" },
          { name: "Balsa Nova" },
          { name: "Bandeirantes" },
          { name: "Barbosa Ferraz" },
          { name: "Barra do Jacaré" },
          { name: "Barracão" },
          { name: "Bela Vista da Caroba" },
          { name: "Bela Vista do Paraíso" },
          { name: "Bituruna" },
          { name: "Boa Esperança" },
          { name: "Boa Esperança do Iguaçu" },
          { name: "Boa Ventura de São Roque" },
          { name: "Boa Vista da Aparecida" },
          { name: "Bocaiúva do Sul" },
          { name: "Bom Jesus do Sul" },
          { name: "Bom Sucesso" },
          { name: "Bom Sucesso do Sul" },
          { name: "Borrazópolis" },
          { name: "Braganey" },
          { name: "Brasilândia do Sul" },
          { name: "Cafeara" },
          { name: "Cafelândia" },
          { name: "Cafezal do Sul" },
          { name: "Califórnia" },
          { name: "Cambará" },
          { name: "Cambé" },
          { name: "Cambira" },
          { name: "Campina da Lagoa" },
          { name: "Campina do Simão" },
          { name: "Campina Grande do Sul" },
          { name: "Campo Bonito" },
          { name: "Campo do Tenente" },
          { name: "Campo Largo" },
          { name: "Campo Magro" },
          { name: "Campo Mourão" },
          { name: "Cândido de Abreu" },
          { name: "Candói" },
          { name: "Cantagalo" },
          { name: "Capanema" },
          { name: "Capitão Leônidas Marques" },
          { name: "Carambeí" },
          { name: "Carlópolis" },
          { name: "Cascavel" },
          { name: "Castro" },
          { name: "Catanduvas" },
          { name: "Centenário do Sul" },
          { name: "Cerro Azul" },
          { name: "Céu Azul" },
          { name: "Chopinzinho" },
          { name: "Cianorte" },
          { name: "Cidade Gaúcha" },
          { name: "Clevelândia" },
          { name: "Colombo" },
          { name: "Colorado" },
          { name: "Congonhinhas" },
          { name: "Conselheiro Mairinck" },
          { name: "Contenda" },
          { name: "Corbélia" },
          { name: "Cornélio Procópio" },
          { name: "Coronel Domingos Soares" },
          { name: "Coronel Vivida" },
          { name: "Corumbataí do Sul" },
          { name: "Cruz Machado" },
          { name: "Cruzeiro do Iguaçu" },
          { name: "Cruzeiro do Oeste" },
          { name: "Cruzeiro do Sul" },
          { name: "Cruzmaltina" },
          { name: "Curitiba" },
          { name: "Curiúva" },
          { name: "Diamante d'Oeste" },
          { name: "Diamante do Norte" },
          { name: "Diamante do Sul" },
          { name: "Dois Vizinhos" },
          { name: "Douradina" },
          { name: "Doutor Camargo" },
          { name: "Doutor Ulysses" },
          { name: "Enéas Marques" },
          { name: "Engenheiro Beltrão" },
          { name: "Entre Rios do Oeste" },
          { name: "Esperança Nova" },
          { name: "Espigão Alto do Iguaçu" },
          { name: "Farol" },
          { name: "Faxinal" },
          { name: "Fazenda Rio Grande" },
          { name: "Fênix" },
          { name: "Fernandes Pinheiro" },
          { name: "Figueira" },
          { name: "Flor da Serra do Sul" },
          { name: "Floraí" },
          { name: "Floresta" },
          { name: "Florestópolis" },
          { name: "Flórida" },
          { name: "Formosa do Oeste" },
          { name: "Foz do Iguaçu" },
          { name: "Foz do Jordão" },
          { name: "Francisco Alves" },
          { name: "Francisco Beltrão" },
          { name: "General Carneiro" },
          { name: "Godoy Moreira" },
          { name: "Goioerê" },
          { name: "Goioxim" },
          { name: "Grandes Rios" },
          { name: "Guaíra" },
          { name: "Guairaçá" },
          { name: "Guamiranga" },
          { name: "Guapirama" },
          { name: "Guaporema" },
          { name: "Guaraci" },
          { name: "Guaraniaçu" },
          { name: "Guarapuava" },
          { name: "Guaraqueçaba" },
          { name: "Guaratuba" },
          { name: "Honório Serpa" },
          { name: "Ibaiti" },
          { name: "Ibema" },
          { name: "Ibiporã" },
          { name: "Icaraíma" },
          { name: "Iguaraçu" },
          { name: "Iguatu" },
          { name: "Imbaú" },
          { name: "Imbituva" },
          { name: "Inácio Martins" },
          { name: "Inajá" },
          { name: "Indianópolis" },
          { name: "Ipiranga" },
          { name: "Iporã" },
          { name: "Iracema do Oeste" },
          { name: "Irati" },
          { name: "Iretama" },
          { name: "Itaguajé" },
          { name: "Itaipulândia" },
          { name: "Itambaracá" },
          { name: "Itambé" },
          { name: "Itapejara d'Oeste" },
          { name: "Itaperuçu" },
          { name: "Itaúna do Sul" },
          { name: "Ivaí" },
          { name: "Ivaiporã" },
          { name: "Ivaté" },
          { name: "Ivatuba" },
          { name: "Jaboti" },
          { name: "Jacarezinho" },
          { name: "Jaguapitã" },
          { name: "Jaguariaíva" },
          { name: "Jandaia do Sul" },
          { name: "Janiópolis" },
          { name: "Japira" },
          { name: "Japurá" },
          { name: "Jardim Alegre" },
          { name: "Jardim Olinda" },
          { name: "Jataizinho" },
          { name: "Jesuítas" },
          { name: "Joaquim Távora" },
          { name: "Jundiaí do Sul" },
          { name: "Juranda" },
          { name: "Jussara" },
          { name: "Kaloré" },
          { name: "Lapa" },
          { name: "Laranjal" },
          { name: "Laranjeiras do Sul" },
          { name: "Leópolis" },
          { name: "Lidianópolis" },
          { name: "Lindoeste" },
          { name: "Loanda" },
          { name: "Lobato" },
          { name: "Londrina" },
          { name: "Luiziana" },
          { name: "Lunardelli" },
          { name: "Lupionópolis" },
          { name: "Mallet" },
          { name: "Mamborê" },
          { name: "Mandaguaçu" },
          { name: "Mandaguari" },
          { name: "Mandirituba" },
          { name: "Manfrinópolis" },
          { name: "Mangueirinha" },
          { name: "Manoel Ribas" },
          { name: "Marechal Cândido Rondon" },
          { name: "Maria Helena" },
          { name: "Marialva" },
          { name: "Marilândia do Sul" },
          { name: "Marilena" },
          { name: "Mariluz" },
          { name: "Maringá" },
          { name: "Mariópolis" },
          { name: "Maripá" },
          { name: "Marmeleiro" },
          { name: "Marquinho" },
          { name: "Marumbi" },
          { name: "Matelândia" },
          { name: "Matinhos" },
          { name: "Mato Rico" },
          { name: "Mauá da Serra" },
          { name: "Medianeira" },
          { name: "Mercedes" },
          { name: "Mirador" },
          { name: "Miraselva" },
          { name: "Missal" },
          { name: "Moreira Sales" },
          { name: "Morretes" },
          { name: "Munhoz de Melo" },
          { name: "Nossa Senhora das Graças" },
          { name: "Nova Aliança do Ivaí" },
          { name: "Nova América da Colina" },
          { name: "Nova Aurora" },
          { name: "Nova Cantu" },
          { name: "Nova Esperança" },
          { name: "Nova Esperança do Sudoeste" },
          { name: "Nova Fátima" },
          { name: "Nova Laranjeiras" },
          { name: "Nova Londrina" },
          { name: "Nova Olímpia" },
          { name: "Nova Prata do Iguaçu" },
          { name: "Nova Santa Bárbara" },
          { name: "Nova Santa Rosa" },
          { name: "Nova Tebas" },
          { name: "Novo Itacolomi" },
          { name: "Ortigueira" },
          { name: "Ourizona" },
          { name: "Ouro Verde do Oeste" },
          { name: "Paiçandu" },
          { name: "Palmas" },
          { name: "Palmeira" },
          { name: "Palmital" },
          { name: "Palotina" },
          { name: "Paraíso do Norte" },
          { name: "Paranacity" },
          { name: "Paranaguá" },
          { name: "Paranapoema" },
          { name: "Paranavaí" },
          { name: "Pato Bragado" },
          { name: "Pato Branco" },
          { name: "Paula Freitas" },
          { name: "Paulo Frontin" },
          { name: "Peabiru" },
          { name: "Perobal" },
          { name: "Pérola" },
          { name: "Pérola d'Oeste" },
          { name: "Piên" },
          { name: "Pinhais" },
          { name: "Pinhal de São Bento" },
          { name: "Pinhalão" },
          { name: "Pinhão" },
          { name: "Piraí do Sul" },
          { name: "Piraquara" },
          { name: "Pitanga" },
          { name: "Pitangueiras" },
          { name: "Planaltina do Paraná" },
          { name: "Planalto" },
          { name: "Ponta Grossa" },
          { name: "Pontal do Paraná" },
          { name: "Porecatu" },
          { name: "Porto Amazonas" },
          { name: "Porto Barreiro" },
          { name: "Porto Rico" },
          { name: "Porto Vitória" },
          { name: "Prado Ferreira" },
          { name: "Pranchita" },
          { name: "Presidente Castelo Branco" },
          { name: "Primeiro de Maio" },
          { name: "Prudentópolis" },
          { name: "Quarto Centenário" },
          { name: "Quatiguá" },
          { name: "Quatro Barras" },
          { name: "Quatro Pontes" },
          { name: "Quedas do Iguaçu" },
          { name: "Querência do Norte" },
          { name: "Quinta do Sol" },
          { name: "Quitandinha" },
          { name: "Ramilândia" },
          { name: "Rancho Alegre" },
          { name: "Rancho Alegre d'Oeste" },
          { name: "Realeza" },
          { name: "Rebouças" },
          { name: "Renascença" },
          { name: "Reserva" },
          { name: "Reserva do Iguaçu" },
          { name: "Ribeirão Claro" },
          { name: "Ribeirão do Pinhal" },
          { name: "Rio Azul" },
          { name: "Rio Bom" },
          { name: "Rio Bonito do Iguaçu" },
          { name: "Rio Branco do Ivaí" },
          { name: "Rio Branco do Sul" },
          { name: "Rio Negro" },
          { name: "Rolândia" },
          { name: "Roncador" },
          { name: "Rondon" },
          { name: "Rosário do Ivaí" },
          { name: "Sabáudia" },
          { name: "Salgado Filho" },
          { name: "Salto do Itararé" },
          { name: "Salto do Lontra" },
          { name: "Santa Amélia" },
          { name: "Santa Cecília do Pavão" },
          { name: "Santa Cruz de Monte Castelo" },
          { name: "Santa Fé" },
          { name: "Santa Helena" },
          { name: "Santa Inês" },
          { name: "Santa Isabel do Ivaí" },
          { name: "Santa Izabel do Oeste" },
          { name: "Santa Lúcia" },
          { name: "Santa Maria do Oeste" },
          { name: "Santa Mariana" },
          { name: "Santa Mônica" },
          { name: "Santa Tereza do Oeste" },
          { name: "Santa Terezinha de Itaipu" },
          { name: "Santana do Itararé" },
          { name: "Santo Antônio da Platina" },
          { name: "Santo Antônio do Caiuá" },
          { name: "Santo Antônio do Paraíso" },
          { name: "Santo Antônio do Sudoeste" },
          { name: "Santo Inácio" },
          { name: "São Carlos do Ivaí" },
          { name: "São Jerônimo da Serra" },
          { name: "São João" },
          { name: "São João do Caiuá" },
          { name: "São João do Ivaí" },
          { name: "São João do Triunfo" },
          { name: "São Jorge d'Oeste" },
          { name: "São Jorge do Ivaí" },
          { name: "São Jorge do Patrocínio" },
          { name: "São José da Boa Vista" },
          { name: "São José das Palmeiras" },
          { name: "São José dos Pinhais" },
          { name: "São Manoel do Paraná" },
          { name: "São Mateus do Sul" },
          { name: "São Miguel do Iguaçu" },
          { name: "São Pedro do Iguaçu" },
          { name: "São Pedro do Ivaí" },
          { name: "São Pedro do Paraná" },
          { name: "São Sebastião da Amoreira" },
          { name: "São Tomé" },
          { name: "Sapopema" },
          { name: "Sarandi" },
          { name: "Saudade do Iguaçu" },
          { name: "Sengés" },
          { name: "Serranópolis do Iguaçu" },
          { name: "Sertaneja" },
          { name: "Sertanópolis" },
          { name: "Siqueira Campos" },
          { name: "Sulina" },
          { name: "Tamarana" },
          { name: "Tamboara" },
          { name: "Tapejara" },
          { name: "Tapira" },
          { name: "Teixeira Soares" },
          { name: "Telêmaco Borba" },
          { name: "Terra Boa" },
          { name: "Terra Rica" },
          { name: "Terra Roxa" },
          { name: "Tibagi" },
          { name: "Tijucas do Sul" },
          { name: "Toledo" },
          { name: "Tomazina" },
          { name: "Três Barras do Paraná" },
          { name: "Tunas do Paraná" },
          { name: "Tuneiras do Oeste" },
          { name: "Tupãssi" },
          { name: "Turvo" },
          { name: "Ubiratã" },
          { name: "Umuarama" },
          { name: "União da Vitória" },
          { name: "Uniflor" },
          { name: "Uraí" },
          { name: "Ventania" },
          { name: "Vera Cruz do Oeste" },
          { name: "Verê" },
          { name: "Vila Alta" },
          { name: "Virmond" },
          { name: "Vitorino" },
          { name: "Wenceslau Braz" },
          { name: "Xambrê" }
        ]
      },
      {
        name:   "Rio Grande do Sul",
        sigla:  "RS",
        cities: [
          { name: "Aceguá" },
          { name: "Água Santa" },
          { name: "Agudo" },
          { name: "Ajuricaba" },
          { name: "Alecrim" },
          { name: "Alegrete" },
          { name: "Alegria" },
          { name: "Almirante Tamandaré do Sul" },
          { name: "Alpestre" },
          { name: "Alto Alegre" },
          { name: "Alto Feliz" },
          { name: "Alvorada" },
          { name: "Amaral Ferrador" },
          { name: "Ametista do Sul" },
          { name: "André da Rocha" },
          { name: "Anta Gorda" },
          { name: "Antônio Prado" },
          { name: "Arambaré" },
          { name: "Araricá" },
          { name: "Aratiba" },
          { name: "Arroio do Meio" },
          { name: "Arroio do Padre" },
          { name: "Arroio do Sal" },
          { name: "Arroio do Tigre" },
          { name: "Arroio dos Ratos" },
          { name: "Arroio Grande" },
          { name: "Arvorezinha" },
          { name: "Augusto Pestana" },
          { name: "Áurea" },
          { name: "Bagé" },
          { name: "Balneário Pinhal" },
          { name: "Barão" },
          { name: "Barão de Cotegipe" },
          { name: "Barão do Triunfo" },
          { name: "Barra do Guarita" },
          { name: "Barra do Quaraí" },
          { name: "Barra do Ribeiro" },
          { name: "Barra do Rio Azul" },
          { name: "Barra Funda" },
          { name: "Barracão" },
          { name: "Barros Cassal" },
          { name: "Benjamin Constant do Sul" },
          { name: "Bento Gonçalves" },
          { name: "Boa Vista das Missões" },
          { name: "Boa Vista do Buricá" },
          { name: "Boa Vista do Cadeado" },
          { name: "Boa Vista do Incra" },
          { name: "Boa Vista do Sul" },
          { name: "Bom Jesus" },
          { name: "Bom Princípio" },
          { name: "Bom Progresso" },
          { name: "Bom Retiro do Sul" },
          { name: "Boqueirão do Leão" },
          { name: "Bossoroca" },
          { name: "Bozano" },
          { name: "Braga" },
          { name: "Brochier" },
          { name: "Butiá" },
          { name: "Caçapava do Sul" },
          { name: "Cacequi" },
          { name: "Cachoeira do Sul" },
          { name: "Cachoeirinha" },
          { name: "Cacique Doble" },
          { name: "Caibaté" },
          { name: "Caiçara" },
          { name: "Camaquã" },
          { name: "Camargo" },
          { name: "Cambará do Sul" },
          { name: "Campestre da Serra" },
          { name: "Campina das Missões" },
          { name: "Campinas do Sul" },
          { name: "Campo Bom" },
          { name: "Campo Novo" },
          { name: "Campos Borges" },
          { name: "Candelária" },
          { name: "Cândido Godói" },
          { name: "Candiota" },
          { name: "Canela" },
          { name: "Canguçu" },
          { name: "Canoas" },
          { name: "Canudos do Vale" },
          { name: "Capão Bonito do Sul" },
          { name: "Capão da Canoa" },
          { name: "Capão do Cipó" },
          { name: "Capão do Leão" },
          { name: "Capela de Santana" },
          { name: "Capitão" },
          { name: "Capivari do Sul" },
          { name: "Caraá" },
          { name: "Carazinho" },
          { name: "Carlos Barbosa" },
          { name: "Carlos Gomes" },
          { name: "Casca" },
          { name: "Caseiros" },
          { name: "Catuípe" },
          { name: "Caxias do Sul" },
          { name: "Centenário" },
          { name: "Cerrito" },
          { name: "Cerro Branco" },
          { name: "Cerro Grande" },
          { name: "Cerro Grande do Sul" },
          { name: "Cerro Largo" },
          { name: "Chapada" },
          { name: "Charqueadas" },
          { name: "Charrua" },
          { name: "Chiapetta" },
          { name: "Chuí" },
          { name: "Chuvisca" },
          { name: "Cidreira" },
          { name: "Ciríaco" },
          { name: "Colinas" },
          { name: "Colorado" },
          { name: "Condor" },
          { name: "Constantina" },
          { name: "Coqueiro Baixo" },
          { name: "Coqueiros do Sul" },
          { name: "Coronel Barros" },
          { name: "Coronel Bicaco" },
          { name: "Coronel Pilar" },
          { name: "Cotiporã" },
          { name: "Coxilha" },
          { name: "Crissiumal" },
          { name: "Cristal" },
          { name: "Cristal do Sul" },
          { name: "Cruz Alta" },
          { name: "Cruzaltense" },
          { name: "Cruzeiro do Sul" },
          { name: "David Canabarro" },
          { name: "Derrubadas" },
          { name: "Dezesseis de Novembro" },
          { name: "Dilermando de Aguiar" },
          { name: "Dois Irmãos" },
          { name: "Dois Irmãos das Missões" },
          { name: "Dois Lajeados" },
          { name: "Dom Feliciano" },
          { name: "Dom Pedrito" },
          { name: "Dom Pedro de Alcântara" },
          { name: "Dona Francisca" },
          { name: "Doutor Maurício Cardoso" },
          { name: "Doutor Ricardo" },
          { name: "Eldorado do Sul" },
          { name: "Encantado" },
          { name: "Encruzilhada do Sul" },
          { name: "Engenho Velho" },
          { name: "Entre Rios do Sul" },
          { name: "Entre-Ijuís" },
          { name: "Erebango" },
          { name: "Erechim" },
          { name: "Ernestina" },
          { name: "Erval Grande" },
          { name: "Erval Seco" },
          { name: "Esmeralda" },
          { name: "Esperança do Sul" },
          { name: "Espumoso" },
          { name: "Estação" },
          { name: "Estância Velha" },
          { name: "Esteio" },
          { name: "Estrela" },
          { name: "Estrela Velha" },
          { name: "Eugênio de Castro" },
          { name: "Fagundes Varela" },
          { name: "Farroupilha" },
          { name: "Faxinal do Soturno" },
          { name: "Faxinalzinho" },
          { name: "Fazenda Vilanova" },
          { name: "Feliz" },
          { name: "Flores da Cunha" },
          { name: "Floriano Peixoto" },
          { name: "Fontoura Xavier" },
          { name: "Formigueiro" },
          { name: "Forquetinha" },
          { name: "Fortaleza dos Valos" },
          { name: "Frederico Westphalen" },
          { name: "Garibaldi" },
          { name: "Garruchos" },
          { name: "Gaurama" },
          { name: "General Câmara" },
          { name: "Gentil" },
          { name: "Getúlio Vargas" },
          { name: "Giruá" },
          { name: "Glorinha" },
          { name: "Gramado" },
          { name: "Gramado dos Loureiros" },
          { name: "Gramado Xavier" },
          { name: "Gravataí" },
          { name: "Guabiju" },
          { name: "Guaíba" },
          { name: "Guaporé" },
          { name: "Guarani das Missões" },
          { name: "Harmonia" },
          { name: "Herval" },
          { name: "Herveiras" },
          { name: "Horizontina" },
          { name: "Hulha Negra" },
          { name: "Humaitá" },
          { name: "Ibarama" },
          { name: "Ibiaçá" },
          { name: "Ibiraiaras" },
          { name: "Ibirapuitã" },
          { name: "Ibirubá" },
          { name: "Igrejinha" },
          { name: "Ijuí" },
          { name: "Ilópolis" },
          { name: "Imbé" },
          { name: "Imigrante" },
          { name: "Independência" },
          { name: "Inhacorá" },
          { name: "Ipê" },
          { name: "Ipiranga do Sul" },
          { name: "Iraí" },
          { name: "Itaara" },
          { name: "Itacurubi" },
          { name: "Itapuca" },
          { name: "Itaqui" },
          { name: "Itati" },
          { name: "Itatiba do Sul" },
          { name: "Ivorá" },
          { name: "Ivoti" },
          { name: "Jaboticaba" },
          { name: "Jacuizinho" },
          { name: "Jacutinga" },
          { name: "Jaguarão" },
          { name: "Jaguari" },
          { name: "Jaquirana" },
          { name: "Jari" },
          { name: "Jóia" },
          { name: "Júlio de Castilhos" },
          { name: "Lagoa Bonita do Sul" },
          { name: "Lagoa dos Três Cantos" },
          { name: "Lagoa Vermelha" },
          { name: "Lagoão" },
          { name: "Lajeado" },
          { name: "Lajeado do Bugre" },
          { name: "Lavras do Sul" },
          { name: "Liberato Salzano" },
          { name: "Lindolfo Collor" },
          { name: "Linha Nova" },
          { name: "Maçambara" },
          { name: "Machadinho" },
          { name: "Mampituba" },
          { name: "Manoel Viana" },
          { name: "Maquiné" },
          { name: "Maratá" },
          { name: "Marau" },
          { name: "Marcelino Ramos" },
          { name: "Mariana Pimentel" },
          { name: "Mariano Moro" },
          { name: "Marques de Souza" },
          { name: "Mata" },
          { name: "Mato Castelhano" },
          { name: "Mato Leitão" },
          { name: "Mato Queimado" },
          { name: "Maximiliano de Almeida" },
          { name: "Minas do Leão" },
          { name: "Miraguaí" },
          { name: "Montauri" },
          { name: "Monte Alegre dos Campos" },
          { name: "Monte Belo do Sul" },
          { name: "Montenegro" },
          { name: "Mormaço" },
          { name: "Morrinhos do Sul" },
          { name: "Morro Redondo" },
          { name: "Morro Reuter" },
          { name: "Mostardas" },
          { name: "Muçum" },
          { name: "Muitos Capões" },
          { name: "Muliterno" },
          { name: "Não-Me-Toque" },
          { name: "Nicolau Vergueiro" },
          { name: "Nonoai" },
          { name: "Nova Alvorada" },
          { name: "Nova Araçá" },
          { name: "Nova Bassano" },
          { name: "Nova Boa Vista" },
          { name: "Nova Bréscia" },
          { name: "Nova Candelária" },
          { name: "Nova Esperança do Sul" },
          { name: "Nova Hartz" },
          { name: "Nova Pádua" },
          { name: "Nova Palma" },
          { name: "Nova Petrópolis" },
          { name: "Nova Prata" },
          { name: "Nova Ramada" },
          { name: "Nova Roma do Sul" },
          { name: "Nova Santa Rita" },
          { name: "Novo Barreiro" },
          { name: "Novo Cabrais" },
          { name: "Novo Hamburgo" },
          { name: "Novo Machado" },
          { name: "Novo Tiradentes" },
          { name: "Novo Xingu" },
          { name: "Osório" },
          { name: "Paim Filho" },
          { name: "Palmares do Sul" },
          { name: "Palmeira das Missões" },
          { name: "Palmitinho" },
          { name: "Panambi" },
          { name: "Pantano Grande" },
          { name: "Paraí" },
          { name: "Paraíso do Sul" },
          { name: "Pareci Novo" },
          { name: "Parobé" },
          { name: "Passa Sete" },
          { name: "Passo do Sobrado" },
          { name: "Passo Fundo" },
          { name: "Paulo Bento" },
          { name: "Paverama" },
          { name: "Pedras Altas" },
          { name: "Pedro Osório" },
          { name: "Pejuçara" },
          { name: "Pelotas" },
          { name: "Picada Café" },
          { name: "Pinhal" },
          { name: "Pinhal da Serra" },
          { name: "Pinhal Grande" },
          { name: "Pinheirinho do Vale" },
          { name: "Pinheiro Machado" },
          { name: "Pirapó" },
          { name: "Piratini" },
          { name: "Planalto" },
          { name: "Poço das Antas" },
          { name: "Pontão" },
          { name: "Ponte Preta" },
          { name: "Portão" },
          { name: "Porto Alegre" },
          { name: "Porto Lucena" },
          { name: "Porto Mauá" },
          { name: "Porto Vera Cruz" },
          { name: "Porto Xavier" },
          { name: "Pouso Novo" },
          { name: "Presidente Lucena" },
          { name: "Progresso" },
          { name: "Protásio Alves" },
          { name: "Putinga" },
          { name: "Quaraí" },
          { name: "Quatro Irmãos" },
          { name: "Quevedos" },
          { name: "Quinze de Novembro" },
          { name: "Redentora" },
          { name: "Relvado" },
          { name: "Restinga Seca" },
          { name: "Rio dos Índios" },
          { name: "Rio Grande" },
          { name: "Rio Pardo" },
          { name: "Riozinho" },
          { name: "Roca Sales" },
          { name: "Rodeio Bonito" },
          { name: "Rolador" },
          { name: "Rolante" },
          { name: "Ronda Alta" },
          { name: "Rondinha" },
          { name: "Roque Gonzales" },
          { name: "Rosário do Sul" },
          { name: "Sagrada Família" },
          { name: "Saldanha Marinho" },
          { name: "Salto do Jacuí" },
          { name: "Salvador das Missões" },
          { name: "Salvador do Sul" },
          { name: "Sananduva" },
          { name: "Santa Bárbara do Sul" },
          { name: "Santa Cecília do Sul" },
          { name: "Santa Clara do Sul" },
          { name: "Santa Cruz do Sul" },
          { name: "Santa Margarida do Sul" },
          { name: "Santa Maria" },
          { name: "Santa Maria do Herval" },
          { name: "Santa Rosa" },
          { name: "Santa Tereza" },
          { name: "Santa Vitória do Palmar" },
          { name: "Santana da Boa Vista" },
          { name: "Santana do Livramento" },
          { name: "Santiago" },
          { name: "Santo Ângelo" },
          { name: "Santo Antônio da Patrulha" },
          { name: "Santo Antônio das Missões" },
          { name: "Santo Antônio do Palma" },
          { name: "Santo Antônio do Planalto" },
          { name: "Santo Augusto" },
          { name: "Santo Cristo" },
          { name: "Santo Expedito do Sul" },
          { name: "São Borja" },
          { name: "São Domingos do Sul" },
          { name: "São Francisco de Assis" },
          { name: "São Francisco de Paula" },
          { name: "São Gabriel" },
          { name: "São Jerônimo" },
          { name: "São João da Urtiga" },
          { name: "São João do Polêsine" },
          { name: "São Jorge" },
          { name: "São José das Missões" },
          { name: "São José do Herval" },
          { name: "São José do Hortêncio" },
          { name: "São José do Inhacorá" },
          { name: "São José do Norte" },
          { name: "São José do Ouro" },
          { name: "São José do Sul" },
          { name: "São José dos Ausentes" },
          { name: "São Leopoldo" },
          { name: "São Lourenço do Sul" },
          { name: "São Luiz Gonzaga" },
          { name: "São Marcos" },
          { name: "São Martinho" },
          { name: "São Martinho da Serra" },
          { name: "São Miguel das Missões" },
          { name: "São Nicolau" },
          { name: "São Paulo das Missões" },
          { name: "São Pedro da Serra" },
          { name: "São Pedro das Missões" },
          { name: "São Pedro do Butiá" },
          { name: "São Pedro do Sul" },
          { name: "São Sebastião do Caí" },
          { name: "São Sepé" },
          { name: "São Valentim" },
          { name: "São Valentim do Sul" },
          { name: "São Valério do Sul" },
          { name: "São Vendelino" },
          { name: "São Vicente do Sul" },
          { name: "Sapiranga" },
          { name: "Sapucaia do Sul" },
          { name: "Sarandi" },
          { name: "Seberi" },
          { name: "Sede Nova" },
          { name: "Segredo" },
          { name: "Selbach" },
          { name: "Senador Salgado Filho" },
          { name: "Sentinela do Sul" },
          { name: "Serafina Corrêa" },
          { name: "Sério" },
          { name: "Sertão" },
          { name: "Sertão Santana" },
          { name: "Sete de Setembro" },
          { name: "Severiano de Almeida" },
          { name: "Silveira Martins" },
          { name: "Sinimbu" },
          { name: "Sobradinho" },
          { name: "Soledade" },
          { name: "Tabaí" },
          { name: "Tapejara" },
          { name: "Tapera" },
          { name: "Tapes" },
          { name: "Taquara" },
          { name: "Taquari" },
          { name: "Taquaruçu do Sul" },
          { name: "Tavares" },
          { name: "Tenente Portela" },
          { name: "Terra de Areia" },
          { name: "Teutônia" },
          { name: "Tio Hugo" },
          { name: "Tiradentes do Sul" },
          { name: "Toropi" },
          { name: "Torres" },
          { name: "Tramandaí" },
          { name: "Travesseiro" },
          { name: "Três Arroios" },
          { name: "Três Cachoeiras" },
          { name: "Três Coroas" },
          { name: "Três de Maio" },
          { name: "Três Forquilhas" },
          { name: "Três Palmeiras" },
          { name: "Três Passos" },
          { name: "Trindade do Sul" },
          { name: "Triunfo" },
          { name: "Tucunduva" },
          { name: "Tunas" },
          { name: "Tupanci do Sul" },
          { name: "Tupanciretã" },
          { name: "Tupandi" },
          { name: "Tuparendi" },
          { name: "Turuçu" },
          { name: "Ubiretama" },
          { name: "União da Serra" },
          { name: "Unistalda" },
          { name: "Uruguaiana" },
          { name: "Vacaria" },
          { name: "Vale do Sol" },
          { name: "Vale Real" },
          { name: "Vale Verde" },
          { name: "Vanini" },
          { name: "Venâncio Aires" },
          { name: "Vera Cruz" },
          { name: "Veranópolis" },
          { name: "Vespasiano Correa" },
          { name: "Viadutos" },
          { name: "Viamão" },
          { name: "Vicente Dutra" },
          { name: "Victor Graeff" },
          { name: "Vila Flores" },
          { name: "Vila Lângaro" },
          { name: "Vila Maria" },
          { name: "Vila Nova do Sul" },
          { name: "Vista Alegre" },
          { name: "Vista Alegre do Prata" },
          { name: "Vista Gaúcha" },
          { name: "Vitória das Missões" },
          { name: "Westfália" },
          { name: "Xangri-lá" }
        ]
      },
      {
        name:   "Santa Catarina",
        sigla:  "SC",
        cities: [
          { name: "Abdon Batista" },
          { name: "Abelardo Luz" },
          { name: "Agrolândia" },
          { name: "Agronômica" },
          { name: "Água Doce" },
          { name: "Águas de Chapecó" },
          { name: "Águas Frias" },
          { name: "Águas Mornas" },
          { name: "Alfredo Wagner" },
          { name: "Alto Bela Vista" },
          { name: "Anchieta" },
          { name: "Angelina" },
          { name: "Anita Garibaldi" },
          { name: "Anitápolis" },
          { name: "Antônio Carlos" },
          { name: "Apiúna" },
          { name: "Arabutã" },
          { name: "Araquari" },
          { name: "Araranguá" },
          { name: "Armazém" },
          { name: "Arroio Trinta" },
          { name: "Arvoredo" },
          { name: "Ascurra" },
          { name: "Atalanta" },
          { name: "Aurora" },
          { name: "Balneário Arroio do Silva" },
          { name: "Balneário Barra do Sul" },
          { name: "Balneário Camboriú" },
          { name: "Balneário Gaivota" },
          { name: "Balneário Rincão" },
          { name: "Bandeirante" },
          { name: "Barra Bonita" },
          { name: "Barra Velha" },
          { name: "Bela Vista do Toldo" },
          { name: "Belmonte" },
          { name: "Benedito Novo" },
          { name: "Biguaçu" },
          { name: "Blumenau" },
          { name: "Bocaina do Sul" },
          { name: "Bom Jardim da Serra" },
          { name: "Bom Jesus" },
          { name: "Bom Jesus do Oeste" },
          { name: "Bom Retiro" },
          { name: "Bombinhas" },
          { name: "Botuverá" },
          { name: "Braço do Norte" },
          { name: "Braço do Trombudo" },
          { name: "Brunópolis" },
          { name: "Brusque" },
          { name: "Caçador" },
          { name: "Caibi" },
          { name: "Calmon" },
          { name: "Camboriú" },
          { name: "Campo Alegre" },
          { name: "Campo Belo do Sul" },
          { name: "Campo Erê" },
          { name: "Campos Novos" },
          { name: "Canelinha" },
          { name: "Canoinhas" },
          { name: "Capão Alto" },
          { name: "Capinzal" },
          { name: "Capivari de Baixo" },
          { name: "Catanduvas" },
          { name: "Caxambu do Sul" },
          { name: "Celso Ramos" },
          { name: "Cerro Negro" },
          { name: "Chapadão do Lageado" },
          { name: "Chapecó" },
          { name: "Cocal do Sul" },
          { name: "Concórdia" },
          { name: "Cordilheira Alta" },
          { name: "Coronel Freitas" },
          { name: "Coronel Martins" },
          { name: "Correia Pinto" },
          { name: "Corupá" },
          { name: "Criciúma" },
          { name: "Cunha Porã" },
          { name: "Cunhataí" },
          { name: "Curitibanos" },
          { name: "Descanso" },
          { name: "Dionísio Cerqueira" },
          { name: "Dona Emma" },
          { name: "Doutor Pedrinho" },
          { name: "Entre Rios" },
          { name: "Ermo" },
          { name: "Erval Velho" },
          { name: "Faxinal dos Guedes" },
          { name: "Flor do Sertão" },
          { name: "Florianópolis" },
          { name: "Formosa do Sul" },
          { name: "Forquilhinha" },
          { name: "Fraiburgo" },
          { name: "Frei Rogério" },
          { name: "Galvão" },
          { name: "Garopaba" },
          { name: "Garuva" },
          { name: "Gaspar" },
          { name: "Governador Celso Ramos" },
          { name: "Grão Pará" },
          { name: "Gravatal" },
          { name: "Guabiruba" },
          { name: "Guaraciaba" },
          { name: "Guaramirim" },
          { name: "Guarujá do Sul" },
          { name: "Guatambú" },
          { name: "Herval d'Oeste" },
          { name: "Ibiam" },
          { name: "Ibicaré" },
          { name: "Ibirama" },
          { name: "Içara" },
          { name: "Ilhota" },
          { name: "Imaruí" },
          { name: "Imbituba" },
          { name: "Imbuia" },
          { name: "Indaial" },
          { name: "Iomerê" },
          { name: "Ipira" },
          { name: "Iporã do Oeste" },
          { name: "Ipuaçu" },
          { name: "Ipumirim" },
          { name: "Iraceminha" },
          { name: "Irani" },
          { name: "Irati" },
          { name: "Irineópolis" },
          { name: "Itá" },
          { name: "Itaiópolis" },
          { name: "Itajaí" },
          { name: "Itapema" },
          { name: "Itapiranga" },
          { name: "Itapoá" },
          { name: "Ituporanga" },
          { name: "Jaborá" },
          { name: "Jacinto Machado" },
          { name: "Jaguaruna" },
          { name: "Jaraguá do Sul" },
          { name: "Jardinópolis" },
          { name: "Joaçaba" },
          { name: "Joinville" },
          { name: "José Boiteux" },
          { name: "Jupiá" },
          { name: "Lacerdópolis" },
          { name: "Lages" },
          { name: "Laguna" },
          { name: "Lajeado Grande" },
          { name: "Laurentino" },
          { name: "Lauro Muller" },
          { name: "Lebon Régis" },
          { name: "Leoberto Leal" },
          { name: "Lindóia do Sul" },
          { name: "Lontras" },
          { name: "Luiz Alves" },
          { name: "Luzerna" },
          { name: "Macieira" },
          { name: "Mafra" },
          { name: "Major Gercino" },
          { name: "Major Vieira" },
          { name: "Maracajá" },
          { name: "Maravilha" },
          { name: "Marema" },
          { name: "Massaranduba" },
          { name: "Matos Costa" },
          { name: "Meleiro" },
          { name: "Mirim Doce" },
          { name: "Modelo" },
          { name: "Mondaí" },
          { name: "Monte Carlo" },
          { name: "Monte Castelo" },
          { name: "Morro da Fumaça" },
          { name: "Morro Grande" },
          { name: "Navegantes" },
          { name: "Nova Erechim" },
          { name: "Nova Itaberaba" },
          { name: "Nova Trento" },
          { name: "Nova Veneza" },
          { name: "Novo Horizonte" },
          { name: "Orleans" },
          { name: "Otacílio Costa" },
          { name: "Ouro" },
          { name: "Ouro Verde" },
          { name: "Paial" },
          { name: "Painel" },
          { name: "Palhoça" },
          { name: "Palma Sola" },
          { name: "Palmeira" },
          { name: "Palmitos" },
          { name: "Papanduva" },
          { name: "Paraíso" },
          { name: "Passo de Torres" },
          { name: "Passos Maia" },
          { name: "Paulo Lopes" },
          { name: "Pedras Grandes" },
          { name: "Penha" },
          { name: "Peritiba" },
          { name: "Petrolândia" },
          { name: "Piçarras" },
          { name: "Pinhalzinho" },
          { name: "Pinheiro Preto" },
          { name: "Piratuba" },
          { name: "Planalto Alegre" },
          { name: "Pomerode" },
          { name: "Ponte Alta" },
          { name: "Ponte Alta do Norte" },
          { name: "Ponte Serrada" },
          { name: "Porto Belo" },
          { name: "Porto União" },
          { name: "Pouso Redondo" },
          { name: "Praia Grande" },
          { name: "Presidente Castelo Branco" },
          { name: "Presidente Getúlio" },
          { name: "Presidente Nereu" },
          { name: "Princesa" },
          { name: "Quilombo" },
          { name: "Rancho Queimado" },
          { name: "Rio das Antas" },
          { name: "Rio do Campo" },
          { name: "Rio do Oeste" },
          { name: "Rio do Sul" },
          { name: "Rio dos Cedros" },
          { name: "Rio Fortuna" },
          { name: "Rio Negrinho" },
          { name: "Rio Rufino" },
          { name: "Riqueza" },
          { name: "Rodeio" },
          { name: "Romelândia" },
          { name: "Salete" },
          { name: "Saltinho" },
          { name: "Salto Veloso" },
          { name: "Sangão" },
          { name: "Santa Cecília" },
          { name: "Santa Helena" },
          { name: "Santa Rosa de Lima" },
          { name: "Santa Rosa do Sul" },
          { name: "Santa Terezinha" },
          { name: "Santa Terezinha do Progresso" },
          { name: "Santiago do Sul" },
          { name: "Santo Amaro da Imperatriz" },
          { name: "São Bento do Sul" },
          { name: "São Bernardino" },
          { name: "São Bonifácio" },
          { name: "São Carlos" },
          { name: "São Cristovão do Sul" },
          { name: "São Domingos" },
          { name: "São Francisco do Sul" },
          { name: "São João Batista" },
          { name: "São João do Itaperiú" },
          { name: "São João do Oeste" },
          { name: "São João do Sul" },
          { name: "São Joaquim" },
          { name: "São José" },
          { name: "São José do Cedro" },
          { name: "São José do Cerrito" },
          { name: "São Lourenço do Oeste" },
          { name: "São Ludgero" },
          { name: "São Martinho" },
          { name: "São Miguel da Boa Vista" },
          { name: "São Miguel do Oeste" },
          { name: "São Pedro de Alcântara" },
          { name: "Saudades" },
          { name: "Schroeder" },
          { name: "Seara" },
          { name: "Serra Alta" },
          { name: "Siderópolis" },
          { name: "Sombrio" },
          { name: "Sul Brasil" },
          { name: "Taió" },
          { name: "Tangará" },
          { name: "Tigrinhos" },
          { name: "Tijucas" },
          { name: "Timbé do Sul" },
          { name: "Timbó" },
          { name: "Timbó Grande" },
          { name: "Três Barras" },
          { name: "Treviso" },
          { name: "Treze de Maio" },
          { name: "Treze Tílias" },
          { name: "Trombudo Central" },
          { name: "Tubarão" },
          { name: "Tunápolis" },
          { name: "Turvo" },
          { name: "União do Oeste" },
          { name: "Urubici" },
          { name: "Urupema" },
          { name: "Urussanga" },
          { name: "Vargeão" },
          { name: "Vargem" },
          { name: "Vargem Bonita" },
          { name: "Vidal Ramos" },
          { name: "Videira" },
          { name: "Vitor Meireles" },
          { name: "Witmarsum" },
          { name: "Xanxerê" },
          { name: "Xavantina" },
          { name: "Xaxim" },
          { name: "Zortéa" }
        ]
      }
    ]
  }
]
