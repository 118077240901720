import { toastAttributes } from "./attributes"

/**
 * @template T
 * @typedef {keyof T} GetKeys
 *
*/

/** @typedef {GetKeys<toastAttributes>} ToastAttributes */

/**
 *
 * @param {ToastAttributes} errorAttribute
 * @param {{error: Error, alternativeMessage: string}} param1
 * @returns {string}
 */
export function getErrorMessage(errorAttribute, { error, alternativeMessage = "Um erro ocorreu. Tente novamente mais tarde." } = {}) {
  if (error?.response?.data?.message) return error.response.data.message

  const errorsObject = error?.response?.data?.errors

  if (!errorsObject) {
    return alternativeMessage
  }

  const errorType = Object.keys(errorsObject)[0]
  const messageBase = toastAttributes[errorAttribute][errorType]

  if (!messageBase) {
    console.error(`getErrorMessage - key "${errorType}" does not exists on attribute "${errorAttribute}"`)
    return alternativeMessage
  }

  const objectMessage = errorsObject[errorType][0]

  /** @type {string[]} */
  const messageArray = [messageBase, objectMessage]

  if (!messageArray.every(Boolean)) return alternativeMessage

  return messageArray.join(" ")
}

/**
 * @param {ToastAttributes} attribute
 * @returns {(param: Parameters<getErrorMessage>[1]) => string}
 */
export function errorHandlerConstructor(attribute) {
  const attributeExists = Object.keys(toastAttributes).includes(attribute)
  if (!attributeExists) throw new Error(`errorHandlerConstructor - attribute "${attribute}" does not exists`)

  return ({ error, alternativeMessage }) =>
    getErrorMessage(attribute, { error, alternativeMessage })
}
