import { format, isValid } from "date-fns"
import Vue from "vue/dist/vue.esm"
import { configure, extend, ValidationObserver, ValidationProvider } from "vee-validate"
import * as rules from "vee-validate/dist/rules"
import { i18n } from "../i18n"

import { copyDeeply } from "../../helpers"

import { cpf } from "./cpf"
import { customMaxValue } from "./custom-max-value"
import { dateAfter } from "./date-after"
import { decimal } from "./decimal"
import { maxLength } from "./max-length"
import { pastDate } from "./past-date"
import { phone } from "./phone"

Vue.component("ValidationObserver", ValidationObserver)
Vue.component("ValidationProvider", ValidationProvider)

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

extend("cpf", cpf)
extend("custom_max_value", customMaxValue)
extend("date_after", dateAfter)
extend("max_length", maxLength)
extend("past_date", pastDate)
extend("phone", phone)

extend("decimal", {
  ...decimal,
  message: (fieldName, { decimals = "*", separator = "." } = {}) => {
    const field = i18n.t(`form.fields.${fieldName}`)

    let separatorMessage = copyDeeply(separator)
    if (Array.isArray(separator)) {
      const lastItem = separatorMessage.pop()

      separatorMessage = separatorMessage.map(char => `'${char}'`).join(", ") + ` ou '${lastItem}'`
    }
    else {
      separatorMessage = `'${separatorMessage}'`
    }

    return decimals === "*"
      ? `${field} deve ter casas decimais separado por ${separatorMessage}`
      : `${field} deve ter até ${decimals} casas decimais separado por ${separatorMessage}`
  },
})

configure({
  classes: {
    invalid: "is-invalid"
  },

  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`form.fields.${field}`)

    for (let key in values) {
      if (key === "target") {
        values[key] = i18n.t(`form.fields.${values.target}`)
        //
      } else if (key.includes("date")) {
        values[key] = isValid(new Date(values[key]))
          ? format(new Date(values[key]), "dd/MM/yyyy")
          : i18n.t(`form.fields.${field}`)
      }
    }


    return i18n.t(`form.validation.messages.${values._rule_}`, values)
  }
})
