import { BaseEndpoint } from "./Base"

export default class UserDocumentNumberEndpoint extends BaseEndpoint {
  static get selector() {
    return "users.documentNumbers"
  }

  update({ userId, params = {}, options = {} } = {}) {
    this.verifyData("update", { userId })

    return this.action({
      method:  "patch",
      url:     `/users/${userId}/document_numbers`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  delete({ userId, params = {}, options = {} } = {}) {
    this.verifyData("delete", { userId })

    return this.action({
      method:  "delete",
      url:     `/users/${userId}/document_numbers`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
