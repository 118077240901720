import { BaseEndpoint } from "./Base"

export default class ForbiddenTermEndpoint extends BaseEndpoint {
  static get selector() {
    return "forbiddenTerms"
  }

  index({ params = {}, options = {} } = {}) {

    return this.action({
      method:  "get",
      url:     "/forbidden_terms",
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  show({ forbiddenTermId, params = {}, options = {} } = {}) {
    this.verifyData("show", { forbiddenTermId })

    return this.action({
      method:  "get",
      url:     `/forbidden_terms/${forbiddenTermId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  create({ params = {}, options = {} } = {}) {

    return this.action({
      method:  "post",
      url:     "/forbidden_terms",
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  update({ forbiddenTermId, params = {}, options = {} } = {}) {
    this.verifyData("update", { forbiddenTermId })

    return this.action({
      method:  "patch",
      url:     `/forbidden_terms/${forbiddenTermId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  delete({ forbiddenTermId, params = {}, options = {} } = {}) {
    this.verifyData("delete", { forbiddenTermId })

    return this.action({
      method:  "delete",
      url:     `/forbidden_terms/${forbiddenTermId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  // Refatorar!
  validate({ params = {}, options = {} } = {}) {

    return this.action({
      method:  "post",
      url:     "/forbidden_terms/validate",
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
