import { format, parseISO } from "date-fns"

const localizedFormat = "dd/MM/y"

function formatDate (dateStr, formatType = localizedFormat) {
  if (!dateStr) { return null }
  return format(parseISO(dateStr), formatType)
}

const timeFormat = "HH:mm"

function formatTime (timeStr, formatType = timeFormat) {
  if (!timeStr) { return null }
  return format(parseISO(timeStr), formatType)
}

const formatBrDate = (date, withMinutes = false) => format(date, withMinutes ? "dd/LL/yy HH:mm" : "dd/LL/yy")
const formatDateAndHour = isoString => formatBrDate(parseISO(isoString), true)


export {
  formatDateAndHour,
  formatDate,
  formatTime
}
