import { BaseEndpoint } from "./Base"

export default class CommentEndpoint extends BaseEndpoint {
  static get selector() {
    return "comments"
  }

  create({ params = {}, options = {} } = {}) {

    return this.action({
      method:  "post",
      url:     "/comments",
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
