export { COURSE_KIND, COURSE_STATUS } from "./course"
export { PERMISSION } from "./permission"
export { DATA_ACCESS_LOG_ORIGIN, DATA_ACCESS_LOG_VIEWABLE_TYPE, REPORT_STATUS } from "./report"
export { ENROLLED_PROGRESS, PHASE_KIND, SELECTION_PROCESS_STATUS } from "./selection-process"
export { FILE_TYPE } from "./file"

export const ORGANIZATION_NAME = /** @type {const} */ ({
  ESCOLA_FUNDACAO_ITAU: "escola_fundacao_itau",
})

export const FORM_STATUS = /** @type {const} */({
  UNSTARTED: "A iniciar",
  ONGOING:   "Em andamento",
  ENDED:     "Finalizado",
  ARCHIVED:  "Arquivado",
})

export const SUBTITLE_LANGUAGE = /** @type {const} */ ({
  PT: { label: "Português do Brasil", value: "pt-BR" },
  ES: { label: "Espanhol", value: "es" },
  EN: { label: "Inglês", value: "en" },
})

export const EVENT = /** @type {const} */ ({
  USER: {
    EMAIL_CHANGE:    "Events::Users::EmailChange",
    RESET_PASSWORD:  "Events::Users::ResetPassword",
    DOCUMENT_CHANGE: "Events::Users::DocumentChange",
  }
})
