export const copyDeeply = (object) => JSON.parse(JSON.stringify(object))

export function renameKeys(mainObject, mappingArray) {
  const keysMap = new Map(mappingArray)

  const result = {}

  for (let key in mainObject) {
    const value = mainObject[key]
    let resultKey = keysMap.get(key) || key

    const isParamObject = checkIfObject(value)
    const isArrayOfObjects = Array.isArray(value) && value.some(checkIfObject)

    if (isParamObject) {
      result[resultKey] = renameKeys(value, mappingArray)
      continue
    }

    if (isArrayOfObjects) {
      result[resultKey] = value.map( obj => renameKeys(obj, mappingArray))
      continue
    }

    result[resultKey] = value
  }

  return result
}

function checkIfObject(param) {
  return typeof param === "object"
    && param !== null
    && !Array.isArray(param)
}
