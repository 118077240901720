export const COURSE_KIND = /** @type {const} */ ({
  SELF_FORMATION: {
    value: "self_formation",
    label: "Autoformativo",
  },
  WITH_MEDIATOR: {
    value: "with_mediator",
    label: "Mediado",
  },
})

export const COURSE_STATUS = /** @type {const} */ ({
  DRAFT: {
    value:     "draft",
    label:     "Rascunho",
    iconClass: "far fa-file-alt",
  },
  SOON: {
    value:     "soon",
    label:     "Em breve",
    iconClass: "far fa-clock",
  },
  ENTRIES_RELEASED: {
    value:     "entries_released",
    label:     "Inscrição liberada",
    iconClass: "far fa-file-edit",
  },
  ENTRIES_CLOSED: {
    value:     "entries_closed",
    label:     "Inscrição encerrada",
    iconClass: "far fa-file-exclamation",
  },
  AVAILABLE: {
    value:     "available",
    label:     "Disponível para alunos",
    iconClass: "far fa-check",
  },
  CLOSED: {
    value:     "closed",
    label:     "Encerrado",
    iconClass: "far fa-file-times",
  },
  ARCHIVED: {
    value:     "archived",
    label:     "Arquivado",
    iconClass: "far fa-archive",
  },
})

export const LEARNING_CONTENT_TYPE = /** @type {const} */ ({
  DOCUMENT: /** @type {const} */ ({
    value:   "Library::Document",
    faClass: "fal fa-book-reader",
    title:   "Documento de leitura",
  }),
  EDITORIAL: /** @type {const} */ ({
    value:   "Editorial",
    faClass: "fal fa-file-alt",
    title:   "Editorial",
  }),
  CLASSROOM: /** @type {const} */ ({
    value:   "Classroom",
    faClass: "fal fa-chalkboard-teacher",
    title:   "Sala de aula",
  }),
  UPLOAD: /** @type {const} */ ({
    value:   "Student::UploadResource",
    faClass: "fal fa-upload",
    title:   "Recurso de upload",
  }),
  OPEN_QUESTION: /** @type {const} */ ({
    value:   "OpenQuestion",
    faClass: "fal fa-comments",
    title:   "Pergunta aberta",
  }),
  QUIZ: /** @type {const} */ ({
    value:   "Quiz",
    faClass: "fal fa-question",
    title:   "Quizz",
  }),
  SCORM: /** @type {const} */ ({
    value:   "Library::Scorm",
    faClass: "fal fa-chart-network",
    title:   "Rec. de aprendizagem interativa",
  }),
  VIDEO: /** @type {const} */ ({
    value:   "Library::Video",
    faClass: "fal fa-play-circle",
    title:   "Vídeo",
  }),
})

export const STUDENT_PROGRESS_STATUS = /** @type {const} */ ({
  WITH_MEDIATOR: {
    IN_PROGRESS: { value: "in_progress", label: "Pendente" },
    FINISHED:    { value: "finished", label: "Concluído" },
  },

  SELF_FORMATION: {
    IN_PROGRESS: { value: "in_progress", label: "Pendente" },
    FINISHED:    { value: "finished", label: "Finalizado" },
    EXPIRED:     { value: "expired", label: "Expirado" },
  },
})

export const COLLABORATOR_ROLE = /** @type {const} */ ({
  EDITOR:  { value: "editor", label: "Conteúdo do curso" },
  MONITOR: { value: "monitor", label: "Monitorar interações" },
})

export const ERROR_MESAGE = /** @type {const} */ ({
  VIDEO_WITHOUT_IMAGE: {
    value:           "File does not have a valid video",
    alternativeText: "Arquivo inválido. Por favor, envie um arquivo que inclua conteúdo de vídeo."
  },
})

export const COMMENT_USER_ROLE = /** @type {const} */ ({
  ADMIN: {
    value: "Administrador",
    label: "Equipe da escola"
  },
  ALUNO: {
    value: "Aluno",
    label: "Aluno"
  },
  STUDENT: {
    value: "student",
    label: "Aluno"
  },
})

export const LEARNING_CONTENT_PROCESSING_STATUS = /** @type {const} */ ({
  scheduled: {
    label: "Processamento agendado",
    icon:  "text-brand-primary-500 fal fa-clock",
    info:  "Processamento agendado! O tempo para inicio e encerramento desta etapa pode variar de acordo com o tamanho do arquivo. Por favor, acesse mais tarde para verificar a atualização do status.",
  },
  in_process: {
    label: "Processamento iniciado",
    icon:  "text-brand-primary-500 fal fa-circle-notch fa-spin",
    info:  "Processamento iniciado! O arquivo já está sendo processado pelo sistema, o encerramento desta etapa pode variar de acordo com o tamanho do arquivo. Por favor, acesse mais tarde para verificar a atualização dos status",
  },
  done: {
    label: "Sucesso no processamento do arquivo",
    icon:  "text-green-500 fal fa-check",
    info:  "O arquivo foi processado e está disponível para o aluno",
  },
  failure: {
    label: "Ops! Houve uma falha ao processar o arquivo.",
    icon:  "fal fa-exclamation-circle text-red-500",
    info:  "Ops! houve uma falha ao processar o arquivo. Tente subir novamente, caso o erro persiste entrar em contato com o administrador do sistema.",
  },
})
