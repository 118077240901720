export const toastAttributes = /**@type {const}*/({
  role: {
    base: "O perfil",
    name: "Nome",
  },
  user: {
    base:                                         "O usuário",
    name:                                         "Nome do Usuário",
    email:                                        "E-mail",
    birth_date:                                   "Data de nascimento",
    password:                                     "Senha",
    country_code:                                 "Código do país",
    country_iso:                                  "Código do país",
    phone:                                        "Telefone",
    phone_number:                                 "Telefone", // NOTE: Parâmetro do Cognito.
    social_name:                                  "Nome social",
    consent_form:                                 "Aceite de termos",
    receive_email:                                "Aceite de Email",
    user_sensitive_datum:                         "Informações pessoais",
    ["user_sensitive_datum.first_name"]:          "Primeiro nome",
    ["user_sensitive_datum.last_name"]:           "Sobrenome",
    ["user_sensitive_datum.cpf"]:                 "CPF",
    ["user_sensitive_datum.ethnie"]:              "Raça/Cor",
    ["user_sensitive_datum.education"]:           "Nível de escolaridade",
    ["user_sensitive_datum.gender_identity"]:     "Gênero",
    ["user_sensitive_datum.disability"]:          "Possui Deficiência",
    ["user_sensitive_datum.disabilityAnswer"]:    "Tipo de Deficiência",
    ["user_sensitive_datum.accessibility"]:       "Precisa de recurso de acessibilidade",
    ["user_sensitive_datum.accessibilityAnswer"]: "Recurso de acessibilidade",
    ["user_sensitive_datum.country"]:             "País em que reside/atua",
    ["user_sensitive_datum.region"]:              "Região do país em que reside/atua",
    ["user_sensitive_datum.state"]:               "Estado em que reside/atua",
    ["user_sensitive_datum.city"]:                "Município em que reside/atua",
    ["user_sensitive_datum.neighborhood"]:        "Bairro em que reside/atua",
    ["user_sensitive_datum.disability_answer"]:   "Tipo dedeficiência",
    ["user_sensitive_datum.organization_type"]:   "Tipo de organização",
    target_email:                                 "Email novo",
    user_role:                                    "Usuário",
    code:                                         "Código de confirmação",
    previous_value:                               "Dado anterior"
  },
  permission: {
    base:        "A permissão",
    name:        "Nome",
    description: "Descrição",
  },
  resourceClassroom: {
    base:       "O recurso Sala de Aula",
    event_date: "A data",
    start_time: "O horário de início",
    end_time:   "O horário de término",
  },
  resourceUpload: {
    base:     "O recurso Upload",
    start_at: "A data e hora de início",
    end_at:   "A data e hora de término",
  },
  contents: {
    content_file: "O arquivo"
  },
  subscription: {
    user:   "O usuário",
    course: "O curso",
  },
  category: {
    name: "O nome da categoria",
  },
  forbidden_term: {
    content: "O termo sensível",
  },
  certificate: {
    base:   "O certificado",
    name:   "O nome",
    file:   "O arquivo",
    layout: "O layout",
  },
  learning_content: {
    start_time: "Horário de início",
    end_time:   "Horário de término",
    start_at:   "Data e hora de início",
    end_at:     "Data e hora de término",
    file:       "Arquivo",
  },
  course: {
    certificate_id:                  "O certificado",
    selection_process_file:          "Folha de Descrição",
    certificate_state:               "Estado do Certificado",
    ["teaser_video.file"]:           "Vídeo",
    ["teaser_video.name"]:           "Nome do vídeo",
    ["teaser_video.subtitles.file"]: "Legenda",
    ["teaser_video.subtitles.name"]: "Nome da legenda",
    ["teaser_video.subtitles.code"]: "Língua legenda",
    ["course_teams.team_id"]:        "Equipe",
  },
})
