export const PERMISSION = /** @type {const} */ ({
  ROLE: {
    READ:     "role.read",
    ACCESSOR: "role.accessor",
    CREATE:   "role.create",
    DESTROY:  "role.destroy",
  },
  USER: {
    READ:           "user.read",
    ACCESSOR:       "user.accessor",
    SENSITIVE_DATA: "user.sensitive_data"
  },
  COURSE: {
    READ:     "course.read",
    ACCESSOR: "course.accessor",
    CREATE:   "course.create",
    DESTROY:  "course.destroy",
  },
  FORM: {
    READ:     "form.read",
    ACCESSOR: "form.accessor",
    CREATE:   "form.create",
    DESTROY:  "form.destroy",
  },
  FORBIDDEN_TERMS: {
    READ:     "forbidden_term.read",
    ACCESSOR: "forbidden_term.accessor",
    CREATE:   "forbidden_term.create",
    DESTROY:  "forbidden_term.destroy",
  },
  SELECTION_PROCESS: {
    READ:     "selection_process.read",
    ACCESSOR: "selection_process.accessor",
    CREATE:   "selection_process.create",
    DESTROY:  "selection_process.destroy",
  },
  PAGES_SECTIONS: {
    READ:     "pages_section.read",
    ACCESSOR: "pages_section.accessor",
  },
  SUBSCRIPTIONS: {
    READ:     "course_subscription.read",
    ACCESSOR: "course_subscription.accessor",
  },
  CATEGORIES: {
    READ:     "category.read",
    ACCESSOR: "category.accessor",
  },
  CERTIFICATES: {
    READ:     "certificate.read",
    ACCESSOR: "certificate.accessor",
    CREATE:   "certificate.create",
    DESTROY:  "certificate.destroy",
  },
  REPORT: {
    LIST_REPORTS:                         "report.list_reports",
    LIST_SUBSCRIPTIONS:                   "report.list_subscriptions",
    LIST_SELECTION_PROCESS_SUBSCRIPTIONS: "report.list_selection_process_subscriptions",
    LIST_PERSONAL_DATA_ACCESS:            "report.list_personal_data_access",
    LIST_USER_DATA:                       "report.list_user_data",
    EXPORT:                               "report.export",
  },
})
