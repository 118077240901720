/**
 * @param {Record<string,any>} params
 */
export function generateSearchParams(params, initialSearchParams = "") {
  const searchParams = new URLSearchParams(initialSearchParams)

  for (let key in params) {
    if (!params[key]) continue

    searchParams.set(key, params[key])
  }

  return searchParams
}
