export const workPlaces = [
  "Arte, cultura ou Economia criativa",
  "Profissional da educação",
  "Outra",
]

export const workArts = [
  "Analista de sistema",
  "Arquiteto(a)",
  "Arquivista",
  "Artesão(ã)",
  "Artista visual",
  "Ator/ atriz",
  "Bailarino(a)",
  "Bibliotecário(a)",
  "Cenógrafo(a)",
  "Chef de cozinha",
  "Compositor(a)",
  "Coreógrafo(a)",
  "Curador/ curadora",
  "Desenvolvedor(a) de games",
  "Designer gráfico",
  "Diretor(a) de audiovisual",
  "Diretor(a) de fotografia",
  "Dramaturgo(a)",
  "Editor(a) de vídeo",
  "Escritor(a)",
  "Figurinista",
  "Fotógrafo(a)",
  "Gestor(a) cultural",
  "Jornalista",
  "Mediador(a) cultural",
  "Museólogo(a)",
  "Música / músico",
  "Pesquisador(a)",
  "Produtor(a) audiovisual",
  "Produtor(a) cultural",
  "Publicitário(a)",
  "Técnico(a) em galerias de arte",
  "Trabalhadores da moda",
  "Outro",
]

export const workEducation = [
  "Autônomo(a)",
  "Consultor(a)",
  "Coordenador(a) pedagógico",
  "Diretor(a) escolar",
  "Educador(a) não formal",
  "Prefeito(a)",
  "Professor(a) educacão infantil",
  "Professor(a) ensino fundamental 1",
  "Professor(a) ensino fundamental 2",
  "Professor(a) ensino médio",
  "Professor(a) ensino superior",
  "Professor(a) ensino técnico",
  "Secretário(a) de educação",
  "Técnico(a) de secretaria",
  "Outro",
]

export const organizationTypes = [
  "Associação de Pais e Mestres",
  "Biblioteca",
  "Conselho dos Direitos da Criança e do Adolescente",
  "Conselho Municipal de Educação",
  "Empresa Privada",
  "Escola Privada",
  "Escola Pública",
  "Imprensa",
  "Instituto ou Fundação",
  "Organização da Sociedade Civil",
  "Secretaria Estadual de Educação",
  "Secretaria Municipal de Educação",
  "Universidade",
  "Outro Órgão Público",
  "Outros",
]