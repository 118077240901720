import { BaseEndpoint } from "./Base"

export default class SubscriptionCommentEndpoint extends BaseEndpoint {
  static get selector() {
    return "subscriptions.comments"
  }

  index({ subscriptionId, params = {}, options = {} } = {}) {
    this.verifyData("index", { subscriptionId })

    return this.action({
      method:  "get",
      url:     `/subscriptions/${subscriptionId}/comments`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
