export const SELECTION_PROCESS_STATUS = /** @type {const} */ ({
  TO_START: {
    value: "to_start",
    label: "A iniciar"
  },
  IN_PROGRESS: {
    value: "in_progress",
    label: "Em andamento"
  },
  CLOSED: {
    value: "closed",
    label: "Encerrado"
  },
})

export const PHASE_KIND = /** @type {const} */ ({
  VALIDATION: {
    value: "validation",
    label: "Validação"
  },
  FIXED_NOTES: {
    value: "fixed_notes",
    label: "Notas Fixas"
  },
  TYPED_NOTES: {
    value: "typed_notes",
    label: "Notas Digitadas"
  },
})

export const ENROLLED_PROGRESS = /** @type {const} */ ({
  ENROLLED: {
    value:      "enrolled",
    label:      "Aprovado",
    alternator: "to_enrolled"
  },
  ENROLLMENT_WAITING: {
    value:      "enrollment_waiting",
    label:      "Aguardando aprovação",
    alternator: "to_enrollment_waiting"
  },
  UNDER_EVALUATION: {
    value:      "under_evaluation",
    label:      "Aguardando avaliação",
    alternator: ""
  },
  ENROLL_CANCELED: {
    value:      "enroll_canceled",
    label:      "Cancelado",
    alternator: "to_enroll_canceled"
  },
})
