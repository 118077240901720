import { BaseEndpoint } from "./Base"

export default class OpenQuestionAnswerVisibilityEndpoint extends BaseEndpoint {
  static get selector() {
    return "openQuestionAnswer.visibility"
  }

  update({ answerId, params = {}, options = {} } = {}) {
    this.verifyData("update", { answerId })

    return this.action({
      method:  "patch",
      url:     `/open_question_answers/${answerId}/visibility`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
