export const FILE_TYPE =  /** @type {const} */ ({
  PDF: {
    mimeTypes:  ["application/pdf"],
    signature:  [0x25, 0x50, 0x44, 0x46],
    extensions: ["pdf"],
  },
  // Excel (XLSX)
  XLSX: {
    mimeTypes:  ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
    signature:  [0x50, 0x4B, 0x03, 0x04],
    extensions: ["xlsx"],
    zipCheck:   true,
  },
  // Excel (XLS)
  XLS: {
    mimeTypes:  ["application/vnd.ms-excel"],
    signature:  [0xD0, 0xCF, 0x11, 0xE0, 0xA1, 0xB1, 0x1A, 0xE1],
    extensions: ["xls"],
  },
  // OpenDocument Spreadsheet (ODS)
  ODS: {
    mimeTypes:  ["application/vnd.oasis.opendocument.spreadsheet"],
    signature:  [0x50, 0x4B, 0x03, 0x04],
    extensions: ["ods"],
    zipCheck:   true,
  },
  // Plain Text (TXT)
  TXT: {
    mimeTypes:  ["text/plain"],
    extensions: ["txt"],
  },
  // Word (DOC)
  DOC: {
    mimeTypes:  ["application/msword"],
    signature:  [0xD0, 0xCF, 0x11, 0xE0, 0xA1, 0xB1, 0x1A, 0xE1],
    extensions: ["doc"],
  },
  // Word (DOCX)
  DOCX: {
    mimeTypes:  ["application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
    signature:  [0x50, 0x4B, 0x03, 0x04],
    extensions: ["docx"],
    zipCheck:   true,
  },
  // OpenDocument Text (ODT)
  ODT: {
    mimeTypes:  ["application/vnd.oasis.opendocument.text"],
    signature:  [0x50, 0x4B, 0x03, 0x04],
    extensions: ["odt"],
    zipCheck:   true,
  },
  // JPEG
  JPEG: {
    mimeTypes:  ["image/jpeg", "image/jpg"],
    signature:  [0xFF, 0xD8, 0xFF],
    extensions: ["jpg", "jpeg"]
  },
  // PNG
  PNG: {
    mimeTypes:  ["image/png"],
    signature:  [0x89, 0x50, 0x4E, 0x47, 0x0D, 0x0A, 0x1A, 0x0A],
    extensions: ["png"],
  },
  // MP4
  MP4: {
    mimeTypes:  ["video/mp4"],
    signature:  [0x66, 0x74, 0x79, 0x70], // 'ftypisom'
    extensions: ["mp4"],
    offset:     4,
  },
  // M4V
  M4V: {
    mimeTypes:  ["video/x-m4v", "video/m4v"],
    signature:  [0x66, 0x74, 0x79, 0x70], // 'ftypM4V '
    extensions: ["m4v"],
    offset:     4,
  },
  // MOV
  MOV: {
    mimeTypes:  ["video/quicktime", "video/mov"],
    signature:  [0x66, 0x74, 0x79, 0x70], // 'ftypqt  '
    extensions: ["mov"],
    offset:     4,
  },
  // AVI
  AVI: {
    mimeTypes:  ["video/x-msvideo", "video/avi"],
    signature:  [0x52, 0x49, 0x46, 0x46], // 'RIFF'
    extensions: ["avi"],
  },
  // MPG/MPEG
  MPG_MPEG: {
    mimeTypes:  ["video/mpeg", "video/mpg"],
    signature:  [0x00, 0x00, 0x01, 0xBA], // MPEG-PS
    extensions: ["mpg", "mpeg"],
  },
  // WMV
  WMV: {
    mimeTypes:  ["video/x-ms-wmv", "video/wmv", "video/x-ms-asf"],
    signature:  [0x30, 0x26, 0xB2, 0x75, 0x8E, 0x66, 0xCF, 0x11], // ASF header
    extensions: ["wmv"],
  },
})
